import { SPD_DETAIL_GEOENTITY_CONTENT_RUBRIC, tagBi } from '../../domain/analytics/BiTagger'
import { SET_GEOENTITY_TAB_BLOCKS_IDX } from '../geoentity/geoentity.actionTypes'
import { selectSelectedGeoentityBlocksContext, selectCurrentGeoentityId } from '../geoentity/geoentity.selectors'

export const geoentityAfterBlocksIdxChangeTagsMiddleware =
  ({ getState }) =>
  next =>
  action => {
    const r = next(action)

    if (__BROWSER__) {
      try {
        if (action.type === SET_GEOENTITY_TAB_BLOCKS_IDX) {
          const store = getState()
          const rubricid = selectSelectedGeoentityBlocksContext(store)
          const geoentityId = selectCurrentGeoentityId(store)
          tagBi(SPD_DETAIL_GEOENTITY_CONTENT_RUBRIC, { geoentity: geoentityId, rubricid })
        }
      } catch (error) {
        console.error(error)
      }
    }

    return r
  }

export default [geoentityAfterBlocksIdxChangeTagsMiddleware]
