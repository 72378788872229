import { FAVORITE_TYPES } from '../../domain/UserAccount/UserAccount.constants'
import { translate } from '../../domain/i18n/T'
import { PE_MAIN_START_POS } from '../../domain/main/main.constants'
import { createCustomSelector } from '../../domain/utils/react-reselect'
import {
  ROUTE_ADDRESS_HOME,
  ROUTE_BICYCLE_HOME,
  ROUTE_HOME,
  ROUTE_ITINERARY_HOME,
  ROUTE_ITINERARY_TO,
  ROUTE_SEARCH_BY_APP,
  ROUTE_SEARCH_BY_CATEGORY,
  ROUTE_SEARCH_BY_SUBCATEGORY,
  ROUTE_TOOLS_COMPUTE_RADIUS,
  ROUTE_TRAFFIC_CITY,
  ROUTE_TRAFFIC_HOME
} from '../../routes'
import { equalsIgnoreCase } from '../../utils/string'
import {
  isAddressRoute,
  isGeoentityOnSearchRoute,
  isItineraryResultRoute,
  isQuizRoute,
  isSearchOnItineraryRoute,
  isSearchRoute,
  isZoneRoute,
  selectCurrentHistoryRoute,
  selectCurrentHistoryRouteDone,
  selectIsHomePage,
  selectIsQuizPage
} from '../history/history.selectors'
import { selectLocale } from '../locale/locale.selectors'
import { selectIsMapFullScreen } from '../map/map.selectors'
import { selectIsPE } from '../tools/tools.selectors'
import { USER_SCREENS } from './ui.constants'
import { GEOENTITY_DISPLAY_MODE_LIST, GEOENTITY_DISPLAY_MODE_MAP } from './ui.geoentities.constants'

export const selectUIState = store => store?.ui

export const isUIElementDisplayed = uiElement => createCustomSelector(selectUIState, ui => ui?.[uiElement]?.isDisplayed)

export const selectIsBackButtonDisplayed = createCustomSelector(
  selectCurrentHistoryRoute,
  route => route !== ROUTE_HOME
)

export const selectCurrentSearch = createCustomSelector(selectUIState, ui => ui?.currentSearch ?? '')

export const selectHeaderHeight = createCustomSelector(selectUIState, ui => ui?.headerHeight)

export const isModalDisplayed = modalId =>
  createCustomSelector(selectUIState, ui => (ui?.displayedModalIds ?? []).includes(modalId))

export const selectIsOnboardingContext = createCustomSelector(selectUIState, ui => ui?.isOnBoardingContext ?? false)

export const selectAreGeoentitiesDisplayedAsList = createCustomSelector(
  selectUIState,
  ui => ui?.geoentity?.displayMode === GEOENTITY_DISPLAY_MODE_LIST
)

export const selectAreGeoentitiesDisplayedAsMap = createCustomSelector(
  selectUIState,
  ui => ui?.geoentity?.displayMode === GEOENTITY_DISPLAY_MODE_MAP
)

export const selectShouldDisplayMapTools = createCustomSelector(
  selectIsMapFullScreen,
  selectCurrentHistoryRoute,
  selectIsPE,
  (selectIsMapFullScreen, route, pe) => {
    if (isQuizRoute(route)) return false

    // TODO : add ROUTE_ADDRESS_HOME in HP list when a toaster will replace the big footer.
    if (isSearchOnItineraryRoute(route) && pe) return false

    const isHP = [
      ROUTE_HOME,
      ROUTE_ITINERARY_HOME,
      ROUTE_TRAFFIC_HOME,
      ROUTE_BICYCLE_HOME,
      ROUTE_TRAFFIC_CITY,
      ROUTE_TOOLS_COMPUTE_RADIUS,
      ROUTE_SEARCH_BY_CATEGORY,
      ROUTE_SEARCH_BY_SUBCATEGORY,
      ROUTE_SEARCH_BY_APP
    ].includes(route)
    if (pe && !selectIsMapFullScreen) {
      return isHP
    }
    return true
  }
)

export const selectShouldDisplayContextMenu = createCustomSelector(selectIsQuizPage, isQuizPage => !isQuizPage)

export const selectUIGeoentityActiveFilterId = createCustomSelector(selectUIState, ui => ui?.geoentity?.filters?.active)

export const selectElevationPositionOver = createCustomSelector(selectUIState, ui => ui?.itinerary?.elevationPointOver)

export const selectMainPEPositionAtStart = createCustomSelector(selectCurrentHistoryRouteDone, route => {
  const { min, low, high, highNoMap } = PE_MAIN_START_POS

  if (isSearchRoute(route)) {
    return highNoMap
  }

  if (
    isItineraryResultRoute(route) ||
    isGeoentityOnSearchRoute(route) ||
    isAddressRoute(route) ||
    isZoneRoute(route) ||
    ROUTE_ITINERARY_TO === route
  ) {
    return high
  }

  if (
    [
      ROUTE_HOME,
      ROUTE_ADDRESS_HOME,
      ROUTE_ITINERARY_HOME,
      ROUTE_TRAFFIC_HOME,
      ROUTE_BICYCLE_HOME,
      ROUTE_TOOLS_COMPUTE_RADIUS
    ].includes(route) ||
    isQuizRoute(route)
  ) {
    return low
  }

  return min
})

export const selectUserUIScreen = createCustomSelector(selectUIState, ui => ui?.user?.screen?.name)

export const selectUserUIScreenParams = createCustomSelector(selectUIState, ui => ui?.user?.screen?.params)

export const selectFavoritePlaceholder = createCustomSelector(
  selectUserUIScreenParams,
  selectLocale,
  (UIScreenParams = {}, locale) => {
    const { type = '' } = UIScreenParams
    const isHome = equalsIgnoreCase(type, FAVORITE_TYPES.HOME)
    const isWork = equalsIgnoreCase(type, FAVORITE_TYPES.WORK)

    if (isHome) return translate(locale)('useraccount.favorite.home')

    if (isWork) return translate(locale)('useraccount.favorite.work')

    return translate(locale)('useraccount.favorite.add-your-favorite')
  }
)

export const selectIsUserScreenDefault = createCustomSelector(
  selectUserUIScreen,
  screen => screen === USER_SCREENS.default
)

export const selectUserAccountIncentivePopinDisplayTimestamp = createCustomSelector(
  selectUIState,
  ui => ui?.userAccountIncentive?.timestamp ?? 0
)

export const selectUserAccountIncentiveItineraryResultsDisplayTimestamp = createCustomSelector(
  selectUIState,
  ui => ui?.userAccountIncentive?.itineraryResultsTimestamp ?? 0
)

export const selectVisitCount = createCustomSelector(selectUIState, ui => ui?.visitCount ?? 0)

export const selectIsPreferencesUserAccountIncentiveDisplayed = createCustomSelector(
  selectUIState,
  ui => ui?.userAccountIncentive?.preferencesDisplayed ?? false
)

const selectUIMap = createCustomSelector(selectUIState, ui => ui?.map)

const selectPopinOnRouteIncentive = createCustomSelector(selectUIState, ui => ui?.poisOnRouteIncentive)

export const selectMapTooSlowTooltip = createCustomSelector(selectUIMap, map => Boolean(map?.mapTooSlowTooltip))

export const selectMapTooSlowTimestamp = createCustomSelector(selectUIMap, map => map?.tooSlowTimestamp)

export const selectShouldDisplayPoisOnRouteIncentivePopin = createCustomSelector(
  selectPopinOnRouteIncentive,
  popin => !popin?.timestamp
)

const selectHomeBanner = createCustomSelector(selectUIState, ui => ui?.homeBanner)

export const selectShouldDisplayHomeBanner = createCustomSelector(
  selectHomeBanner,
  selectIsHomePage,
  (homeBanner, isHomePage) => isHomePage || homeBanner?.shouldBeDisplayed
)

export const selectOutdoorStatus = createCustomSelector(selectUIState, ui => ui?.outdoor?.status)
