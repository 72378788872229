import { SET_PUSH_DISPLAYED, SET_PUSH_ACCEPTATION } from './push.actionTypes'

const getInitialState = () => {
  return {}
}

export default function pushReducers(state = getInitialState(), action) {
  switch (action && action.type) {
    case SET_PUSH_DISPLAYED: {
      return { ...state, timestamp: action?.payload?.timestamp }
    }
    case SET_PUSH_ACCEPTATION: {
      return { ...state, accepted: true }
    }
  }
  return state
}
