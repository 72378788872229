import { string } from 'prop-types'
import {
  isModeTcForSpecialCase,
  isModeTrainForSpecialCase
} from '../../domain/itinerary/specialcases/itinerary.specialcases.utils'
import { createCustomSelector } from '../../domain/utils/react-reselect'
import { ROUTE_ITINERARY_HOME, ROUTE_ITINERARY_HP_BY_MODE, ROUTE_ITINERARY_TO, ROUTE_SUGGEST } from '../../routes'
import { compact } from '../../utils/array'
import { selectCurrentHistoryRoute, selectPreviousHistoryRoute } from '../history/history.selectors'
import { selectIsPE } from '../tools/tools.selectors'
import { MULTIPATH_MODE_CAR, PROVIDER_TC, PROVIDER_TRAIN } from './itinerary.constants'
import { compareRoutesByDuration, compareRoutesByTransportsServiceProviderPriority } from './itinerary.sort.utils'

export const selectItineraryState = store => store?.itinerary

export const selectCurrentProvider = createCustomSelector(selectItineraryState, itinerary => itinerary?.currentProvider)

export const selectProviders = createCustomSelector(selectItineraryState, itinerary => itinerary?.providers ?? [])

export const selectCurrentMode = createCustomSelector(selectItineraryState, itinerary => itinerary?.currentMode)
selectCurrentMode.propTypes = string

const selectCurrentProviderObject = createCustomSelector(
  selectProviders,
  selectCurrentMode,
  selectCurrentProvider,
  (providers, currentMode, currentProvider) =>
    providers.find(provider => provider.mode === currentMode && provider.name === currentProvider)
)

export const selectSeoCurrentMode = createCustomSelector(
  selectItineraryState,
  itinerary => itinerary?.currentMode || MULTIPATH_MODE_CAR
)
selectSeoCurrentMode.propTypes = string

export const selectActiveSort = createCustomSelector(selectItineraryState, itinerary => itinerary?.activeSort)

export const selectIsMonomode = createCustomSelector(selectActiveSort, activeSort => !activeSort)

export const selectIsMultimode = createCustomSelector(selectActiveSort, activeSort => Boolean(activeSort))

const routeByModeFinder =
  modeId =>
  ({ mode }) =>
    mode === modeId

export const selectModesForSwitcher = createCustomSelector(
  selectItineraryState,
  ({ providers, routes, currentProvider }) => {
    const sortedRoutesByProvider = [...routes].sort(compareRoutesByTransportsServiceProviderPriority(providers))
    const sortedRoutesByDuration = [...routes].sort(compareRoutesByDuration)
    const currentProviderData = getProvider(providers, currentProvider)

    return compact(
      (providers ?? [])
        .reduce((modes, provider) => {
          const mode = modes.find(({ id }) => id === provider.mode)
          if (mode) {
            mode.providers.push(provider)
          } else {
            return [
              ...modes,
              {
                id: provider.mode,
                providers: [provider]
              }
            ]
          }
          return modes
        }, [])
        .map(({ id, providers }) => {
          const routeId = sortedRoutesByProvider.find(routeByModeFinder(id))?.routeId
          const providersWithRoutes = providers.filter(
            ({ name }) => routes.filter(({ provider: { id } }) => id === name).length > 0
          )

          let provider
          if (providersWithRoutes.length > 0) {
            provider = providersWithRoutes[0]
          } else if (currentProviderData?.mode === id) {
            provider = currentProviderData
          } else if (isModeTrainForSpecialCase(id)) {
            provider = providers.find(({ name }) => name === PROVIDER_TRAIN)
          } else if (isModeTcForSpecialCase(id)) {
            provider = providers.find(({ name }) => name === PROVIDER_TC)
          } else {
            return null
          }

          const selected = provider ? provider.name === currentProvider : currentProviderData.mode !== id
          const simplified = providers.filter(({ simplified }) => simplified).length > 0

          return {
            id,
            label: provider.label,
            icon: provider.icon,
            selected,
            provider: provider.name,
            routeId: routeId && !simplified ? routeId : undefined,
            time: sortedRoutesByDuration.find(routeByModeFinder(id))?.time
          }
        })
    )
  }
)

export const filterNotMode = (providers, mode) => route => {
  return !getProvidersByMode(providers, mode).find(provider => provider.name === route.provider.id)
}

export const getProvidersByMode = (providers = [], mode) => providers.filter(provider => provider.mode === mode)

export const getProvider = (providers = [], currentProvider) => providers.find(({ name }) => name === currentProvider)

export const selectProviderByName = providerName => getState => {
  const {
    itinerary: { providers = [] }
  } = getState()
  return providers.find(provider => provider.name === providerName)
}

export const selectFirstProviderWithRoutes = ({ routes, providers }) => {
  const firstProvider =
    routes.map(({ provider }) => ({ provider: provider.name })).sort(sortByProvider(providers))?.[0]?.provider ?? ''
  return getProvider(providers, firstProvider)
}

export const getProviderMode = ({ providers, currentProvider }) => {
  const provider = getProvider(providers, currentProvider)
  return provider?.mode
}

export const sortByProvider = providers => {
  if (!providers) return () => 0
  providers = providers.map(({ name }) => name)
  return ({ provider: providerA }, { provider: providerB }) =>
    providers.indexOf(providerA) - providers.indexOf(providerB)
}

export const selectItineraryError = createCustomSelector(selectItineraryState, itinerary => itinerary?.error)

export const selectCurrentModeError = createCustomSelector(
  selectItineraryState,
  selectCurrentMode,
  (itinerary, currentMode) => itinerary?.[`routeError[${currentMode}`]?.errorMessage
)

export const getColor = createCustomSelector(selectItineraryState, itinerary => {
  const currentProvider = itinerary?.currentProvider
  return itinerary?.colors?.[currentProvider]
})

export const selectIsComputingDone = createCustomSelector(selectItineraryState, itinerary => itinerary?.isComputingDone)

export const selectItineraryComputedCount = createCustomSelector(
  selectItineraryState,
  itinerary => itinerary?.computedCount
)

export const selectQid = createCustomSelector(selectCurrentProviderObject, provider => provider?.qid)

export const selectRoadbookTimestamp = createCustomSelector(
  selectItineraryState,
  itinerary => itinerary?.roadbookTimestamp
)

export const selectIsRoadbookExpired = createCustomSelector(
  selectItineraryState,
  itinerary => itinerary?.isRoadbookExpired
)

const routeListForGeolocation = [ROUTE_ITINERARY_HOME, ROUTE_ITINERARY_HP_BY_MODE, ROUTE_ITINERARY_TO]

export const selectShouldLoadPositionOnFirstItineraryStep = createCustomSelector(
  selectIsPE,
  selectCurrentHistoryRoute,
  selectPreviousHistoryRoute,
  (isPe, historyRoute, previousHistoryRoute) => {
    return isPe && routeListForGeolocation.includes(historyRoute) && previousHistoryRoute !== ROUTE_SUGGEST
  }
)

export const selectTcAvailabilityInfo = createCustomSelector(
  selectItineraryState,
  itinerary => itinerary?.tcAvailabilityInfo
)
