import { selectAbTestVariantsIdsForTracking } from '../../store/abtest/abtest.selectors'
import { isGE } from '../Device'
import { head } from '../../utils/array'

const TRANSACTIONAL_APP_IDS = ['accor', 'booking', 'bpvcamping', 'bbhotel']
const BOOKING_DOMAIN = 'booking.com'
const HOTEL_BB_DOMAIN = 'hotel-bb.com'
const ACCOR_HOTELS_DOMAIN = 'accor.com'

export const RESERVATION_URL_TYPE_LIST = 'L'
export const RESERVATION_URL_TYPE_POI_CARD = 'FD'

const REDIRECT_IDS = {
  GE: {
    'booking-aid': '324859',
    'accor-merchantid': 'RT-FR018152-',
    epi2: 'desk',
    'pjdoc-xtor': '371'
  },
  PE: {
    'booking-aid': '343652',
    'accor-merchantid': 'RT-FR018152-',
    epi2: 'mobi',
    'pjdoc-xtor': '371'
  }
}

export const isATransactionalHotelAppId = appId => Boolean(TRANSACTIONAL_APP_IDS.find(id => id === appId))

const urlContains = domain => url => url.includes(domain)
export const isBookingUrl = urlContains(BOOKING_DOMAIN)
export const isAccorUrl = urlContains(ACCOR_HOTELS_DOMAIN)
export const isHotelBbUrl = urlContains(HOTEL_BB_DOMAIN)

const changeBookingTab = url => {
  return url.replace(/(?:\{booking-tab\})|(?:%7Bbooking-tab%7D)/i, isGE() ? '#tab-reviews' : '&activeTab=htReviews')
}

const getAbTestsParam = () => {
  const storeState = global.__mappy__store__rare_usage && global.__mappy__store__rare_usage.getState()
  return head(selectAbTestVariantsIdsForTracking(storeState).map(({ id, variant }) => `-${id}-${variant}`)) || ''
}

export const getBookingUrl = (url, type) => {
  const tabCleanedUrl = changeBookingTab(url)
  const urlParts = tabCleanedUrl.split('#')
  // TODO: sur le legacy, type est contextualisé à l'url courante, pour le moment seulement poi sur nessie, concerne aussi les urls accor et hotelBB
  const suffix = url.includes('checkin_monthday=') ? 'A' : 'NA'
  const abTestId = getAbTestsParam()
  return `${urlParts[0]}&label=mappy-${type}${suffix}${abTestId}${urlParts[1] ? `#${urlParts[1]}` : ''}`
}

export const getAccorUrl = (url, type) => {
  const device = isGE() ? 'd' : 'm'
  const suffix = url.includes('yearIn') ? 'A' : 'N'

  // accor-sourceid
  const enhancedUrl = url.replace(
    /(?:\{accor-sourceid\})|(?:%7Baccor-sourceid%7D)/gi,
    ['FR', type + suffix, device].join('-')
  )

  // accor-xtor
  return enhancedUrl.replace(
    /(?:\{accor-xtor\})|(?:%7Baccor-xtor%7D)/i,
    '[FR]-[' + type + suffix + ']-[' + device + ']-[0]'
  )
}

export const getHotelBbUrl = (url, type) => {
  const suffix = url.includes('arrivalDate') ? 'A' : 'NA'

  return url.replace(/(?:\{epi\})|(?:%7Bepi%7D)/i, type + suffix)
}

export const populateRedirectIdsInGeoentityUrl = url => {
  const ids = REDIRECT_IDS[isGE() ? 'GE' : 'PE']
  let result = url
  Object.keys(ids).forEach(key => {
    result = result.replace(`{${key}}`, ids[key])
  })
  return result
}

export const populateHotelParamsInGeoentityUrl = (url = '', type) => {
  if (isBookingUrl(url)) return getBookingUrl(url, type)
  if (isAccorUrl(url)) return getAccorUrl(url, type)
  if (isHotelBbUrl(url)) return getHotelBbUrl(url, type)
  return url
}

export const populateGeoentityUrl = (url = '', type = RESERVATION_URL_TYPE_POI_CARD) =>
  populateRedirectIdsInGeoentityUrl(populateHotelParamsInGeoentityUrl(url, type))
