import { selectArrivalStep, selectDepartureStep, selectFilledSteps } from '../../domain/itinerary/steps/steps.selectors'
import {
  convertHourAndMinuteFormatToSeconds,
  convertSecondsToHourAndMinuteFormat,
  parseDate
} from '../../domain/utils/date'
import { createCustomSelector } from '../../domain/utils/react-reselect'
import { flatten } from '../../utils/array'
import { isEmpty } from '../../utils/lang'
import { selectCurrentProvider, selectItineraryState } from './itinerary.selectors'
import { selectNumOfTimeLineParts } from './polylines.selectors'

const getTimeLabel = date => `${date.getHours()}h${('0' + date.getMinutes()).slice(-2)}`

const selectRoadbookState = createCustomSelector(selectItineraryState, itinerary => itinerary?.roadbook)

export const selectRoadbookKeyInformations = createCustomSelector(
  selectRoadbookState,
  roadbook => roadbook?.summary?.key_informations ?? null
)

export const selectRoadbookType = createCustomSelector(selectRoadbookState, roadbook => roadbook?.type || '')

export const selectRoadBookTimeLabels = createCustomSelector(selectRoadbookState, roadbook => {
  if (!roadbook?.summary) return {}
  const { summary } = roadbook
  const arrivalDate = parseDate(summary?.arrival_datetime)
  const departureDate = parseDate(summary?.departure_datetime)
  return {
    departureTimeLabel: getTimeLabel(departureDate),
    arrivalTimeLabel: getTimeLabel(arrivalDate)
  }
})

export const selectRoadbookSummary = createCustomSelector(
  selectRoadbookKeyInformations,
  selectRoadBookTimeLabels,
  (keyInformations, timeLabels) => {
    return {
      key_informations: keyInformations,
      ...timeLabels
    }
  }
)

export const selectRoadbookFormattedSections = createCustomSelector(
  selectItineraryState,
  selectRoadbookSummary,
  ({ roadbook, steps }, { departureTimeLabel }) => {
    if (!roadbook?.sections) return []

    let waypointIndex = 0
    const parsedRoadbookDetails = []
    const filledSteps = selectFilledSteps(null, { steps })

    roadbook.sections.reduce((prevSection, currSection) => {
      const actionTimeLabel = convertSecondsToHourAndMinuteFormat(
        convertHourAndMinuteFormatToSeconds(prevSection?.actionTimeLabel ?? departureTimeLabel) +
          currSection?.duration.value
      )

      if (currSection.mode === 'waypoint') {
        const step = filledSteps[waypointIndex++]
        const stepId = step?.ui?.stepId ?? ''
        const roadbookData = {
          ...currSection,
          type: stepId.match(/^step-/) ? 'stop' : stepId,
          data: step,
          actionTimeLabel
        }

        parsedRoadbookDetails.push(roadbookData)
        return roadbookData
      }

      const roadbookData = {
        ...currSection,
        type: 'actions',
        data: {
          ...currSection,
          timeline: currSection?.actions?.[0]?.timeline,
          actions: currSection.actions
        },
        actionTimeLabel
      }

      parsedRoadbookDetails.push(roadbookData)
      return roadbookData
    }, roadbook.sections)

    return parsedRoadbookDetails
  }
)

export const selectRoadbookDetails = createCustomSelector(selectItineraryState, ({ roadbook, steps }) => {
  if (!roadbook?.sections) return {}
  const list = roadbook.sections
    .filter(section => section.mode !== 'waypoint')
    .reduce((actions, section) => actions.concat(section.actions), [])
  const [departure, arrival] = [selectDepartureStep(null, { steps }), selectArrivalStep(null, { steps })]
  return {
    departure,
    list,
    arrival
  }
})

export const selectShouldDisplayExpenseButton = createCustomSelector(selectCurrentProvider, currentProvider =>
  ['car', 'motorbike'].includes(currentProvider)
)

export const selectShouldDisplaySubHeader = createCustomSelector(
  selectRoadbookType,
  selectNumOfTimeLineParts,
  (roadbookType, numTimelineParts) => roadbookType === 'roadbook' && numTimelineParts
)

export const selectHasRoadbook = createCustomSelector(selectRoadbookState, roadbook => !isEmpty(roadbook))

export const selectRoadbook = createCustomSelector(selectRoadbookState, roadbook => roadbook || {})

export const selectRoadbookSections = createCustomSelector(selectRoadbook, roadbook => roadbook?.sections ?? [])

export const selectCurrentRoadbookPolylineForElevation = createCustomSelector(selectRoadbookSections, (sections = []) =>
  flatten(
    sections.filter(section => section?.display_elevation ?? false).map(section => section?.polyline_definition ?? [])
  ).map(([lng, lat]) => [lat, lng])
)

export const selectRoadbookError = createCustomSelector(selectItineraryState, itinerary => itinerary?.roadbook_error)

export const selectRoadbookPollutantEmissions = createCustomSelector(
  selectRoadbookState,
  roadbook => roadbook?.summary?.pollutant_emissions
)

export const selectRoadbookEventRestrictions = createCustomSelector(
  selectRoadbookState,
  roadbook => roadbook?.summary?.restrictions?.[0]
)
