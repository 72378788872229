import { arrayOf, shape } from 'prop-types'
import TopSearchItem from '../../domain/genericComponents/topSearch/TopSearchItem'
import { getSeoCityPostcode, isMainZipForCityWithDistricts } from '../../domain/seo/seo'
import { isCityWithDistrict } from '../../domain/utils/location'
import { createCustomSelector } from '../../domain/utils/react-reselect'
import { MINIMAL_NB_OF_POI_TO_OPEN_CITY } from '../../parameters'
import { selectFirstAddress } from '../address/address.selectors'
import {
  selectGeoentities,
  selectGeoentityParentRubricId,
  selectGeoentityRubricId
} from '../geoentity/geoentity.selectors'

const selectSeoState = store => store?.seo ?? {}

export const selectBreadcrumbs = createCustomSelector(selectSeoState, seo => seo?.breadcrumbs ?? [])

export const selectSeoActivitiesForDeparture = createCustomSelector(
  selectSeoState,
  seo => seo?.seoActivitiesForDeparture?.results
)

export const selectSeoActivitiesForArrival = createCustomSelector(
  selectSeoState,
  seo => seo?.seoActivitiesForArrival?.results
)

export const selectSeoActivities = createCustomSelector(selectSeoState, seo => seo?.seoActivities?.results ?? [])

export const selectSeoRubricId = createCustomSelector(
  selectGeoentities,
  selectGeoentityRubricId,
  selectGeoentityParentRubricId,
  (geoentities, origRubricId, parentRubricId) => (geoentities.length < 2 ? parentRubricId : origRubricId)
)

export const selectRubrics = createCustomSelector(selectSeoState, seo => seo?.rubrics ?? {})

export const selectRubricById = createCustomSelector(
  selectRubrics,
  (_, { rubricId } = {}) => rubricId,
  (rubrics, rubricId) => rubrics[rubricId]
)

export const selectCurrentRubric = createCustomSelector(selectSeoState, seo => seo?.currentRubric ?? {})

export const selectSeoRubric = createCustomSelector(
  selectRubrics,
  selectSeoRubricId,
  (rubrics, rubricId) => rubrics[rubricId] || {}
)

export const selectRubricSiblingsCityActivities = createCustomSelector(selectSeoState, seo => seo?.siblingsCityActivity)

export const selectSeoPois = createCustomSelector(selectSeoState, seo => seo?.pois ?? [])

export const selectSeoPoisOvm = createCustomSelector(selectSeoState, seo => seo?.poisOvm ?? [])

export const selectSeoDirectoryData = createCustomSelector(selectSeoState, seo => seo?.directoryData ?? [])

export const selectTopSearchLinks = createCustomSelector(selectSeoState, seo => seo?.topSearchLinks)
selectTopSearchLinks.propTypes = arrayOf(shape(TopSearchItem.propTypes))

export const selectShouldDisplayTopSearch = createCustomSelector(selectSeoState, seo => seo?.displayTopSearchLinks)

export const selectCitiesToDiscoverLinks = createCustomSelector(selectSeoState, seo => seo?.citiesToDiscoverLinks ?? [])

export const selectSeoTownDetails = createCustomSelector(selectSeoState, seo => seo?.seoTownDetails ?? {})

export const selectSeoCitiesForFooter = createCustomSelector(selectSeoState, seo => seo?.seoCitiesForFooter ?? [])
export const selectSeoCitiesForActivitiesFooter = createCustomSelector(
  selectSeoCitiesForFooter,
  seoCities => seoCities?.slice(20, 100) ?? []
)
export const selectSeoCitiesForContent = createCustomSelector(
  selectSeoState,
  seo => seo?.seoCitiesForActivities?.slice(0, 20) ?? []
)

export const selectSeoAppsForFooter = createCustomSelector(selectSeoState, seo => seo?.seoAppsForFooter ?? [])

export const selectSeoTownDetailsId = createCustomSelector(selectSeoTownDetails, townDetails => townDetails?.id)

export const selectIsSeoCityWithDistricts = createCustomSelector(selectSeoTownDetails, townDetails =>
  isMainZipForCityWithDistricts(townDetails?.postcode)
)

export const selectPoiCount = createCustomSelector(
  selectSeoTownDetails,
  ({ mergedCity, poicount }) => mergedCity?.poicount || poicount || 0
)

export const selectIsActivityFooterOpen = createCustomSelector(
  selectPoiCount,
  poicount => poicount >= MINIMAL_NB_OF_POI_TO_OPEN_CITY
)

export const selectSeoTown = createCustomSelector(selectSeoTownDetails, seoTownDetails => {
  if (isCityWithDistrict(seoTownDetails.postcode)) return seoTownDetails
  return seoTownDetails.mergedCity || seoTownDetails.townWithMorePoiAndSameTownCode || seoTownDetails
})

export const selectSeoPostcodeForCity = createCustomSelector(
  selectFirstAddress,
  selectSeoTownDetails,
  (address, seoTownDetails) => {
    let postcode
    if (seoTownDetails.mergedCity) {
      postcode = seoTownDetails?.mergedCity?.postcode
    } else if ((seoTownDetails?.poicount ?? 0) > MINIMAL_NB_OF_POI_TO_OPEN_CITY) {
      postcode = seoTownDetails?.postcode
    } else {
      postcode = seoTownDetails?.townWithMorePoiAndSameTownCode?.postcode
    }
    if (!postcode) postcode = address.postcode
    return getSeoCityPostcode(postcode)
  }
)

export const selectTownFromPath = createCustomSelector(selectSeoState, seo => seo?.townFromPath ?? {})

export const selectSeoItineraryData = createCustomSelector(selectSeoState, seo => seo?.itineraryData ?? {})

export const selectSeoFaqData = createCustomSelector(selectSeoState, seo => seo?.faqData ?? {})

export const selectSeoDirectoryPageIdx = createCustomSelector(selectSeoState, seo => seo?.directoryPageIdx)

export const selectSeoLayerRubricLinks = createCustomSelector(selectSeoState, seo => seo?.layerRubricLinks)

export const selectSeoH1Title = createCustomSelector(selectSeoState, seo => seo?.h1title)

export const selectSeoArrivalCityDescription = createCustomSelector(selectSeoState, seo => seo?.arrivalCityDescription)

export const selectSeoCurrentRubricLabel = createCustomSelector(selectSeoState, seo => seo?.rubric?.label)
export const selectSeoParentRubricLabel = createCustomSelector(selectSeoState, seo => seo?.rubric?.parentLabel)
export const selectSeoParentRubricId = createCustomSelector(selectSeoState, seo => seo?.rubric?.parentId)
