const SERVICES = {
  MAP: 'map',
  MAP_RASTER: 'map_raster',
  MULTIPATH: 'multipath',
  GEOENTITY: 'geoentity',
  GEOENTITY_V2: 'geoentity_v2',
  GEOENTITY_SEO: 'geoentity_seo',
  GEOCODE_REVERSE: 'geocode_reverse',
  GEOCODE_FORWARD: 'geocode_forward',
  SEARCH: 'search',
  SUGGEST: 'suggest',
  JOURNEY: 'journey',
  POIASSETS: 'poiassets',
  PANO: 'pano',
  BUC: 'buc',
  WEB_GEOIP: 'web_geoip',
  WEB_SEO_DESCRIPTION: 'web_seo_description',
  WEB_SEO_FAQ: 'web_seo_faq',
  WEB_SEO_LINKS: 'web_seo_links',
  WEB_QUIZ: 'web_quiz',
  WEATHER: 'weather',
  WEB_VERSION: 'web_version',
  KEYCLOAK: 'keycloak',
  USER_ACCOUNT: 'user_account',
  CONSENT: 'consent',
  CONSENT_REFUSE: 'consent_refuse',
  ABTEST: 'abtest',
  AT_LIB: 'at_lib',
  MAP_EVENT: 'map_event'
}

export default SERVICES
