import { formatNaturalDateByLocale, getCurrentDateISO } from '../../domain/utils/date'
import { ARRIVAL_LATER, DEPARTURE_LATER, DEPARTURE_NOW } from './travelTimeOptionsWhenTypes'

import { PARAM_ARRIVAL_DATE_TIME, PARAM_DEPARTURE_DATE_TIME } from '../../domain/router/queryParameters.constants'
import { createCustomSelector } from '../../domain/utils/react-reselect'

export const selectTravelTimeOptions = store => store?.travelTimeOptions

export const selectTravelTimeOptionsDate = createCustomSelector(
  selectTravelTimeOptions,
  travelTimeOptions => travelTimeOptions?.date ?? getCurrentDateISO(new Date())
)

export const selectTravelTimeOptionsTime = createCustomSelector(
  selectTravelTimeOptions,
  travelTimeOptions => travelTimeOptions?.time ?? '12:00'
)

export const selectTravelTimeOptionsWhen = createCustomSelector(
  selectTravelTimeOptions,
  travelTimeOptions => travelTimeOptions?.when
)

export const isDepartureNow = createCustomSelector(selectTravelTimeOptionsWhen, when => when === DEPARTURE_NOW)

export const getTimeTitle = createCustomSelector(
  selectTravelTimeOptionsWhen,
  selectTravelTimeOptionsDate,
  selectTravelTimeOptionsTime,
  (when, date, time) => (locale, translations) => {
    const type =
      when === DEPARTURE_NOW || when === DEPARTURE_LATER
        ? translations['itinerary.options.date.start']
        : translations['itinerary.options.date.end']
    if (when === DEPARTURE_NOW) return `${type} ${translations['date.now']}`
    const msg = formatNaturalDateByLocale({
      date,
      locale, // FIXME: not consumed
      today: translations['date.today'],
      tomorrow: translations['date.tomorrow'],
      the: translations['date.the']
    })
    return `${type} ${msg} ${translations['date.at']} ${time}`
  }
)

export const selectTravelTimeQueryStringParams = createCustomSelector(
  selectTravelTimeOptionsWhen,
  selectTravelTimeOptionsDate,
  selectTravelTimeOptionsTime,
  (when, date, time) => {
    const datetime = `${date}T${time}`
    if (when === DEPARTURE_LATER) {
      return { [PARAM_DEPARTURE_DATE_TIME]: datetime }
    } else if (when === ARRIVAL_LATER) {
      return { [PARAM_ARRIVAL_DATE_TIME]: datetime }
    }
    return {}
  }
)
