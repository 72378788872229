export const SET_SEO_TOWN_DETAILS = 'SET_SEO_TOWN_DETAILS'
export const SET_ARRIVAL_CITY_DESCRIPTION = 'SET_ARRIVAL_CITY_DESCRIPTION'
export const SET_SEO_CITIES_FOR_ACTIVITIES = 'SET_SEO_CITIES_FOR_ACTIVITIES'
export const SET_SEO_CITIES_FOR_FOOTER = 'SET_SEO_CITIES_FOR_FOOTER'
export const SET_SEO_SIBLINGS_CITY_ACTIVITY = 'SET_SEO_SIBLINGS_CITY_ACTIVITY'
export const SET_SEO_APPS_FOR_FOOTER = 'SET_SEO_APPS_FOR_FOOTER'
export const SET_ACTIVITIES_FOR_STEPS = 'SET_ACTIVITIES_FOR_STEPS'
export const SET_ACTIVITIES = 'SET_ACTIVITIES'
export const SET_POIS_FOR_CITY = 'SET_POIS_FOR_CITY'
export const SET_TOP_POIS = 'SET_TOP_POIS'
export const SET_BREADCRUMB_LINKS = 'SET_BREADCRUMB_LINKS'
export const RESET_BREADCRUMB_LINKS = 'RESET_BREADCRUMBS'
export const SET_TOP_SEARCH_LINKS = 'SET_TOP_SEARCH_LINKS'
export const SET_CITIES_TO_DISCOVER_LINKS = 'SET_CITIES_TO_DISCOVER_LINKS'
export const SET_RUBRICS = 'SET_RUBRICS'
export const SET_CURRENT_RUBRIC = 'SET_CURRENT_RUBRIC'
export const SET_TOWN_FROM_PATH = 'SET_TOWN_FROM_PATH'
export const SET_SEO_ITINERARY_DATA = 'SET_SEO_ITINERARY_DATA'
export const SET_SEO_DIRECTORY_PAGE_IDX = 'SET_SEO_DIRECTORY_PAGE_IDX'
export const SET_SEO_DIRECTORY_DATA = 'SET_SEO_DIRECTORY_DATA'
export const SET_SEO_FAQ_DATA = 'SET_SEO_FAQ_DATA'
export const SET_SEO_LAYER_RUBRIC_LINKS = 'SET_SEO_LAYER_RUBRIC_LINKS'
export const SET_DEPARTMENT_TOWN_LIST = 'SET_DEPARTMENT_TOWN_LIST'
export const SET_SEO_H1_TITLE = 'SET_SEO_H1_TITLE'
export const SET_SEO_CURRENT_RUBRIC = 'SET_SEO_CURRENT_RUBRIC'
export const SET_SEO_PARENT_RUBRIC = 'SET_SEO_PARENT_RUBRIC'
export const RESET_SEO_CURRENT_RUBRIC = 'RESET_SEO_CURRENT_RUBRIC'
export const RESET_SEO_PARENT_RUBRIC_LABEL = 'RESET_SEO_PARENT_RUBRIC_LABEL'
export const RESET_SEO_PARENT_RUBRIC_ID = 'RESET_SEO_PARENT_RUBRIC_ID'
export const RESET_SEO_H1_TITLE = 'RESET_SEO_H1_TITLE'
