export const MULTIPATH_RESET = 'MULTIPATH_RESET'
export const MULTIPATH_RESET_EXCEPT_STEPS = 'MULTIPATH_RESET_EXCEPT_STEPS'
export const MULTIPATH_RESET_ROUTES = 'MULTIPATH_RESET_ROUTES'
export const SET_STEP_IDX = 'SET_STEP_IDX'
export const SET_STEP_LOCATION = 'SET_STEP_LOCATION'
export const SET_STEP_RESOLVED_LOCATION = 'SET_STEP_RESOLVED_LOCATION'
export const ADD_STEP = 'ADD_STEP'
export const REMOVE_STEP = 'REMOVE_STEP'
export const INVERT_STEPS = 'INVERT_STEPS'
export const SET_CURRENT_STEPS = 'SET_CURRENT_STEPS'
export const SET_PROVIDERS = 'SET_PROVIDERS'
export const SET_CURRENT_PROVIDER = 'SET_CURRENT_PROVIDER'
export const SET_CURRENT_ROUTE = 'SET_CURRENT_ROUTE'
export const SET_CURRENT_MODE = 'SET_CURRENT_MODE'
export const SET_CURRENT_ROADBOOK = 'SET_CURRENT_ROADBOOK'
export const SET_ROADBOOK_TIMESTAMP = 'SET_ROADBOOK_TIMESTAMP'
export const RESET_ROADBOOK = 'RESET_ROADBOOK'
export const ROADBOOK_ERROR = 'ROADBOOK_ERROR'
export const ROADBOOK_EXPIRED = 'ROADBOOK_EXPIRED'
export const MULTIPATH_COMPUTE = 'MULTIPATH_COMPUTE'
export const MULTIPATH_RECOMPUTE = 'MULTIPATH_RECOMPUTE'
export const MULTIPATH_COMPUTE_PROVIDER = 'MULTIPATH_COMPUTE_PROVIDER'
export const MULTIPATH_COMPLETE_PROVIDER = 'MULTIPATH_COMPLETE_PROVIDER'
export const ADD_ROUTES = 'ADD_ROUTES'
export const MULTIPATH_COMPLETE = 'MULTIPATH_COMPLETE'
export const ROUTE_NOT_FOUND_ERROR = 'ROUTE_NOT_FOUND_ERROR'
export const ROUTE_API_UNKNOW_ERROR = 'ROUTE_API_UNKNOW_ERROR'
export const DROP_PROVIDER_ROUTES = 'DROP_PROVIDER_ROUTES'
export const REMOVE_PROVIDER_OPTIONAL_OR_SIMPLIFIED_STATUS = 'REMOVE_PROVIDER_OPTIONAL_OR_SIMPLIFIED_STATUS'
export const SET_POIS_ON_ROUTE = 'SET_POIS_ON_ROUTE'
export const RESET_POIS_ON_ROUTE = 'RESET_POIS_ON_ROUTE'
export const SET_PROVIDERS_COLORS = 'SET_PROVIDERS_COLORS'
export const SET_ACTIVE_SORT = 'SET_ACTIVE_SORT'
export const RESET_ACTIVE_SORT = 'RESET_ACTIVE_SORT'
export const SET_ITINERARY_AVAILABILITY_INFO = 'SET_ITINERARY_AVAILABILITY_INFO'
