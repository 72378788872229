import {
  HISTORY_PUSH,
  REVERSE_RESOLVE_ROUTE_AND_REGISTER,
  HISTORY_POP,
  HISTORY_SAVE_POST_POP,
  HISTORY_NAVIGATION_DONE
} from './history.actionTypes'
import { ROUTE_HOME } from '../../routes'
import { isPagelessRoute } from '../../domain/router/routeUtils'

const getInitialState = () => ({
  route: ROUTE_HOME,
  fullRoute: '/',
  routeOptions: {},
  routeDone: undefined,
  prevFullRouteNonPageLess: '/'
})

export default function historyReducer(state = getInitialState(), action) {
  switch (action && action.type) {
    case REVERSE_RESOLVE_ROUTE_AND_REGISTER:
    case HISTORY_SAVE_POST_POP:
    case HISTORY_PUSH: {
      const { route, routeOptions, fullRoute } = action.payload
      const prevFullRouteNonPageLess = isPagelessRoute(state.route) ? state?.prevFullRouteNonPageLess : state.fullRoute
      return {
        ...state,
        route,
        routeOptions,
        fullRoute,
        prevRoute: state.route,
        prevFullRouteNonPageLess
      }
    }
    case HISTORY_NAVIGATION_DONE:
      const { route } = action.payload
      return {
        ...state,
        routeDone: route
      }
    case HISTORY_POP:
    default:
      return state
  }
}
