import classNames from 'classnames'
import React, { useEffect } from 'react'
import { useStore } from 'react-redux'
import { dispatchTag } from '../../store/analytics/analytics.actions'
import { navigateToExternal } from '../../store/history/history.actions'
import { defer } from '../../utils/function'
import { buildClickDataTag } from '../landingpages/landingPage.utils'
import { useAction } from '../utils/react-redux'

const TagActionLink = ({
  children,
  className,
  disableNoreferrer,
  elementRef,
  href,
  mouseEnter,
  mouseOut,
  nofollow,
  noNavigate = false,
  onClick,
  shouldTagImpression,
  style,
  tagInfo,
  targetBlank,
  title,
  type,
  dataset = {}
}) => {
  const execNavigateToExternal = useAction(navigateToExternal)
  const execDispatchTag = useAction(dispatchTag)
  const { getState } = useStore()

  useEffect(() => {
    if (shouldTagImpression) {
      execDispatchTag({
        type,
        tagInfo,
        isImpression: true
      })
    }
  }, [])

  const onLinkClick = evt => {
    if (!targetBlank) {
      evt.preventDefault()
      if (!noNavigate) execNavigateToExternal({ url: href })
    }
    if (type) {
      const tagInfoWithOptionnalDataSet = { ...evt.target.dataset, ...tagInfo }
      execDispatchTag({
        type,
        tagInfo: tagInfoWithOptionnalDataSet
      })
    }
    if (onClick) defer(onClick, evt)
  }

  const dataAttributes = Object.entries(dataset ?? {})?.reduce((acc, current) => {
    const [key, value] = current
    acc[`data-${key}`] = value
    return acc
  }, {})

  const elProps = {
    ...dataAttributes
  }

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      ref={elementRef}
      className={classNames(className, __SERVER__ && 'click-event')}
      target={targetBlank ? '_blank' : '_self'}
      href={href}
      rel={`noopener${disableNoreferrer ? '' : ' noreferrer'}${nofollow ? ' nofollow' : ''}`}
      title={title}
      onClick={onLinkClick}
      data-tag={__SERVER__ && buildClickDataTag(type, getState(), tagInfo)}
      style={style}
      onMouseEnter={mouseEnter}
      onMouseOut={mouseOut}
      {...elProps}
    >
      {children}
    </a>
  )
}

export default TagActionLink
