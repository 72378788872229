import searchMiddlewares from './search/search.middlewares'
import searchHistoryMiddlewares from './search/searchHistory/searchHistory.middlewares'
import addressMiddlewares from './address/address.middlewares'
import geoentityMiddlewares from './geoentity/geoentity.middlewares'
import itineraryMiddlewares from './itinerary/itinerary.middlewares'
import geoentityOnItineraryMiddlewares from './itinerary/geoentity-on-itinerary.middlewares'
import historyMiddlewares from './history/history.middlewares'
import analyticsMiddlewares from './analytics/analytics.middlewares'
import uiMiddlewares from './ui/ui.middlewares'
import cmpMiddlewares from './cmp/cmp.middlewares'
import mapMiddlewares from './map/map.middlewares'
import toolsMiddlewares from './tools/tools.middlewares'
import adMiddlewares from './ad/ad.middlewares'
import userAccountMiddlewares from './userAccount/userAccount.middlewares'
import quizMiddlewares from './quiz/quiz.middlewares'
import { flatten } from '../utils/array'

// FIXME NES-1760
const middlewares = [
  searchMiddlewares,
  searchHistoryMiddlewares,
  addressMiddlewares,
  geoentityMiddlewares,
  itineraryMiddlewares,
  geoentityOnItineraryMiddlewares,
  historyMiddlewares, // FIXME NES-2734
  analyticsMiddlewares,
  uiMiddlewares,
  cmpMiddlewares,
  mapMiddlewares,
  toolsMiddlewares,
  adMiddlewares,
  userAccountMiddlewares,
  quizMiddlewares
]

export default function build(history) {
  return flatten(middlewares).map(middleware => store => middleware(store, history))
}
