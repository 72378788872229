import { getService } from '../../parameters'
import { REGISTER_CONSENT } from './cmp.actionTypes'
import { selectHasUserConsentedForTracking, selectHasUserConsentedForNativeAdvertising } from './cmp.selectors'
import { paramsSerializerRepeat } from '../../domain/utils/url'
import CMPService from '../../domain/cmp/CMPService'
import SERVICES from '../../services.constants'

export const logConsentMiddleware =
  ({ getState }) =>
  next =>
  action => {
    const r = next(action)

    if (__SERVER__) return r

    switch (action.type) {
      case REGISTER_CONSENT: {
        const nativeAdvertising = selectHasUserConsentedForNativeAdvertising(getState())
        const tracking = selectHasUserConsentedForTracking(getState())

        const consentLogQueryParam = paramsSerializerRepeat({
          version: CMPService.version,
          opt: nativeAdvertising || tracking ? 'in' : 'out',
          tracking: tracking ? 'Y' : 'N',
          advertising: nativeAdvertising ? 'Y' : 'N'
        })

        const serviceKey = nativeAdvertising || tracking ? SERVICES.CONSENT : SERVICES.CONSENT_REFUSE
        const { url } = getService(serviceKey, `/?${consentLogQueryParam}`)

        const img = new Image()
        img.src = url
        break
      }
    }

    return r
  }

export default [logConsentMiddleware]
