import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import s from './PopupPoi.less'
import { TAG_CLICK_POI } from '../../../store/analytics/analytics.actionTypes'

import classNames from 'classnames'
import TagActionButton from '../../genericComponents/TagActionButton'
import {
  selectFocusedPoi,
  selectFocusedPoiIllustrationUrl,
  selectGeoentityRubricId
} from '../../../store/geoentity/geoentity.selectors'
import { POI_BIG_MARKER_HEIGHT, POI_BIG_MARKER_WIDTH } from '../markers/MarkerConstants'
import { SPD_GEOENTITY_GOTO, SPD_TOOLTIP_GEOENTITY, tagBi } from '../../analytics/BiTagger'
import { navigateToGeoentity } from '../../../store/history/history.actions'
import { focusGeoentity, unfocusGeoentity, requestUnfocusGeoentity } from '../../../store/geoentity/geoentity.actions'
import { useAction, useSelectorWithProps, withEverySelectorTruthy } from '../../utils/react-redux'
import { selectIsItineraryUniverse, selectIsSearchPage } from '../../../store/history/history.selectors'
import { isGE } from '../../Device'
import CrossBrowserImage from '../../genericComponents/CrossBrowserImage'
import GoToItineraryButton from '../../genericComponents/GoToItineraryButton'
import RectangleActionButton, { COLOR_GREY_EMPTY } from '../../genericComponents/buttons/RectangleActionButton'
import AddPoiStepButton from '../../itinerary/steps/AddPoiStepButton'
import PopupFuelPoi from './PopupFuelPoi'
import { isStationServiceRubric } from '../../../store/geoentity/geoentity.helpers'

export const POPUP_POI_OFFSET = {
  bottom: [0, -POI_BIG_MARKER_HEIGHT],
  left: [POI_BIG_MARKER_WIDTH / 2, -POI_BIG_MARKER_HEIGHT / 2],
  right: [-POI_BIG_MARKER_WIDTH / 2, -POI_BIG_MARKER_HEIGHT / 2],
  'bottom-left': [0, -POI_BIG_MARKER_HEIGHT],
  'bottom-right': [0, -POI_BIG_MARKER_HEIGHT]
}

export const ButtonsSection = ({ buttonClass = s.buttonsSection, poi, rubricId, execUnfocusGeoentity }) => {
  const searchPage = useSelector(selectIsSearchPage)
  const hasItineraryButton = !searchPage || isGE()
  const isItineraryUniverse = useSelector(selectIsItineraryUniverse)
  return (
    <>
      <div className={buttonClass}>
        <RectangleActionButton color={COLOR_GREY_EMPTY} label="Plus d'infos" />
        {hasItineraryButton && (
          <GoToItineraryButton
            location={poi}
            tagProps={{ tagInfo: { ancrage: 'PopinPoi', catId: rubricId } }}
            biProps={{
              tagId: SPD_GEOENTITY_GOTO,
              params: { geoentity: poi.id }
            }}
            onBeforeNavigate={execUnfocusGeoentity}
          />
        )}
      </div>
      {hasItineraryButton && isItineraryUniverse && (
        <div className={s.addStepButton}>
          <AddPoiStepButton location={poi} />
        </div>
      )}
    </>
  )
}

const PopupClassicPoi = ({ poi, rubricId, onMouseEnter, onMouseLeave, onGeoentityClick, execUnfocusGeoentity }) => {
  const { name, way, postcode, town, additionalInfo } = poi
  const illustrationUrl = useSelector(selectFocusedPoiIllustrationUrl)
  const hasIllustration = illustrationUrl
  return (
    <div
      className={classNames(s.container, hasIllustration && s.withIllustration)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <TagActionButton
        onClick={onGeoentityClick}
        deferClick
        type={TAG_CLICK_POI}
        tagInfo={{ affichage: 'carte', appId: additionalInfo?.appId, catId: rubricId }}
      >
        {hasIllustration && <CrossBrowserImage className={s.illustration} src={illustrationUrl} alt={name} />}
        <div className={s.title}>{name}</div>
        <div className={s.address}>
          {' '}
          <div>{way}</div>
          <div>
            {postcode} {town}
          </div>
        </div>
        <ButtonsSection poi={poi} rubricId={rubricId} execUnfocusGeoentity={execUnfocusGeoentity} />
      </TagActionButton>
    </div>
  )
}

const PopupPoi = () => {
  const poi = useSelector(selectFocusedPoi)
  const rubricId = useSelectorWithProps(selectGeoentityRubricId, { geoentity: poi })
  const isFuelPopin = isStationServiceRubric(rubricId)

  const { id } = poi

  const execNavigateToGeoentity = useAction(navigateToGeoentity)
  const execFocusGeoentity = useAction(focusGeoentity)
  const execUnfocusGeoentity = useAction(unfocusGeoentity)
  const execRequestUnfocusGeoentity = useAction(requestUnfocusGeoentity)

  useEffect(() => {
    tagBi(SPD_TOOLTIP_GEOENTITY, { geoentity: id })
  }, [])

  const onGeoentityClick = useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()

      execUnfocusGeoentity()
      execNavigateToGeoentity({ id })
    },
    [execUnfocusGeoentity, execNavigateToGeoentity, id]
  )

  const onMouseEnter = useCallback(() => {
    execFocusGeoentity(id)
  }, [id])

  const onMouseLeave = useCallback(() => {
    execRequestUnfocusGeoentity(id)
  }, [id])

  return isFuelPopin ? (
    <PopupFuelPoi
      poi={poi}
      rubricId={rubricId}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onGeoentityClick={onGeoentityClick}
      execUnfocusGeoentity={execUnfocusGeoentity}
    />
  ) : (
    <PopupClassicPoi
      poi={poi}
      rubricId={rubricId}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onGeoentityClick={onGeoentityClick}
      execUnfocusGeoentity={execUnfocusGeoentity}
    />
  )
}

export default withEverySelectorTruthy(selectFocusedPoi)(PopupPoi)
