import { SEND_PAGE_TAG, SEND_PAGE_TAG_V3 } from './analytics.actionTypes'

export const dispatchTag = tag => dispatch => tag && dispatch(tag)

export const dispatchTagPage = action => dispatch =>
  dispatch({
    type: SEND_PAGE_TAG,
    ...(action?.payload && { payload: action.payload })
  })

export const dispatchTagPageV3 = action => dispatch =>
  dispatch({
    type: SEND_PAGE_TAG_V3,
    ...(action?.payload && { payload: action.payload })
  })
