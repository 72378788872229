import { setUserIdInTag, unsetUserIdInTag } from '../../domain/analytics/ATInternetService'
import {
  setItineraryResultsUserAccountIncentiveDisplayed,
  setUserAccountIncentivePopinDisplayed,
  showUserAccountPreferencesIncentive
} from '../ui/ui.actions'
import { loadFavorites, loadTravelOptions } from './userAccount.actions'
import { SET_USER_CONNECTED, SET_USER_NOT_CONNECTED } from './userAccount.actionTypes'
import { selectUserIdForTag } from './userAccount.selectors'
import { SET_PREFERRED_MODE, SET_PREFERRED_OPTIONS_FOR_MODE } from '../travelOptions/travelOptions.actionTypes'
import { once } from '../../utils/function'

export const loadFavoritesAndTravelOptionsOnConnected =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const n = next(action)
    if (action.type === SET_USER_CONNECTED) {
      loadFavorites()(dispatch, getState)
      loadTravelOptions()(dispatch, getState)
    }
    return n
  }

export const setUserIdInTagWhenConnected =
  ({ getState }) =>
  next =>
  action => {
    const n = next(action)
    if (action.type === SET_USER_CONNECTED) setUserIdInTag(selectUserIdForTag(getState()))
    return n
  }

export const unsetUserIdInTagWhenDisconnected = () => next => action => {
  const n = next(action)
  if (action.type === SET_USER_NOT_CONNECTED) unsetUserIdInTag()
  return n
}

export const setIncentivePopinTimestampWhenConnected =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const n = next(action)
    if (action.type === SET_USER_CONNECTED) {
      setUserAccountIncentivePopinDisplayed()(dispatch, getState)
      setItineraryResultsUserAccountIncentiveDisplayed()(dispatch, getState)
    }
    return n
  }

const showUserAccountPreferencesIncentiveOnce = once((dispatch, getState) =>
  showUserAccountPreferencesIncentive()(dispatch, getState)
)

export const displayIncentiveWhenPreferencesChanged =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const n = next(action)
    switch (action.type) {
      case SET_PREFERRED_OPTIONS_FOR_MODE:
      case SET_PREFERRED_MODE:
        showUserAccountPreferencesIncentiveOnce(dispatch, getState)
    }
    return n
  }

export default [
  loadFavoritesAndTravelOptionsOnConnected,
  setUserIdInTagWhenConnected,
  unsetUserIdInTagWhenDisconnected,
  setIncentivePopinTimestampWhenConnected,
  displayIncentiveWhenPreferencesChanged
]
