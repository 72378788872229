import { RESET_COMPUTE_RADIUS, SET_COMPUTE_RADIUS_POSITION, SET_COMPUTE_RADIUS, SET_DEVICE } from './tools.actionTypes'

export const ONE_KM = 1000
export const ONE_HUNDRED_KM = 100 * ONE_KM

export const setDevicePE = deviceIsPE => dispatch =>
  dispatch({
    type: SET_DEVICE,
    payload: deviceIsPE
  })

export const setComputeRadiusPositionFromMarker = position => dispatch =>
  dispatch({
    type: SET_COMPUTE_RADIUS_POSITION,
    payload: { position, fromMarker: true }
  })

export const setComputeRadiusPosition = position => dispatch =>
  dispatch({
    type: SET_COMPUTE_RADIUS_POSITION,
    payload: { position }
  })

export const resetComputeRadius = () => dispatch =>
  dispatch({
    type: RESET_COMPUTE_RADIUS
  })

export const setComputeRadiusRadius = radius => dispatch =>
  dispatch({
    type: SET_COMPUTE_RADIUS,
    payload: { radius }
  })
