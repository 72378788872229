import { clickTagsMiddlewares } from './clickTags.middlewares'
import clickTagsV3Middlewares from './v3/clickTagsV3.middlewares'
import pageTagsMiddlewares from './pageTags.middlewares'
import pageTagsV3Middlewares from './v3/pageTagsV3.middlewares'
import { publisherTagsMiddleware } from './publisherTags.middlewares'
import { multipathPublisherTagsMiddleware } from './multipathPublisherTags.middlewares'
import geoentityBiTags from './geoentityBiTags.middlewares'
import handleConsent from './analyticsGDPR.middlewares'
import userPageTagsMiddlewares from './userPageTags.middlewares'
import abtestTagsMiddlewares from './abtestTags.middlewares'

export default [
  clickTagsMiddlewares,
  clickTagsV3Middlewares,
  ...pageTagsMiddlewares,
  ...pageTagsV3Middlewares,
  ...userPageTagsMiddlewares,
  publisherTagsMiddleware,
  multipathPublisherTagsMiddleware,
  ...geoentityBiTags,
  ...abtestTagsMiddlewares,
  handleConsent
]
