import debug from 'debug'
import { isGE } from '../../domain/Device'
import { POPUP_TYPE_ADDRESS, POPUP_TYPE_POI } from '../../domain/map/popup/popup.selectors'
import { ONE_WEEK_MS } from '../../domain/utils/date'
import { ROUTE_GEOENTITY_AT_ADDRESS } from '../../routes'
import { closeVde } from '../ad/ad.actions'
import { FOCUS_GEOENTITY, RESET_GEOENTITY_LIST, UNFOCUS_GEOENTITY } from '../geoentity/geoentity.actionTypes'
import { HISTORY_NAVIGATION_DONE, HISTORY_PUSH } from '../history/history.actionTypes'
import { selectCurrentHistoryRoute } from '../history/history.selectors'
import { displayMapTooSlowTooltip } from '../ui/ui.actions'
import { selectMapTooSlowTimestamp } from '../ui/ui.selectors'
import { MAP_SET_MODE, MAP_SET_THEME, SEND_MAP_LOADING_TIME, SET_MAP_EVENT_POPIN } from './map.actionTypes'
import { displayPopup, hidePopup, resetMapEventPopin, turnOffFullscreen } from './map.actions'
import geolocMiddlewares from './map.geoloc.middlewares'
import { selectIsMapFullScreen, selectIsVectorMap, selectMapEventPopin, selectPopupType } from './map.selectors'

const d = debug('map')

export const VECTO_LOAD_TIME_AFTER_SHOWING_TOO_SLOW_POPIN = 6

export const TIME_BETWEEN_TOO_SLOW_POPIN_DISPLAYS = ONE_WEEK_MS

export const mapPopupMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    switch (action.type) {
      case UNFOCUS_GEOENTITY: {
        if (selectPopupType(getState()) === POPUP_TYPE_POI) hidePopup()(dispatch)
        break
      }
      case RESET_GEOENTITY_LIST: {
        if (selectPopupType(getState()) !== POPUP_TYPE_ADDRESS) hidePopup()(dispatch)
        break
      }
      case FOCUS_GEOENTITY: {
        if (selectCurrentHistoryRoute(getState()) !== ROUTE_GEOENTITY_AT_ADDRESS) {
          displayPopup(POPUP_TYPE_POI)(dispatch)
        } else {
          hidePopup()(dispatch)
        }
        break
      }
    }
    return r
  }

export const mapLoadedMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    if (action.type === SEND_MAP_LOADING_TIME) {
      const { loadTime, mapHasGoodSupport } = action.payload
      const tooSlowTimestamp = selectMapTooSlowTimestamp(getState())
      const delta = Date.now() - tooSlowTimestamp
      if (
        selectIsVectorMap(getState()) &&
        loadTime > VECTO_LOAD_TIME_AFTER_SHOWING_TOO_SLOW_POPIN &&
        delta > TIME_BETWEEN_TOO_SLOW_POPIN_DISPLAYS
      ) {
        d('map is slow to load, displaying too slow popup', loadTime, delta)
        if (!mapHasGoodSupport) displayMapTooSlowTooltip()(dispatch, getState)
      }
    }
    return next(action)
  }

export const disableFullScreenOnNavigation =
  ({ dispatch, getState }) =>
  next =>
  action => {
    if (isGE() && action.type === HISTORY_PUSH && selectIsMapFullScreen(getState())) {
      turnOffFullscreen()(dispatch, getState)
    }
    return next(action)
  }

export const mapEventPopinLifeCycle =
  ({ dispatch, getState }) =>
  next =>
  action => {
    if (action.type === SET_MAP_EVENT_POPIN) {
      closeVde()(dispatch, getState)
    } else if (
      (action.type === HISTORY_NAVIGATION_DONE || action.type === MAP_SET_MODE || action.type === MAP_SET_THEME) &&
      selectMapEventPopin(getState())
    ) {
      resetMapEventPopin()(dispatch, getState)
    }

    return next(action)
  }

export default [
  mapPopupMiddleware,
  mapLoadedMiddleware,
  disableFullScreenOnNavigation,
  mapEventPopinLifeCycle,
  ...geolocMiddlewares
]
