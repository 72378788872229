import { combineReducers } from 'redux'
import adReducer from './ad/ad.reducers'
import addressReducer from './address/address.reducers'
import suggestReducer from './suggest/suggest.reducers'
import itineraryReducer from './itinerary/itinerary.reducers'
import mapReducer from './map/map.reducers'
import geoentityReducer from './geoentity/geoentity.reducers'
import searchReducer from './search/search.reducers'
import travelOptionsReducers from './travelOptions/travelOptions.reducers'
import travelTimeOptionsReducers from './travelTimeOptions/travelTimeOptions.reducers'
import historyReducer from './history/history.reducers'
import uiReducer from './ui/ui.reducers'
import poiassetsReducers from './poiassets/poiassets.reducers'
import localeReducer from './locale/locale.reducers'
import cmpReducers from './cmp/cmp.reducers'
import seoReducers from './seo/seo.reducers'
import toolsReducer from './tools/tools.reducers'
import pushReducers from './push/push.reducers'
import abTestReducer from './abtest/abtest.reducers'
import userAccountReducer from './userAccount/userAccount.reducers'
import quizReducer from './quiz/quiz.reducers'

// TODO renome each file to reducers (plural) not reducer (singular)
export default combineReducers({
  abtest: abTestReducer,
  ad: adReducer,
  locale: localeReducer,
  address: addressReducer,
  suggest: suggestReducer,
  map: mapReducer,
  ui: uiReducer,
  search: searchReducer,
  itinerary: itineraryReducer,
  travelOptions: travelOptionsReducers,
  travelTimeOptions: travelTimeOptionsReducers,
  history: historyReducer,
  geoentity: geoentityReducer,
  poiassets: poiassetsReducers,
  cmp: cmpReducers,
  seo: seoReducers,
  tools: toolsReducer,
  push: pushReducers,
  userAccount: userAccountReducer,
  quiz: quizReducer
})
