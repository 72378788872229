import {
  SET_GEOENTITY_LIST,
  ADD_GEOENTITY_LIST,
  RESET_GEOENTITY_LIST,
  SELECT_GEOENTITY,
  UNSELECT_GEOENTITY,
  GEOENTITY_NO_RESULT,
  REFRESH_SELECTED_GEOENTITY_DATA,
  POPULATE_GEOENTITY_TAB_CONTENT,
  SET_GEOENTITY_TAB_ID,
  SET_GEOENTITY_TAB_BLOCKS_IDX,
  SET_SINGLE_GEOENTITY,
  FOCUS_GEOENTITY,
  UNFOCUS_GEOENTITY,
  SET_GEOENTITIES_REQUESTED,
  SET_GEOENTITIES_RECEIVED,
  SET_GEOENTITY_FILTER_PARAMETER,
  RESET_GEOENTITY_FILTER_PARAMETER,
  GEOENTITY_NOT_FOUND,
  SET_FUEL_INFORMATION
} from './geoentity.actionTypes'
import { matches, omit } from '../../utils/object'
import { uniqBy } from '../../utils/array'

const applyToCurrentGeoentity = (state, fn) => {
  const { currentPoiId, pois } = state

  return pois.map(poi => {
    if (poi.id === currentPoiId) return fn(poi)
    return poi
  })
}

const setTabIdAndIdx = poi => {
  const currentBlocksIdx = 0
  const currentTabId = poi?.tabs?.[currentBlocksIdx]?.appId
  return { ...poi, currentTabId, currentBlocksIdx }
}

export default (state = {}, action) => {
  switch (action && action.type) {
    case SET_GEOENTITIES_REQUESTED: {
      return {
        ...state,
        isWaitingForRequestedGeoentities: true
      }
    }
    case SET_GEOENTITIES_RECEIVED: {
      return {
        ...state,
        isWaitingForRequestedGeoentities: false
      }
    }
    case SET_SINGLE_GEOENTITY:
      const poi = action.payload
      const poiWithTabIdAndIdx = setTabIdAndIdx(poi)
      return {
        ...state,
        pois: (state.pois || []).length
          ? state.pois.map(p => (p.id === poi.id ? poiWithTabIdAndIdx : p))
          : [poiWithTabIdAndIdx],
        currentPoiId: poi.id,
        focusedPoiId: undefined
      }
    case SET_GEOENTITY_LIST: {
      const { pois, viewport, filters = [], extendBbox = 0 } = action.payload
      const oldStateWithoutFocusedPoiId = omit(state, ['focusedPoiId'])
      const currentFocusedPoiId = state?.focusedPoiId
      const focusedPoiId = currentFocusedPoiId ? pois.find(poi => poi?.id === currentFocusedPoiId)?.id : undefined

      return {
        ...oldStateWithoutFocusedPoiId,
        ...(focusedPoiId ? { focusedPoiId } : {}),
        pois,
        viewport,
        filters,
        extendBbox: Boolean(extendBbox)
      }
    }
    case ADD_GEOENTITY_LIST: {
      const { pois, viewport, filters = [] } = action.payload
      return {
        ...state,
        pois: uniqBy([...(state.pois || []), ...pois], 'id'),
        viewport,
        filters
      }
    }
    case SELECT_GEOENTITY:
      const { currentPoiId } = action.payload
      return {
        ...state,
        currentPoiId
      }
    case FOCUS_GEOENTITY:
      const { focusedPoiId } = action.payload
      return {
        ...state,
        focusedPoiId
      }
    case REFRESH_SELECTED_GEOENTITY_DATA: {
      const poiData = action.payload
      return {
        ...state,
        pois: applyToCurrentGeoentity(state, currentPoi => {
          return { ...currentPoi, ...setTabIdAndIdx(poiData) }
        })
      }
    }
    case POPULATE_GEOENTITY_TAB_CONTENT: {
      const tabContent = action.payload
      return {
        ...state,
        pois: applyToCurrentGeoentity(state, currentPoi => {
          const { tabs, currentTabId } = currentPoi
          const newTabs = tabs.map(tab => {
            const { appId, url } = tab
            if (appId !== currentTabId) return tab
            return {
              appId,
              url,
              content: tabContent
            }
          })
          return {
            ...currentPoi,
            tabs: newTabs
          }
        })
      }
    }
    case SET_GEOENTITY_TAB_ID: {
      const tabId = action.payload
      return {
        ...state,
        pois: applyToCurrentGeoentity(state, currentPoi => {
          return { ...currentPoi, currentTabId: tabId }
        })
      }
    }
    case SET_GEOENTITY_TAB_BLOCKS_IDX: {
      const idx = action.payload
      return {
        ...state,
        pois: applyToCurrentGeoentity(state, currentPoi => {
          return { ...currentPoi, currentBlocksIdx: idx }
        })
      }
    }
    case UNSELECT_GEOENTITY:
      return omit(state, ['currentPoiId'])
    case UNFOCUS_GEOENTITY:
      return omit(state, ['focusedPoiId'])
    case SET_GEOENTITY_FILTER_PARAMETER:
      return {
        ...state,
        filterParameters: {
          ...state.filterParameters,
          ...action.payload
        }
      }
    case RESET_GEOENTITY_FILTER_PARAMETER:
      return {
        ...state,
        filterParameters: undefined
      }
    case RESET_GEOENTITY_LIST: {
      if (!action.payload) {
        return {
          fuels: state.fuels
        }
      } else {
        const filter = action?.payload?.filter ?? {}
        return {
          ...state,
          pois: state.pois.filter(poi => !matches(filter)(poi))
        }
      }
    }
    case GEOENTITY_NO_RESULT:
      return {
        error: 'geoentity.noresult'
      }
    case GEOENTITY_NOT_FOUND:
      return {
        error: 'geoentity.notfound'
      }
    case SET_FUEL_INFORMATION:
      return {
        ...state,
        fuels: action.payload
      }
  }
  return state
}
