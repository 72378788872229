import {
  SET_USER_CONNECTED,
  SET_USER_NOT_CONNECTED,
  ADD_USER_FAVORITE,
  EDIT_USER_FAVORITE,
  DELETE_USER_FAVORITE,
  SET_USER_FAVORITES,
  SET_TOKEN
} from './userAccount.actionTypes'

export const getInitialState = () => ({
  connected: undefined,
  token: undefined,
  identity: {
    picture: undefined,
    name: ''
  },
  favorites: {
    home: undefined,
    work: undefined,
    others: []
  },
  error: undefined
})

export default function uiReducer(state = getInitialState(), action) {
  switch (action && action.type) {
    case SET_USER_CONNECTED:
      return {
        ...state,
        connected: true,
        token: action?.payload?.token,
        identity: action?.payload?.identity ?? {}
      }
    case SET_USER_NOT_CONNECTED:
      return {
        ...state,
        connected: false,
        token: undefined
      }
    case SET_TOKEN:
      return {
        ...state,
        token: action?.payload ?? state.token
      }
    case SET_USER_FAVORITES:
      const { favorites } = action.payload
      return {
        ...state,
        favorites
      }
    case ADD_USER_FAVORITE:
      return {
        ...state,
        favorites: {
          ...state.favorites,
          others: [...state.favorites.others, action.payload]
        }
      }
    case EDIT_USER_FAVORITE:
      const favorite = action.payload
      const previousFavoriteData = state?.favorites?.[favorite?.type] ?? {}
      return {
        ...state,
        favorites: {
          ...state.favorites,
          [favorite.type]: {
            ...previousFavoriteData,
            ...favorite
          }
        }
      }
    case DELETE_USER_FAVORITE:
      return {
        ...state,
        favorites: {
          ...state.favorites,
          others: state.favorites.others.filter(fav => fav.uuid !== action.payload.uuid)
        }
      }
    default:
      return state
  }
}
