import { init, captureException, addBreadcrumb, withScope, withProfiler, ErrorBoundary, setUser } from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { getDomain, isProduction, isRecette } from '../../parameters'
import { getMid } from '../analytics/BiTagger'
import debug from 'debug'

const d = debug('sentry')

export const sentryOn = isProduction() || isRecette()

/** Stationnary conditions */
const MAXIMUM_MONTHLY_AUDIENCE = 30_000_000
const SENTRY_ISSUE_QUOTA = 50_000
const SENTRY_PERF_QUOTA = 100_000

/** Hypothesis constants */
const PERCENTAGE_OF_AUDIENCE = 1 / 100
const OCCURENCE_OF_ERRORS_DURING_SESSION = 10
const TRANSACTION_PER_VISIT = 2

const sampleRate =
  SENTRY_ISSUE_QUOTA / (MAXIMUM_MONTHLY_AUDIENCE * OCCURENCE_OF_ERRORS_DURING_SESSION * PERCENTAGE_OF_AUDIENCE)

const tracesSampleRate = SENTRY_PERF_QUOTA / (MAXIMUM_MONTHLY_AUDIENCE * TRANSACTION_PER_VISIT)

d('sampleRate: %s / tracesSampleRate: %s', sampleRate, tracesSampleRate)

if (sentryOn) {
  init({
    dsn: 'https://dd315337fcb2408ab28f7c7e78d2511a@sentry.io/1320871',
    release: global.__APP_VERSION__,
    debug: false,
    integrations: [
      new BrowserTracing({
        startTransactionOnPageLoad: true,
        startTransactionOnLocationChange: false,
        shouldCreateSpanForRequest: () => false
      })
    ],
    environment: getDomain(),
    allowUrls: [global?.location?.host],
    ignoreErrors: [
      "Can't execute code from a freed script", // Common error from IE (even if we’ve disabled it)
      'ResizeObserver loop limit exceeded', // Common error which seems ok to ignore
      'AbortController is not defined', // Error on old browser not supporting AbortController
      'Blocked a restricted frame with origin' // security error massively called...
    ],
    denyUrls: [/c\.amazon-adsystem\.com/i, /j\.adlooxtracking\.com\/ads/i],
    sampleRate,
    normalizeDepth: 5,
    tracesSampleRate
  })
}

const SentryService = {
  captureException: (...args) => {
    if (sentryOn) {
      captureException(...args)
    } else {
      console.error('Sentry.captureException', ...args)
    }
  },
  addBreadcrumb: (category, message, level = 'info') => {
    if (sentryOn) {
      addBreadcrumb({
        category,
        message,
        level
      })
    } else {
      console.info('Sentry.addBreadcrumb', category, message, level)
    }
  },

  withScope,
  withProfiler,
  ErrorBoundary
}

setUser({ id: getMid() })

export default SentryService
