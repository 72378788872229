import { ADD_ROUTES } from '../itinerary/itinerary.actionTypes'

const cleanupMsg = 'removed by storeSanitizers'

export const polylineActionSanitizer = action =>
  action.type === ADD_ROUTES
    ? {
        ...action,
        payload: {
          ...action.payload,
          routes: action.payload.routes.map(route => ({
            ...route,
            sections: [cleanupMsg]
          }))
        }
      }
    : action

export const polylineStateSanitizer = state => ({
  ...state,
  itinerary: {
    ...state.itinerary,
    routes: state.itinerary.routes.map(route => ({
      ...route,
      sections: [cleanupMsg]
    })),
    roadbook: {
      ...(state.itinerary?.roadbook || {}),
      sections: (state.itinerary?.roadbook?.sections || []).map(section => ({
        ...section,
        polyline_definition: [cleanupMsg]
      }))
    }
  }
})

export const minimalDebugActionSanitizer = action => ({ type: action.type })

export const minimalDebugStateSanitizer = state => ({
  abtest: {
    ...state.abtest,
    config: null
  },
  history: state.history
})
