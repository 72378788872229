import {
  SET_ACTIVITIES_FOR_STEPS,
  SET_ARRIVAL_CITY_DESCRIPTION,
  SET_BREADCRUMB_LINKS,
  RESET_BREADCRUMB_LINKS,
  SET_TOP_SEARCH_LINKS,
  SET_SEO_TOWN_DETAILS,
  SET_SEO_CITIES_FOR_ACTIVITIES,
  SET_SEO_CITIES_FOR_FOOTER,
  SET_ACTIVITIES,
  SET_POIS_FOR_CITY,
  SET_TOP_POIS,
  SET_RUBRICS,
  SET_CURRENT_RUBRIC,
  SET_TOWN_FROM_PATH,
  SET_SEO_ITINERARY_DATA,
  SET_SEO_DIRECTORY_PAGE_IDX,
  SET_SEO_FAQ_DATA,
  SET_SEO_LAYER_RUBRIC_LINKS,
  SET_SEO_DIRECTORY_DATA,
  SET_DEPARTMENT_TOWN_LIST,
  SET_SEO_H1_TITLE,
  RESET_SEO_H1_TITLE,
  SET_SEO_APPS_FOR_FOOTER,
  SET_SEO_CURRENT_RUBRIC,
  RESET_SEO_CURRENT_RUBRIC,
  RESET_SEO_PARENT_RUBRIC_LABEL,
  RESET_SEO_PARENT_RUBRIC_ID,
  SET_SEO_PARENT_RUBRIC,
  SET_CITIES_TO_DISCOVER_LINKS,
  SET_SEO_SIBLINGS_CITY_ACTIVITY
} from './seo.actionTypes'

export const getInitialState = () => {
  return {
    h1title: undefined,
    seoTownDetails: undefined,
    arrivalCityDescription: '',
    seoActivitiesForDeparture: undefined,
    seoActivitesForArrival: undefined,
    seoCitiesForActivities: undefined,
    seoCitiesForFooter: undefined,
    seoAppsForFooter: undefined,
    breadcrumbs: [],
    topSearchLinks: [],
    displayTopSearchLinks: false,
    citiesToDiscoverLinks: [],
    rubric: undefined,
    townFromPath: undefined,
    directoryPageIdx: undefined,
    directoryData: undefined,
    faqData: undefined,
    layerRubricLinks: undefined,
    currentRubric: undefined,
    quiz: {
      regions: []
    }
  }
}

export default function seoReducers(state = getInitialState(), action) {
  switch (action.type) {
    case SET_SEO_TOWN_DETAILS:
      return {
        ...state,
        seoTownDetails: action.payload
      }
    case SET_ARRIVAL_CITY_DESCRIPTION:
      return {
        ...state,
        arrivalCityDescription: action.payload
      }
    case SET_ACTIVITIES_FOR_STEPS: {
      const { seoActivitiesForDeparture, seoActivitiesForArrival } = action.payload
      return {
        ...state,
        seoActivitiesForDeparture,
        seoActivitiesForArrival
      }
    }
    case SET_SEO_CITIES_FOR_ACTIVITIES:
      return { ...state, seoCitiesForActivities: action.payload }
    case SET_SEO_CITIES_FOR_FOOTER:
      return {
        ...state,
        seoCitiesForFooter: action.payload
      }
    case SET_SEO_APPS_FOR_FOOTER:
      return {
        ...state,
        seoAppsForFooter: action.payload
      }
    case SET_ACTIVITIES:
      return {
        ...state,
        seoActivities: action.payload
      }
    case SET_POIS_FOR_CITY: {
      const { pois, poisOvm } = action.payload
      return {
        ...state,
        pois,
        poisOvm
      }
    }
    case SET_TOP_POIS: {
      const { pois } = action.payload
      return {
        ...state,
        pois
      }
    }
    case SET_BREADCRUMB_LINKS:
      return {
        ...state,
        breadcrumbs: action.payload
      }
    case RESET_BREADCRUMB_LINKS:
      return {
        ...state,
        breadcrumbs: []
      }
    case SET_TOP_SEARCH_LINKS: {
      const topSearchLinks = action?.payload ?? []
      return {
        ...state,
        topSearchLinks,
        displayTopSearchLinks: topSearchLinks.length > 0
      }
    }
    case SET_CITIES_TO_DISCOVER_LINKS: {
      const citiesToDiscoverLinks = action?.payload ?? []
      return {
        ...state,
        citiesToDiscoverLinks
      }
    }
    case SET_RUBRICS: {
      return {
        ...state,
        rubrics: action.payload
      }
    }
    case SET_CURRENT_RUBRIC: {
      return {
        ...state,
        currentRubric: action.payload
      }
    }
    case SET_TOWN_FROM_PATH:
      return {
        ...state,
        townFromPath: action.payload
      }
    case SET_SEO_ITINERARY_DATA:
      return {
        ...state,
        itineraryData: action.payload
      }
    case SET_SEO_DIRECTORY_PAGE_IDX:
      return {
        ...state,
        directoryPageIdx: action.payload
      }
    case SET_SEO_DIRECTORY_DATA:
      return {
        ...state,
        directoryData: action.payload
      }
    case SET_SEO_FAQ_DATA:
      return {
        ...state,
        faqData: action.payload
      }
    case SET_SEO_LAYER_RUBRIC_LINKS:
      return {
        ...state,
        layerRubricLinks: action.payload
      }
    case SET_DEPARTMENT_TOWN_LIST:
      const { filteredTowns } = action.payload
      return {
        ...state,
        departmentTownList: filteredTowns
      }
    case SET_SEO_H1_TITLE:
      return {
        ...state,
        h1title: action.payload
      }

    case RESET_SEO_H1_TITLE:
      return {
        ...state,
        h1title: undefined
      }
    case SET_SEO_CURRENT_RUBRIC:
      return {
        ...state,
        rubric: {
          ...state.rubric,
          label: action.payload
        }
      }
    case SET_SEO_PARENT_RUBRIC:
      return {
        ...state,
        rubric: {
          ...state.rubric,
          parentId: action.payload.rubricId,
          parentLabel: action.payload.rubricLabel
        }
      }
    case SET_SEO_SIBLINGS_CITY_ACTIVITY:
      return {
        ...state,
        siblingsCityActivity: action.payload
      }
    case RESET_SEO_CURRENT_RUBRIC:
      return {
        ...state,
        rubric: {
          ...state.rubric,
          label: undefined
        }
      }
    case RESET_SEO_PARENT_RUBRIC_ID:
      return {
        ...state,
        rubric: {
          ...state.rubric,
          parentId: undefined
        }
      }
    case RESET_SEO_PARENT_RUBRIC_LABEL:
      return {
        ...state,
        rubric: {
          ...state.rubric,
          parentLabel: undefined
        }
      }
    default:
      return state
  }
}
