import { useCallback } from 'react'
import { useAction } from '../utils/react-redux'
import { hideUIElement, showUIElement } from '../../store/ui/ui.actions'
import { useSelector } from 'react-redux'
import { isUIElementDisplayed } from '../../store/ui/ui.selectors'

const useUIDisplay = uiElement => {
  const execShow = useAction(showUIElement)
  const execHide = useAction(hideUIElement)
  const isUIDisplayed = useSelector(isUIElementDisplayed(uiElement))
  const showUI = useCallback(() => execShow(uiElement), [execShow, uiElement])
  const hideUI = useCallback(() => execHide(uiElement), [execHide, uiElement])
  return {
    isUIDisplayed,
    showUI,
    hideUI
  }
}

export default useUIDisplay
