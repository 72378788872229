import { isGE } from '../../domain/Device'

export const getBasicRouteInfo = ({ routeId, provider, mode, time, fakeTrainRoute }) => ({
  routeId,
  provider: provider?.id,
  mode,
  duration: time?.label,
  time,
  fakeTrainRoute
})

export const getCtaDataFromRouteOrRoadbookAction = routeOrAction => {
  const ctaLink = routeOrAction?.cta_links?.[isGE() ? 'web_desktop' : 'web_mobile']
  const actionLabel = routeOrAction?.action_label
  const provider = routeOrAction?.provider?.id || routeOrAction?.provider_id

  if (ctaLink && actionLabel && provider) {
    return {
      ctaLink,
      actionLabel,
      provider
    }
  }
}

export const formatRoute = (route, modeRangeKey = 'monomode') => {
  if (!route) return {}
  const moreTripsLink = route.provider?.more_trips_links?.[isGE() ? 'web_desktop' : 'web_mobile']
  const ctaData = getCtaDataFromRouteOrRoadbookAction(route)
  const roadbookLink = route.roadbook_link || route.details_link
  const { gps, info, isOptional, icon, length } = route
  const hasOnlyRoadbookLink = roadbookLink && (!ctaData || !ctaData.ctaLink) && (!gps || isGE())
  const main_information = {
    title: route.main_information?.titles?.[modeRangeKey]?.components,
    labels: route.main_information?.labels?.[modeRangeKey]?.map?.(label => label.components)
  }

  return {
    ...getBasicRouteInfo(route),
    ...ctaData,
    price: {
      label: route.prices?.main?.label,
      value: route.prices?.main?.value
    },
    co2Emissions: route.co2_emissions?.label,
    co2Score: route.provider?.co2_score ?? 0,
    title: route.titles?.[modeRangeKey] ?? {},
    labels: route.labels?.[modeRangeKey] ?? {},
    main_information,
    info,
    icon,
    isOptional,
    roadbookLink,
    moreTripsLabel: route?.provider?.more_trips_label,
    moreTripsLink,
    priceCalendar: route?.is_eligible_for_price_calendar ?? false,
    gps,
    hasOnlyRoadbookLink,
    length
  }
}

const buildLabelComponentPicto = (icon, color) => ({ picto_id: icon, text: '', type: 'picto', color })

const buildLabelComponentText = (text, color, size) => ({ text, type: 'text', color, size })

export const LabelComponentBuilder = {
  picto: buildLabelComponentPicto,
  text: buildLabelComponentText
}

export const greenLeaf = '#00d856'
export const greyLeaf = '#888'

export const buildCo2LeafsLabelComponents = co2Score => {
  return [1, 2, 3].map(score => LabelComponentBuilder.picto('feuille-co2', co2Score >= score ? greenLeaf : greyLeaf))
}
