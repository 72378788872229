import { isGE } from '../../domain/Device'
import { getAdIdByAdName, getPageAdIdByPageId } from '../../domain/advertising/AdConfigService'
import { AdService } from '../../domain/advertising/AdService'
import { TYPE_VDE } from '../../domain/advertising/vde.constants'
import { SPD_PUSH_EDITO, SPD_PUSH_VDE } from '../../domain/analytics/BiTagger'
import { ADD_GEOENTITY_LIST } from '../geoentity/geoentity.actionTypes'
import { resetVdeGeoentities, searchGeoentities } from '../geoentity/geoentity.actions'
import { selectCurrentHistoryRoute } from '../history/history.selectors'
import { selectMapZoom } from '../map/map.selectors'
import { selectAppOrRubricId } from '../poiassets/poiassets.selectors'
import {
  MARK_LAST_TIME_AD_SEARCH_EXTENSION_DISPLAYED,
  PAGE_AD_CONFIG_LOADED,
  PAGE_AD_CONFIG_LOADING,
  PAGE_AD_CONFIG_NONE,
  PAGE_AD_CONFIG_REQUESTED,
  REMOVE_AD_DATA,
  SET_AD_DATA
} from './ad.actionTypes'
import { MINIMUM_ZOOM_VALUE_TO_SEARCH_VDE_GEOENTITIES } from './ad.constants'
import { selectBboxForVde, selectPageIdForAdConfig, selectVdeName } from './ad.selectors'

export const requestPageAdConfig = pageId => (dispatch, getState) => {
  const currentPageId = selectPageIdForAdConfig(getState())
  if (currentPageId !== pageId)
    dispatch({
      type: PAGE_AD_CONFIG_REQUESTED,
      payload: pageId
    })
}

export const loadPageAdConfig = () => (dispatch, getState) => {
  const pageId = selectPageIdForAdConfig(getState())
  const pageAdId = getPageAdIdByPageId(pageId)

  const srcRoute = selectCurrentHistoryRoute(getState())

  if (pageAdId && pageId === selectCurrentHistoryRoute(getState())) {
    dispatch({
      type: PAGE_AD_CONFIG_LOADING
    })

    return AdService.loadPageAdConfig(pageAdId).then(() => {
      const dstRoute = selectCurrentHistoryRoute(getState())

      if (srcRoute === dstRoute) {
        dispatch({
          type: PAGE_AD_CONFIG_LOADED
        })
      } else {
        dispatch({
          type: PAGE_AD_CONFIG_NONE
        })
      }
    })
  } else {
    dispatch({
      type: PAGE_AD_CONFIG_NONE
    })
  }
}

export const markLastTimeAdSearchExtensionDisplayed = () => dispatch =>
  dispatch({
    type: MARK_LAST_TIME_AD_SEARCH_EXTENSION_DISPLAYED
  })

export const setAdData =
  (adId, data = {}) =>
  dispatch =>
    dispatch({
      type: SET_AD_DATA,
      payload: {
        adId,
        data
      }
    })

export const removeAdData = adId => dispatch =>
  dispatch({
    type: REMOVE_AD_DATA,
    payload: {
      adId
    }
  })

export const searchGeoentitiesForVde = vdeData => (dispatch, getState) => {
  const { type, vdeAppId, subcategoryId, editoRubricIds } = vdeData
  const id = vdeAppId || subcategoryId || editoRubricIds
  const zoom = selectMapZoom(getState())
  const canSearchGeoentities = zoom >= MINIMUM_ZOOM_VALUE_TO_SEARCH_VDE_GEOENTITIES
  if (id && canSearchGeoentities) {
    const store = getState()
    const bbox = selectBboxForVde(store)
    const appOrRubricIds = selectAppOrRubricId(store, { id })
    return searchGeoentities({
      appOrRubricIds,
      max: isGE() ? 20 : 2,
      biTagId: type === TYPE_VDE.EDITO ? SPD_PUSH_EDITO : SPD_PUSH_VDE,
      setGeoentityListActionType: ADD_GEOENTITY_LIST,
      vde: true,
      bbox
    })(dispatch, getState)
  }
}

export const closeVde = () => (dispatch, getState) => {
  const adName = selectVdeName(getState())
  const pageId = selectPageIdForAdConfig(getState())
  const adId = getAdIdByAdName(adName, pageId)
  removeAdData(adId)(dispatch, getState)
  resetVdeGeoentities()(dispatch, getState)
}
