import { selectCurrentMode } from '../../store/itinerary/itinerary.selectors'
import { selectArrivalStep, selectDepartureStep, selectStopSteps } from '../itinerary/steps/steps.selectors'
import { selectHasRoadbook } from '../../store/itinerary/roadbook.selectors'
import {
  isLayerResponseRoute,
  isZoneRoute,
  selectCurrentHistoryRoute,
  selectIsToolsComputeRadiusPage,
  selectIsItineraryUniverse,
  selectIsPlacesUniverse,
  selectIsSearchPage,
  selectIsSeoCityActivityPage,
  selectIsSeoCityAppPage,
  selectIsAGeoentityPage,
  selectIsAGeoentityOnSearchPage
} from '../../store/history/history.selectors'
import { selectFirstAddress } from '../../store/address/address.selectors'
import {
  selectCurrentGeoentity,
  selectFirstGeoentitySatisfying,
  selectGeoentityRubricId,
  selectSelectedGeoentity
} from '../../store/geoentity/geoentity.selectors'
import { getStcLocaliteByGeocodeLevel, getTagLocaleFromRoute } from './analyticsUtils'
import { selectSearchParameters } from '../../store/search/search.selectors'
import {
  CONFIG_TYPES,
  findCategoryForItem,
  selectGeoentityTypeDataForSearch
} from '../../store/poiassets/poiassets.selectors'
import { SUGGEST_FROM_VALUES } from '../suggest/suggest.constants'
import { PARAM_REDIRECT, REDIRECT_QRCODE } from '../router/queryParameters.constants'
import { selectComputeRadius } from '../../store/tools/tools.selectors'
import { ROUTE_SEO_CITY_APP } from '../../routes'
import { selectCurrentItineraryRouteDistance } from '../../store/itinerary/routes.selectors'
import { isEmpty } from '../../utils/lang'
import { JO_CELEBRATION_PLACE, JO_EVENT_SITE } from '../../store/geoentity/geoentity.constants'

const getRedirection = query => {
  const qrcode = query?.get(PARAM_REDIRECT) === REDIRECT_QRCODE
  if (qrcode)
    return {
      Redirection: 'Vers_mobile_par_QRcode'
    }
  return {}
}
const stcResolver = (store, pageTag, query) => {
  if (selectIsItineraryUniverse(store)) {
    const [CodeAller, CodeRetour] = [selectDepartureStep(store), selectArrivalStep(store)].map(
      ({ towncode, countryCode }) => `${towncode}/${countryCode}`
    )
    return { ...getRedirection(query), CodeAller, CodeRetour }
  }

  const route = selectCurrentHistoryRoute(store)
  const firstAddress = selectFirstAddress(store)
  const { departmentCode, regionCode, countryCode, geocode_level } = firstAddress

  if (isLayerResponseRoute(route)) {
    return {
      type: 'Page_Reponse_Juin_2023',
      localite: getStcLocaliteByGeocodeLevel(geocode_level),
      departement: departmentCode || '',
      region: regionCode || '',
      pays: countryCode
    }
  }

  if (isZoneRoute(route)) {
    return {
      type: 'Page_plan_Janvier_2023',
      localite: getTagLocaleFromRoute(route),
      departement: departmentCode || '',
      region: regionCode || '',
      pays: countryCode
    }
  }

  if (route === ROUTE_SEO_CITY_APP) {
    return {
      type: 'Page_LandingActiviteAppVille_Juillet_2023',
      localite: 'Ville',
      departement: departmentCode || '',
      region: regionCode || '',
      pays: countryCode
    }
  }

  const firstPoi = selectFirstGeoentitySatisfying(store, { keys: ['towncode', 'countryCode'] })
  const location = !isEmpty(firstAddress) ? firstAddress : firstPoi

  if (selectIsAGeoentityPage(store) || selectIsAGeoentityOnSearchPage(store)) {
    const currentGeoentity = selectCurrentGeoentity(store)

    if (currentGeoentity?.rubric.id === JO_EVENT_SITE || currentGeoentity?.rubric.id === JO_CELEBRATION_PLACE)
      return (
        location && {
          ...getRedirection(query),
          CodeAller: `${location.towncode || ''}/${location.countryCode || ''}`,
          evenement: 'best_olympic'
        }
      )
  }

  return location && { ...getRedirection(query), CodeAller: `${location.towncode || ''}/${location.countryCode || ''}` }
}

const f3Resolver = (store, pageTag) => {
  switch (pageTag.name) {
    case 'ReponseItineraire':
    case 'ReponseItineraireChoix':
      return selectStopSteps(store)?.length > 0 ? 1 : 0
  }
  return ''
}

const x3Resolver = store => {
  if (selectIsSearchPage(store)) {
    const { category, subcategory } = selectSearchParameters(store)
    if (subcategory) {
      const cat = findCategoryForItem(store, { id: subcategory, type: CONFIG_TYPES.subcategory })
      return cat?.id ?? ''
    }
    return category || ''
  }
  return ''
}

const x4Resolver = store => {
  if (selectIsItineraryUniverse(store))
    return selectGeoentityRubricId({}, { geoentity: selectArrivalStep(store) }) ?? ''
  if (selectIsSearchPage(store)) {
    const { rubric } = selectSearchParameters(store)
    return rubric || ''
  }
  if (selectIsSeoCityActivityPage(store)) return selectGeoentityTypeDataForSearch(store)?.appOrRubricIds ?? ''
  return selectGeoentityRubricId(store) || ''
}

const x5Resolver = store => {
  if (!selectIsPlacesUniverse(store)) return ''
  if (selectIsSearchPage(store)) {
    const { app } = selectSearchParameters(store)
    return app || ''
  }
  if (selectIsSeoCityAppPage(store)) return selectGeoentityTypeDataForSearch(store)?.appOrRubricIds ?? ''
  const geoentity = selectSelectedGeoentity(store)
  if (geoentity) return geoentity?.currentTabId ?? 'NC'
  return 'all'
}

// return length value in Km with 1 decimal
const x6Resolver = store => {
  if (selectIsToolsComputeRadiusPage(store)) {
    return (parseFloat(selectComputeRadius(store)) / 1000).toFixed(0)
  }

  const oLength = selectCurrentItineraryRouteDistance(store)

  if (!oLength) return
  if (oLength.unit === 'km') return parseFloat(oLength.value)
  if (oLength.unit === 'm') return parseFloat((oLength.value / 1000).toFixed(1))
}

const x7Resolver = store => {
  const currentProvider = store.itinerary?.currentProvider
  const currentMode = selectCurrentMode(store)
  return `${currentMode}${currentProvider ? `_${currentProvider}` : ''}`
}

const x11Resolver = store => {
  if (selectIsItineraryUniverse(store)) {
    return selectDepartureStep(store)?.postcode
  }
  const firstAddress = selectFirstAddress(store)
  const firstPoi = selectFirstGeoentitySatisfying(store, { keys: ['postcode'] })
  const location = !isEmpty(firstAddress) ? firstAddress : firstPoi
  return location?.postcode
}

const x13Resolver = store => selectArrivalStep(store)?.postcode

const nameResolver = (store, pageTag) => {
  let pageName = pageTag.name

  if (pageName.includes('#Region')) {
    const { regionCode } = selectFirstAddress(store)
    pageName = pageName.replace('#Region', regionCode)
  }
  if (pageName.includes('#Department')) {
    const { departmentCode } = selectFirstAddress(store)
    pageName = pageName.replace('#Department', departmentCode)
  }

  return pageName
}

export default {
  stc: stcResolver,
  x2: () => 'Nessie',
  x3: x3Resolver,
  x4: x4Resolver,
  x5: x5Resolver,
  x6: x6Resolver, // valeur numérique : longueur de l'itinéraire, taille du rayon
  x7: x7Resolver,
  x9: store => `${selectHasRoadbook(store) ? 'avec' : 'sans'}_feuillederoute`,
  x10: store => selectFirstGeoentitySatisfying(store, { keys: ['regionCode'] })?.regionCode ?? '',
  x11: x11Resolver, // code postal du départ, de l'adresse ou du poi
  x13: x13Resolver, // code postal de l'arrivée
  x15: store => (selectDepartureStep(store)?.from === SUGGEST_FROM_VALUES.history ? 'POI|historique' : 'adresse|na'),
  x17: store => (selectArrivalStep(store)?.from === SUGGEST_FROM_VALUES.history ? 'POI|historique' : 'adresse|na'),
  f3: f3Resolver,
  name: nameResolver
}
