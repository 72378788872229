import {
  LOAD_MULTI_ADDRESS_SUCCESS,
  LOAD_SINGLE_ADDRESS_SUCCESS,
  LOAD_SINGLE_ADDRESS_COMPUTE_RADIUS_SUCCESS,
  LOAD_ADDRESS_ERROR,
  RESET_ADDRESS,
  LOAD_DESCRIPTION,
  RESET_DESCRIPTION,
  REMOVE_ADDRESS_ERROR,
  ADD_CONTOUR_TO_FIRST_ADDRESS,
  LOAD_AREA_FROM_GEOCODE
} from './address.actionTypes'
import { parseErrorFromReduxAction } from '../../domain/error/errorParser'

const getDefaultState = () => {
  return {
    viewport: undefined,
    addresses: [],
    description: undefined,
    contour: null
  }
}

export default function addressReducer(state = getDefaultState(), action) {
  switch (action && action.type) {
    case LOAD_AREA_FROM_GEOCODE:
    case LOAD_MULTI_ADDRESS_SUCCESS:
    case LOAD_SINGLE_ADDRESS_SUCCESS:
    case LOAD_SINGLE_ADDRESS_COMPUTE_RADIUS_SUCCESS: {
      const {
        data: { addresses, viewport }
      } = action.payload

      return {
        ...state,
        addresses,
        viewport
      }
    }
    case ADD_CONTOUR_TO_FIRST_ADDRESS: {
      return {
        ...state,
        contour: action.payload
      }
    }
    case LOAD_ADDRESS_ERROR:
      return {
        error: parseErrorFromReduxAction(action)
      }
    case REMOVE_ADDRESS_ERROR:
      return {
        ...state,
        error: undefined
      }
    case LOAD_DESCRIPTION:
      const { description } = action.payload
      return {
        ...state,
        description
      }
    case RESET_DESCRIPTION:
      return {
        ...state,
        description: undefined
      }
    case RESET_ADDRESS:
      return getDefaultState()
    default:
      return state
  }
}
