import { SET_QUIZ_CITY_ANSWER, SET_QUIZ_GUESS_COORDINATES } from './quiz.actionTypes'
import { setCurrentCityAnswer, waitForNextQuestion } from './quiz.actions'
import { selectQuizConfig, selectQuizCurrentCity, selectQuizType } from './quiz.selectors'
import { getBboxFromLngLatPoints, getDistanceByFlyCrow, isPointInBbox } from '../../domain/utils/map'
import { requestMove } from '../map/map.actions'
import { selectMapBbox } from '../map/map.selectors'
import { QUIZ_CITIES_BONUS_POINTS, QUIZ_TYPES } from '../../domain/quiz/quiz.constants'

export const calcCitiesQuizPoints = (city, distance) => {
  const maxDistance = 300 + Math.floor(city.data.rank / 10) * 10
  const points = Math.max(0, maxDistance - distance)
  const bonus = distance <= 10 ? QUIZ_CITIES_BONUS_POINTS : 0
  return { bonus, points }
}

export const calcZoneCitiesQuizPoints = (distance, config) => {
  const distanceForPoints = Math.max(0, config.answerMaxDistance - distance)
  const points = Math.round((distanceForPoints / config.answerMaxDistance) * config.answerMaxPoints)
  const bonus = distance <= config.bonusDistance ? config.bonusPoints : 0
  return { bonus, points }
}

export const onSetClickCoordinates =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    if (action.type === SET_QUIZ_GUESS_COORDINATES) {
      const type = selectQuizType(getState())
      const config = selectQuizConfig(getState())
      const city = selectQuizCurrentCity(getState())
      if (city?.data) {
        const cityCoordinates = city.data.coordinates
        const { guessCoordinates } = action.payload.answer
        const distance = Math.round(
          getDistanceByFlyCrow(guessCoordinates.lng, guessCoordinates.lat, cityCoordinates.lng, cityCoordinates.lat)
        )

        const { bonus, points } = [QUIZ_TYPES.REGION_CITIES, QUIZ_TYPES.DEPARTMENT_CITIES].includes(type)
          ? calcZoneCitiesQuizPoints(distance, config)
          : calcCitiesQuizPoints(city, distance)

        setCurrentCityAnswer({
          distance,
          points,
          bonus
        })(dispatch, getState)

        const mapBbox = selectMapBbox(getState())
        if (!isPointInBbox(cityCoordinates, mapBbox)) {
          const bbox = getBboxFromLngLatPoints([guessCoordinates, cityCoordinates])
          requestMove({ bbox })(dispatch, getState)
        }
      }
    }

    return r
  }

export const nextCity =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)
    if (action.type === SET_QUIZ_CITY_ANSWER) {
      waitForNextQuestion()(dispatch, getState)
    }
    return r
  }

export default [onSetClickCoordinates, nextCity]
