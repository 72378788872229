import {
  HISTORY_PUSH,
  REVERSE_RESOLVE_ROUTE_AND_REGISTER,
  HISTORY_POP,
  HISTORY_SAVE_POST_POP,
  HISTORY_GO_TO_EXTERNAL,
  HISTORY_OPEN_POPUP
} from './history.actionTypes'
import { formatFullRouteFromHistory, getMatchingRoute } from '../../domain/router/routeUtils'
import { deferredWindowLocation, deferredWindowOpen } from '../../domain/utils/deferredWindowActions'
import { selectCurrentHistoryRoute, selectPreviousHistoryRoute, selectIsSearchPage } from './history.selectors'
import { shouldReplace, shouldNativePop, getForcedPopRoute } from './historyWaybackEngine'
import { navigateToExternal, navigateTo } from './history.actions'
import { MAP_UPDATE } from '../map/map.actionTypes'
import { selectSearchParameters } from '../search/search.selectors'
import { selectIsMapReady } from '../map/map.selectors'

import { MAP_FROM_APP_ACTION_PADDING } from '../../domain/map/map.utils'

/*
 * History v5.0.0 introduced a behavior change [1] which keep the hash/search even if new location don’t have one.
 * So we need to explictly path an object with an empty hash if we’re going to an URL without a hash to avoid keeping it.
 * [1] https://github.com/ReactTraining/history/issues/814
 */
export const prepareUrlForHistory = url =>
  !url.includes('#')
    ? {
        pathname: url,
        hash: ''
      }
    : url

export const navigationMiddleware = (store, history) => next => action => {
  switch (action.type) {
    case HISTORY_PUSH: {
      const { route, routeOptions, fullRoute } = action.payload
      const storeState = (store && store.getState()) || {}
      const currentRoute = selectCurrentHistoryRoute(storeState)

      if (shouldReplace(currentRoute, route, routeOptions)) {
        history.replace(prepareUrlForHistory(fullRoute), routeOptions)
      } else {
        history.push(prepareUrlForHistory(fullRoute), routeOptions)
      }

      return next(action)
    }
    case HISTORY_POP: {
      const storeState = store.getState()
      const currentRoute = selectCurrentHistoryRoute(storeState)
      const previousRoute = selectPreviousHistoryRoute(storeState)

      if (shouldNativePop(currentRoute, previousRoute, storeState)) {
        history.back() // this operation is asynchronous, this is why we have to dispatch HISTORY_SAVE_POST_POP on event 'POP' to register actual url
      } else {
        const { route, external, routeOptions } = getForcedPopRoute(currentRoute, storeState)

        if (external) {
          navigateToExternal({ url: route })(store.dispatch)
        } else {
          navigateTo({
            route,
            routeOptions: {
              ...routeOptions,
              forceReplaceForPop: !routeOptions?.forceNotReplace
            }
          })(store.dispatch, store.getState)
        }
      }

      break
    }
    case HISTORY_SAVE_POST_POP: {
      const currentFullRoute = formatFullRouteFromHistory(history)
      const currentRoute = getMatchingRoute(currentFullRoute)

      return next({
        ...action,
        payload: {
          ...action.payload,
          route: currentRoute,
          fullRoute: currentFullRoute
        }
      })
    }
    case REVERSE_RESOLVE_ROUTE_AND_REGISTER: {
      const { fullRoute } = action.payload

      const route = getMatchingRoute(fullRoute)

      if (!route) return

      if (__SERVER__) history.push(prepareUrlForHistory(fullRoute))

      return next({
        ...action,
        type: HISTORY_PUSH,
        origType: REVERSE_RESOLVE_ROUTE_AND_REGISTER,
        payload: {
          ...action.payload,
          route
        }
      })
    }
    case HISTORY_GO_TO_EXTERNAL: {
      const { url } = action.payload
      deferredWindowLocation(url)

      return next(action)
    }
    case HISTORY_OPEN_POPUP: {
      const { url, popupName, width, height } = action.payload
      deferredWindowOpen({ url, popupName, width, height })

      return next(action)
    }
  }

  return next(action)
}

export const updateSearchRouteWithBboxOnMapUpdate =
  ({ getState, dispatch }) =>
  next =>
  action => {
    const r = next(action)
    if (action.type === MAP_UPDATE) {
      const storeState = getState()
      const fromAppAction = action?.payload?.fromAppAction
      if (
        selectIsMapReady(storeState) &&
        selectIsSearchPage(storeState) &&
        fromAppAction !== MAP_FROM_APP_ACTION_PADDING
      ) {
        const route = selectCurrentHistoryRoute(storeState)
        const routeOptions = {
          ...selectSearchParameters(storeState),
          avoidDispatchingAnyPageAction: true,
          avoidPageTag: true
        }
        navigateTo({
          route,
          routeOptions
        })(dispatch, getState)
      }
    }
    return r
  }

export default [navigationMiddleware, updateSearchRouteWithBboxOnMapUpdate]
