import { RESET_COMPUTE_RADIUS, SET_COMPUTE_RADIUS_POSITION, SET_COMPUTE_RADIUS, SET_DEVICE } from './tools.actionTypes'
import { DEFAULT_COMPUTE_CIRCLE_RADIUS } from '../../domain/tools/tools.constants'
import { isPE } from '../../domain/Device'

const getInitialState = () => {
  return {
    deviceIsPE: isPE(),
    position: null,
    radius: DEFAULT_COMPUTE_CIRCLE_RADIUS * 1000
  }
}

const toolsReducer = (state = getInitialState(), action) => {
  switch (action?.type) {
    case SET_DEVICE: {
      const deviceIsPE = action.payload
      return { ...state, deviceIsPE }
    }

    case SET_COMPUTE_RADIUS_POSITION: {
      const { position, fromMarker = false } = action.payload
      return {
        ...state,
        position,
        fromMarker
      }
    }

    case SET_COMPUTE_RADIUS: {
      const { radius } = action.payload
      return {
        ...state,
        radius
      }
    }
    case RESET_COMPUTE_RADIUS:
      return getInitialState()
    default:
      return state
  }
}

export default toolsReducer
