export const SET_DEFAULT_BBOX = 'SET_DEFAULT_BBOX'
export const MAP_UPDATE = 'MAP_UPDATE'
export const MAP_REQUEST_MOVE = 'MAP_REQUEST_MOVE'
export const MAP_ACK_MOVE = 'MAP_ACK_MOVE'
export const MAP_REQUEST_ZOOM = 'MAP_REQUEST_ZOOM'
export const MAP_ACK_ZOOM = 'MAP_ACK_ZOOM'
export const MAP_SET_THEME = 'MAP_SET_THEME'
export const MAP_SET_MODE = 'MAP_SET_MODE'
export const ACTIVATE_GEOIP = 'ACTIVATE_GEOIP'
export const MAP_SET_STATUS = 'MAP_SET_STATUS'
export const MAP_RESET_STYLE = 'MAP_RESET_STYLE'
export const MAP_DESTROYED = 'MAP_DESTROYED'
export const SET_GEOLOC_POSITION = 'SET_GEOLOC_POSITION'
export const SET_GEOLOC_STATUS = 'SET_GEOLOC_STATUS'
export const REQUEST_GEOLOC_STATUS = 'REQUEST_GEOLOC_STATUS'
export const CLEAN_GEOLOC_AFTER_FETCH = 'CLEAN_GEOLOC_AFTER_FETCH'
export const TOGGLE_FULLSCREEN = 'TOGGLE_FULLSCREEN'
export const TURN_OFF_FULLSCREEN = 'TURN_OFF_FULLSCREEN'
export const DISPLAY_POPUP = 'DISPLAY_POPUP'
export const HIDE_POPUP = 'HIDE_POPUP'
export const SEND_MAP_LOADING_TIME = 'SEND_MAP_LOADING_TIME'
export const SWITCH_MAP_TYPE = 'SWITCH_MAP_TYPE'
export const SET_MAP_EVENTS = 'SET_MAP_EVENTS'
export const SET_MAP_EVENT_POPIN = 'SET_MAP_EVENT_POPIN'
export const RESET_MAP_EVENT_POPIN = 'RESET_MAP_EVENT_POPIN'
export const SET_MAP_EVENT_DATE = 'SET_MAP_EVENT_DATE'
export const SET_MAP_EVENT_TIME = 'SET_MAP_EVENT_TIME'
