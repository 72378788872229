import ScriptLoader from '../utils/ScriptLoader'
import { ORANGE_AD_LIB_CONFIG_SCRIPT } from './AdConfigService'
import { isProduction } from '../../parameters'
import debug from 'debug'
import { isEmpty } from '../../utils/lang'
import { once } from '../../utils/function'

const d = debug('ad:AdService')

const convertAllValuesToString = (o = {}) => {
  const ret = {}
  Object.keys(o).forEach(k => {
    ret[k] = String(o[k])
  })
  return ret
}

const stringifyKeywordsAsStrings = keywords => {
  const keywordsAsStrings = convertAllValuesToString(keywords)
  return JSON.stringify(keywordsAsStrings)
}

export class AdService {
  static loadLibrary = once(() => ScriptLoader.load('Orange', ORANGE_AD_LIB_CONFIG_SCRIPT))

  static loadPageAdConfig = pageId =>
    AdService.loadLibrary().then(() => {
      const id = `${pageId}${isProduction() ? '' : '.test'}`
      d('loadPageAdConfig - ADGW.loadConfig', id)
      return global.ADGW.loadConfig(id)
    })

  static createAd = adId =>
    Promise.resolve().then(() => {
      d('createAd - ADGW.createSlot', adId)
      return global.ADGW.createSlot(adId)
    })

  static loadAd = adId =>
    Promise.resolve().then(() => {
      d('loadAd - ADGW.displaySlot', adId)
      return global.ADGW.displaySlot(adId)
    })

  static reloadAd = adId =>
    Promise.resolve().then(() => {
      d('reloadAd - ADGW.displaySlot', adId)
      return global.ADGW.displaySlot(adId)
    })

  static deleteAd = adId =>
    Promise.resolve().then(() => {
      d('deleteAd - ADGW.deleteSlot', adId)
      return global.ADGW.deleteSlot(adId)
    })

  static setAdKeywords = (adId, keywords) => {
    if (isEmpty(keywords))
      return Promise.resolve().then(() => {
        d('setAdKeywords - ADGW.setSlotTargetings', adId, "''")
        return global.ADGW.setSlotTargetings(adId, '')
      })

    return Promise.resolve().then(() => {
      const keywordsAsString = stringifyKeywordsAsStrings(keywords)
      d('setAdKeywords - ADGW.setSlotTargetings', adId, keywordsAsString)
      return global.ADGW.setSlotTargetings(adId, keywordsAsString)
    })
  }

  static getAdData = adId =>
    Promise.resolve().then(() => {
      d('getAdData - ADGW.getSlotData', adId)
      return global.ADGW.getSlotData(adId).then(data => {
        d('getAdData - ADGW.getSlotData response', adId, data)
        return data
      })
    })
}
