import React from 'react'
import debug from 'debug'
const d = debug('Router')

export default class Router extends React.Component {
  constructor(props) {
    super(props)

    const { currentPageComponent } = props
    this.state = { currentPageComponent }
  }

  componentDidMount() {
    d('componentDidMount')
    const { routerService } = this.props

    routerService.onPageChange((err, currentPageComponent) => {
      if (err) return console.error(err)

      this.setState({ currentPageComponent })
    })
  }

  render() {
    d('render')
    const { currentPageComponent } = this.state

    return <>{currentPageComponent}</>
  }
}
