import {
  LOAD_SUGGEST_ERROR,
  LOAD_SUGGEST_RESPONSE,
  RESET_SUGGEST_DATA,
  SET_SUGGEST_INPUT_VALUE
} from './suggest.actionTypes'
import { parseErrorFromReduxAction } from '../../domain/error/errorParser'

export const getInitialState = () => ({
  inputValue: '',
  serviceSuggestions: [],
  error: null,
  lastRequestTime: 0
})

export default function suggestReducer(state = getInitialState(), action) {
  switch (action.type) {
    case SET_SUGGEST_INPUT_VALUE:
      return {
        ...state,
        inputValue: action.payload
      }
    case LOAD_SUGGEST_RESPONSE: {
      const { data, requestTime } = action.payload
      return {
        ...state,
        serviceSuggestions: data.suggests,
        lastRequestTime: requestTime
      }
    }
    case LOAD_SUGGEST_ERROR: {
      return {
        ...state,
        error: parseErrorFromReduxAction(action),
        serviceSuggestions: [],
        lastRequestTime: action.payload.requestTime
      }
    }
    case RESET_SUGGEST_DATA:
      return getInitialState()
    default:
      return state
  }
}
