import { compact, flatten } from '../../utils/array'

import { findAddressforGeolocation } from '../../dataSource/address/address.request'
import { areStepsFilled } from '../../domain/itinerary/steps/steps.selectors'
import {
  ROUTE_ITINERARY_COMPUTING,
  ROUTE_ITINERARY_HOME,
  ROUTE_ITINERARY_HP_BY_MODE,
  ROUTE_ITINERARY_RESULTS_BY_PROVIDER,
  ROUTE_ITINERARY_RESULTS_BY_ROUTE,
  ROUTE_SUGGEST
} from '../../routes'
import {
  BROWSER_STORAGE_ALLOW_DEFAULT_USER_POSITION,
  BROWSER_STORAGE_ALLOW_DEFAULT_USER_POSITION_FALSE,
  BROWSER_STORAGE_ALLOW_DEFAULT_USER_POSITION_TRUE,
  getFromLocalStorage,
  saveToLocalStorage
} from '../../utils/browserStorageService'
import { resetAddress } from '../address/address.actions'
import { HISTORY_NAVIGATION_DONE } from '../history/history.actionTypes'
import { navigateBack, navigateTo } from '../history/history.actions'
import {
  selectCurrentHistoryRoute,
  selectIsItineraryUniverse,
  selectPreviousHistoryRoute
} from '../history/history.selectors'
import { selectLocale } from '../locale/locale.selectors'
import { TOGGLE_FULLSCREEN } from '../map/map.actionTypes'
import { getCurrentLocation } from '../map/map.actions'
import {
  INVERT_STEPS,
  MULTIPATH_COMPLETE,
  MULTIPATH_COMPLETE_PROVIDER,
  MULTIPATH_COMPUTE,
  MULTIPATH_RECOMPUTE,
  REMOVE_STEP,
  SET_ACTIVE_SORT,
  SET_CURRENT_PROVIDER,
  SET_CURRENT_ROUTE,
  SET_CURRENT_STEPS,
  SET_STEP_LOCATION,
  SET_STEP_RESOLVED_LOCATION
} from './itinerary.actionTypes'
import {
  computeProvider,
  mapFitCurrentPolyline,
  mapFitCurrentSteps,
  navigateToFirstSortedItineraryRoute,
  resetRoutes,
  setCurrentProvider,
  setRouteIdForCurrentProviderOrMode,
  setStepFromAddress,
  setStepIdx
} from './itinerary.actions'
import {
  selectFirstProviderWithRoutes,
  selectProviders,
  selectShouldLoadPositionOnFirstItineraryStep
} from './itinerary.selectors'
import { selectDoesCurrentModeHaveRoutes, selectRoutesForMonomode } from './routes.selectors'

export const computeItineraryIfEnoughSteps =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    switch (action.type) {
      case SET_STEP_LOCATION:
      case INVERT_STEPS:
      case REMOVE_STEP:
      case SET_CURRENT_STEPS:
        if (areStepsFilled(getState())) {
          navigateTo({ route: ROUTE_ITINERARY_COMPUTING })(dispatch, getState)
        }
        break
    }
    return r
  }

const computeOthersSimplifiedOrOptionalProvider = (providers, provider) => (dispatch, getState) => {
  const nextMode = provider.mode
  const simplifiedProviderForThisMode = (providers || []).filter(p => p.mode === nextMode)
  return simplifiedProviderForThisMode.map(p => {
    // we dont fetch the selected provider to avoid recomputing in the next condition
    if ((p.simplified || p.optional) && p.name !== provider.name) {
      return computeProvider(p.name)(dispatch, getState)
    }
    return null
  })
}

export const computeProviderIfRouteSimplifiedOrOptional =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)
    switch (action.type) {
      case SET_CURRENT_PROVIDER:
        const nextProvider = action.payload.provider
        const {
          itinerary: { providers }
        } = getState()
        const provider = (providers || []).find(p => p.name === nextProvider)

        const computeItineraries = []
        if (provider) {
          computeItineraries.push(computeOthersSimplifiedOrOptionalProvider(providers, provider)(dispatch, getState))
        }

        if (provider && (provider.simplified === true || provider.optional === true)) {
          computeItineraries.push(computeProvider(nextProvider)(dispatch, getState))
          Promise.all(compact(computeItineraries))
            .then(flatten)
            .then(compact)
            .then(() => {
              dispatch({
                type: MULTIPATH_COMPLETE_PROVIDER
              })
              setRouteIdForCurrentProviderOrMode()(dispatch, getState)
            })
        }
        break
    }
    return r
  }

export const computeAllProviderWhenSortIsActive =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    switch (action.type) {
      case SET_ACTIVE_SORT:
        const simplifiedProviders = selectProviders(getState()).filter(({ simplified }) => simplified)
        if (simplifiedProviders.length === 0) return r

        Promise.all(simplifiedProviders.map(provider => computeProvider(provider.name)(dispatch, getState))).then(
          () => {
            dispatch({
              type: MULTIPATH_COMPLETE_PROVIDER
            })
            navigateToFirstSortedItineraryRoute()(dispatch, getState)
          }
        )
        break
    }
    return r
  }

export const recomputeShouldRedirectToRouteByProvider =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)
    switch (action.type) {
      case MULTIPATH_RECOMPUTE:
        navigateTo({ route: ROUTE_ITINERARY_RESULTS_BY_PROVIDER })(dispatch, getState)
        break
    }

    return r
  }

export const reselectCurrentProviderIfHasNoRoute =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    switch (action.type) {
      case MULTIPATH_COMPLETE:
        const { itinerary } = getState()

        if (!selectDoesCurrentModeHaveRoutes(getState())) {
          const newCurrentProvider = selectFirstProviderWithRoutes(itinerary)?.name ?? 'car'
          setCurrentProvider(newCurrentProvider)(dispatch, getState)

          navigateTo({
            route: ROUTE_ITINERARY_RESULTS_BY_ROUTE,
            routeOptions: {
              avoidRefetchingPageData: true
            },
            selectedStoreState: {
              provider: newCurrentProvider.name,
              mode: newCurrentProvider.mode,
              routeId: selectRoutesForMonomode(getState())?.[0]?.routeId
            }
          })(dispatch, getState)
        }

        break
    }

    return r
  }

export const resetRoutesWhenStepChange =
  ({ dispatch }) =>
  next =>
  action => {
    switch (action.type) {
      case SET_STEP_LOCATION:
      case INVERT_STEPS:
        resetRoutes()(dispatch)
        break
    }

    return next(action)
  }

export const handleBackFromSuggestToItineraryHome =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    if (
      action.type === SET_STEP_LOCATION &&
      selectCurrentHistoryRoute(getState()) === ROUTE_SUGGEST &&
      (selectPreviousHistoryRoute(getState()) === ROUTE_ITINERARY_HOME ||
        selectPreviousHistoryRoute(getState()) === ROUTE_ITINERARY_HP_BY_MODE) &&
      !areStepsFilled(getState())
    ) {
      navigateBack()(dispatch, getState)
    }

    return r
  }

export const moveMapOnItineraryActions =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    switch (action.type) {
      case TOGGLE_FULLSCREEN:
        if (selectIsItineraryUniverse(getState())) {
          mapFitCurrentPolyline()(dispatch, getState)
        }
        break
      case SET_STEP_RESOLVED_LOCATION: // move map when page load, even before we’ve got polyline
      case MULTIPATH_COMPUTE: // when changing steps after an itinerary, SET_STEP_RESOLVED_LOCATION is not called
        mapFitCurrentSteps()(dispatch, getState)
        break
      case MULTIPATH_COMPLETE:
      case SET_CURRENT_ROUTE:
      case SET_CURRENT_PROVIDER: // move map when route or provider change (polyline may go outside current bbox)
        mapFitCurrentPolyline()(dispatch, getState)
    }
    return r
  }

export const handleSortToNavigateToFirstRouteMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    if (action.type === SET_ACTIVE_SORT) {
      navigateToFirstSortedItineraryRoute()(dispatch, getState)
    }
    return r
  }

export const setLocationOnFirstStep =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    if (action.type === HISTORY_NAVIGATION_DONE && selectShouldLoadPositionOnFirstItineraryStep(getState())) {
      const allowDefaultUserPosition = getFromLocalStorage(BROWSER_STORAGE_ALLOW_DEFAULT_USER_POSITION)
      if (allowDefaultUserPosition === BROWSER_STORAGE_ALLOW_DEFAULT_USER_POSITION_TRUE) {
        const locale = selectLocale(getState())
        getCurrentLocation()(dispatch, getState)
          .then(geolocation => findAddressforGeolocation({ ...geolocation, locale }))
          .then(response => {
            const address = response?.addresses?.[0]

            if (address) {
              setStepIdx({ idx: 0 })(dispatch, getState)
              setStepFromAddress(address)(dispatch, getState)
            }
          })
          .catch(error => {
            saveToLocalStorage(
              BROWSER_STORAGE_ALLOW_DEFAULT_USER_POSITION,
              BROWSER_STORAGE_ALLOW_DEFAULT_USER_POSITION_FALSE
            )
            console.error('Resolution of current location went wrong', error)
          })
      }
    }
    return r
  }

export const cleanAddressOnSetStepLocation =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    if (action.type === SET_STEP_LOCATION) {
      resetAddress()(dispatch, getState)
    }
    return r
  }

export default [
  handleBackFromSuggestToItineraryHome,
  computeItineraryIfEnoughSteps,
  computeProviderIfRouteSimplifiedOrOptional,
  computeAllProviderWhenSortIsActive,
  recomputeShouldRedirectToRouteByProvider,
  reselectCurrentProviderIfHasNoRoute,
  resetRoutesWhenStepChange,
  moveMapOnItineraryActions,
  handleSortToNavigateToFirstRouteMiddleware,
  setLocationOnFirstStep,
  cleanAddressOnSetStepLocation
]
