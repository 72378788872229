import {
  ACTIVATE_GEOIP,
  CLEAN_GEOLOC_AFTER_FETCH,
  DISPLAY_POPUP,
  HIDE_POPUP,
  MAP_ACK_MOVE,
  MAP_ACK_ZOOM,
  MAP_DESTROYED,
  MAP_REQUEST_MOVE,
  MAP_REQUEST_ZOOM,
  MAP_RESET_STYLE,
  MAP_SET_MODE,
  MAP_SET_STATUS,
  MAP_SET_THEME,
  MAP_UPDATE,
  REQUEST_GEOLOC_STATUS,
  RESET_MAP_EVENT_POPIN,
  SET_DEFAULT_BBOX,
  SET_GEOLOC_POSITION,
  SET_GEOLOC_STATUS,
  SET_MAP_EVENTS,
  SET_MAP_EVENT_DATE,
  SET_MAP_EVENT_POPIN,
  SET_MAP_EVENT_TIME,
  SWITCH_MAP_TYPE,
  TOGGLE_FULLSCREEN,
  TURN_OFF_FULLSCREEN
} from './map.actionTypes'
import { GEOLOC_STATUS_DISABLED, MAP_MODES, MAP_STATUS_INIT, MAP_STATUS_NONE, MAP_THEMES } from './map.constants'

export function getInitialState() {
  return {
    vecto: true,
    geoip: false,
    bbox: null,
    activeBbox: null,
    searchBbox: null,
    targetBbox: undefined,
    targetCenter: undefined,
    targetOptions: undefined,
    targetZoom: undefined,
    theme: MAP_THEMES.normal,
    mode: MAP_MODES.neutral,
    fullscreen: false,
    status: MAP_STATUS_NONE,
    popupType: null,
    geolocation: {
      status: GEOLOC_STATUS_DISABLED,
      position: null,
      requestStatus: null,
      fetchOptions: null
    },
    mapEvents: undefined
  }
}

export default function mapReducer(state = getInitialState(), action) {
  switch (action && action.type) {
    case MAP_UPDATE: {
      const { zoom, bbox, activeBbox, searchBbox } = action.payload
      return {
        ...state,
        zoom,
        bbox,
        activeBbox,
        searchBbox
      }
    }
    case SET_DEFAULT_BBOX: {
      const { bbox } = action.payload
      return {
        ...state,
        bbox
      }
    }
    case MAP_REQUEST_MOVE: {
      const { bbox, center, options } = action.payload
      return {
        ...state,
        targetBbox: bbox,
        targetCenter: center,
        targetOptions: options
      }
    }
    case MAP_ACK_MOVE: {
      return {
        ...state,
        targetBbox: undefined,
        targetCenter: undefined,
        targetOptions: undefined
      }
    }
    case MAP_REQUEST_ZOOM: {
      const { zoom } = action.payload
      return {
        ...state,
        targetZoom: zoom
      }
    }
    case MAP_ACK_ZOOM: {
      return {
        ...state,
        targetZoom: undefined
      }
    }
    case MAP_SET_THEME: {
      return {
        ...state,
        theme: action.payload
      }
    }
    case MAP_SET_MODE: {
      return {
        ...state,
        mode: action.payload
      }
    }
    case ACTIVATE_GEOIP:
      return {
        ...state,
        geoip: true
      }
    case MAP_SET_STATUS:
      return {
        ...state,
        status: action.payload
      }
    case SET_GEOLOC_STATUS:
      return {
        ...state,
        geolocation: {
          ...state.geolocation,
          status: action.payload.status,
          fetchOptions: action.payload.options
        }
      }
    case SET_GEOLOC_POSITION:
      return {
        ...state,
        geolocation: {
          ...state.geolocation,
          position: action.payload
        }
      }
    case REQUEST_GEOLOC_STATUS:
      return {
        ...state,
        geolocation: {
          ...state.geolocation,
          requestStatus: action.payload.status,
          fetchOptions: action.payload.options
        }
      }
    case CLEAN_GEOLOC_AFTER_FETCH:
      return {
        ...state,
        geolocation: {
          ...state.geolocation,
          requestStatus: null,
          fetchOptions: null
        }
      }
    case MAP_RESET_STYLE:
      return {
        ...state,
        status: MAP_STATUS_INIT
      }
    case MAP_DESTROYED:
      return { ...getInitialState(), bbox: state.bbox, geolocation: state.geolocation }
    case TOGGLE_FULLSCREEN:
      return {
        ...state,
        fullscreen: !state.fullscreen
      }
    case TURN_OFF_FULLSCREEN:
      return {
        ...state,
        fullscreen: false
      }
    case DISPLAY_POPUP:
      return {
        ...state,
        popupType: action.payload
      }
    case HIDE_POPUP:
      return {
        ...state,
        popupType: null
      }
    case SWITCH_MAP_TYPE:
      return {
        ...state,
        vecto: !state.vecto
      }
    case SET_MAP_EVENTS:
      return {
        ...state,
        mapEvents: action.payload ?? []
      }
    case SET_MAP_EVENT_POPIN:
      return {
        ...state,
        eventPopin: action.payload
      }
    case RESET_MAP_EVENT_POPIN:
      return {
        ...state,
        eventPopin: undefined
      }
    case SET_MAP_EVENT_DATE:
      return {
        ...state,
        mapEventDate: action.payload
      }
    case SET_MAP_EVENT_TIME:
      return {
        ...state,
        mapEventTime: action.payload
      }
    default:
      return state
  }
}
