import { HEADER_DEFAULT_HEIGHT_GE, HEADER_DEFAULT_HEIGHT_PE } from '../../domain/header/header-constants'
import { OUTDOOR_STATUS_NONE } from '../../domain/outdoor/Utils'
import {
  CLOSE_NPS_SURVEY,
  DISPLAY_GEOLOC,
  DISPLAY_MAP_TOO_SLOW_TOOLTIP,
  DISPLAY_MODAL,
  DISPLAY_OUTDOOR,
  HIDE_GEOLOC,
  HIDE_HOME_BANNER,
  HIDE_MAP_TOO_SLOW_TOOLTIP,
  HIDE_MODAL,
  HIDE_OUTDOOR,
  HIDE_PREFERENCES_USER_INCENTIVE,
  HIDE_UI_ELEMENT,
  INCREMENT_VISIT,
  RESET_CURRENT_SEARCH,
  RESET_GEOENTITY_ACTIVE_FILTER,
  RESET_GEOENTITY_DISPLAY_MODE_TOGGLE,
  RESET_ITINERARY_ELEVATION_POINT_OVER,
  SET_CURRENT_SEARCH,
  SET_GEOENTITY_ACTIVE_FILTER,
  SET_GEOENTITY_DISPLAY_MODE,
  SET_HEADER_HEIGHT,
  SET_ITINERARY_ELEVATION_POINT_OVER,
  SET_ITINERARY_RESULTS_INCENTIVE_DISPLAYED,
  SET_MAP_TOO_SLOW_TIMESTAMP,
  SET_POIS_ON_ROUTE_INCENTIVE_POPIN_TIMESTAMP,
  SET_POPIN_INCENTIVE_DISPLAYED,
  SET_USER_UI_SCREEN,
  SHOW_PREFERENCES_USER_INCENTIVE,
  SHOW_UI_ELEMENT,
  TOGGLEOFF_ONBOARDING_CONTEXT,
  TOGGLE_GEOENTITY_DISPLAY_MODE
} from './ui.actionTypes'
import { GEOENTITY_DISPLAY_MODE_LIST, GEOENTITY_DISPLAY_MODE_MAP } from './ui.geoentities.constants'

import { isPE } from '../../domain/Device'
import { USER_SCREENS } from './ui.constants'

export const getInitialState = () => {
  return {
    isOnBoardingContext: true,
    headerHeight: isPE() ? HEADER_DEFAULT_HEIGHT_PE : HEADER_DEFAULT_HEIGHT_GE,
    searchDialog: {
      isDisplayed: false
    },
    suggest: {
      isDisplayed: false
    },
    menuPE: {
      isDisplayed: false
    },
    map: {
      mapTooSlowTooltip: false,
      tooSlowTimestamp: 0,
      geoLoc: {
        isDisplayed: true
      }
    },
    geoentity: {
      displayMode: GEOENTITY_DISPLAY_MODE_MAP,
      filters: {
        active: undefined
      }
    },
    itinerary: {
      elevationPointOver: null
    },
    travelOptionsDialog: {
      isDisplayed: false
    },
    travelTimeOptionsDialog: {
      isDisplayed: false
    },
    survey: {
      lastTimeClosed: 0
    },
    popin: {
      isDisplayed: false
    },
    outdoor: {
      status: OUTDOOR_STATUS_NONE
    },
    displayedModalIds: [],
    user: {
      isDisplayed: false,
      screen: {
        name: USER_SCREENS.default,
        params: null
      }
    },
    visitCount: 0,
    userAccountIncentive: {
      timestamp: 0,
      preferencesDisplayed: false,
      itineraryResultsTimestamp: 0
    },
    poisOnRouteIncentive: {
      timestamp: 0
    },
    homeBanner: {
      shouldBeDisplayed: true
    }
  }
}

export default function uiReducer(state = getInitialState(), action) {
  switch (action && action.type) {
    case TOGGLEOFF_ONBOARDING_CONTEXT: {
      return {
        ...state,
        isOnBoardingContext: false
      }
    }

    case SET_CURRENT_SEARCH:
      const { currentSearch } = action.payload
      return {
        ...state,
        currentSearch
      }

    case RESET_CURRENT_SEARCH:
      return {
        ...state,
        currentSearch: ''
      }

    case SET_HEADER_HEIGHT:
      return {
        ...state,
        headerHeight: action.payload
      }

    case SHOW_UI_ELEMENT:
      return {
        ...state,
        [action.payload]: {
          ...(state?.[action?.payload] ?? {}),
          isDisplayed: true
        }
      }

    case HIDE_UI_ELEMENT:
      return {
        ...state,
        [action.payload]: {
          ...(state?.[action?.payload] ?? {}),
          isDisplayed: false
        }
      }

    case DISPLAY_GEOLOC:
      return {
        ...state,
        map: {
          ...state.map,
          geoLoc: { isDisplayed: true }
        }
      }

    case DISPLAY_MAP_TOO_SLOW_TOOLTIP:
      return {
        ...state,
        map: {
          ...state.map,
          mapTooSlowTooltip: true
        }
      }

    case HIDE_MAP_TOO_SLOW_TOOLTIP:
      return {
        ...state,
        map: {
          ...state.map,
          mapTooSlowTooltip: false
        }
      }

    case SET_MAP_TOO_SLOW_TIMESTAMP:
      return {
        ...state,
        map: {
          ...state.map,
          tooSlowTimestamp: Date.now()
        }
      }

    case HIDE_GEOLOC:
      return {
        ...state,
        map: {
          ...state.map,
          geoLoc: { isDisplayed: false }
        }
      }

    case CLOSE_NPS_SURVEY:
      return {
        ...state,
        survey: {
          lastTimeClosed: action.payload
        }
      }

    case DISPLAY_OUTDOOR:
      return {
        ...state,
        outdoor: action.payload
      }

    case HIDE_OUTDOOR:
      return {
        ...state,
        outdoor: {
          status: OUTDOOR_STATUS_NONE
        }
      }

    case DISPLAY_MODAL:
      return {
        ...state,
        displayedModalIds: [
          ...state.displayedModalIds.filter(id => id !== action.payload.modalId),
          action.payload.modalId
        ]
      }

    case HIDE_MODAL:
      return {
        ...state,
        displayedModalIds: action.payload.modalId
          ? state.displayedModalIds.filter(id => id !== action.payload.modalId)
          : []
      }

    case RESET_GEOENTITY_DISPLAY_MODE_TOGGLE:
      return {
        ...state,
        geoentity: {
          ...state.geoentity,
          displayMode: GEOENTITY_DISPLAY_MODE_MAP
        }
      }

    case TOGGLE_GEOENTITY_DISPLAY_MODE:
      return {
        ...state,
        geoentity: {
          ...state.geoentity,
          displayMode:
            state.geoentity.displayMode === GEOENTITY_DISPLAY_MODE_LIST
              ? GEOENTITY_DISPLAY_MODE_MAP
              : GEOENTITY_DISPLAY_MODE_LIST
        }
      }

    case SET_GEOENTITY_DISPLAY_MODE:
      return {
        ...state,
        geoentity: {
          ...state.geoentity,
          displayMode: action.payload
        }
      }

    case SET_GEOENTITY_ACTIVE_FILTER:
      const { id } = action.payload
      return {
        ...state,
        geoentity: {
          ...state.geoentity,
          filters: {
            active: id
          }
        }
      }

    case RESET_GEOENTITY_ACTIVE_FILTER:
      return {
        ...state,
        geoentity: {
          ...state.geoentity,
          filters: {
            active: undefined
          }
        }
      }

    case SET_ITINERARY_ELEVATION_POINT_OVER:
      return {
        ...state,
        itinerary: {
          ...state.itinerary,
          elevationPointOver: action.payload
        }
      }

    case RESET_ITINERARY_ELEVATION_POINT_OVER:
      return {
        ...state,
        itinerary: {
          ...state.itinerary,
          elevationPointOver: null
        }
      }

    case SET_USER_UI_SCREEN: {
      const { name, params } = action.payload
      return {
        ...state,
        user: {
          ...state.user,
          screen: {
            name,
            params
          }
        }
      }
    }

    case SET_POPIN_INCENTIVE_DISPLAYED: {
      return {
        ...state,
        userAccountIncentive: {
          ...state.userAccountIncentive,
          timestamp: action?.payload?.timestamp
        }
      }
    }

    case SET_ITINERARY_RESULTS_INCENTIVE_DISPLAYED: {
      return {
        ...state,
        userAccountIncentive: {
          ...state.userAccountIncentive,
          itineraryResultsTimestamp: action?.payload?.timestamp
        }
      }
    }

    case INCREMENT_VISIT: {
      const previousCount = state?.visitCount ?? 0
      return { ...state, visitCount: previousCount + 1 }
    }

    case SHOW_PREFERENCES_USER_INCENTIVE: {
      return {
        ...state,
        userAccountIncentive: {
          ...state.userAccountIncentive,
          preferencesDisplayed: true
        }
      }
    }

    case HIDE_PREFERENCES_USER_INCENTIVE: {
      return {
        ...state,
        userAccountIncentive: {
          ...state.userAccountIncentive,
          preferencesDisplayed: false
        }
      }
    }

    case SET_POIS_ON_ROUTE_INCENTIVE_POPIN_TIMESTAMP: {
      return {
        ...state,
        poisOnRouteIncentive: {
          ...state.poisOnRouteIncentive,
          timestamp: Date.now()
        }
      }
    }

    case HIDE_HOME_BANNER: {
      return {
        ...state,
        homeBanner: {
          shouldBeDisplayed: false
        }
      }
    }

    default:
      return state
  }
}
