import { bool } from 'prop-types'
import { FAVORITE_TYPES } from '../../domain/UserAccount/UserAccount.constants'
import { createCustomSelector } from '../../domain/utils/react-reselect'
import { head } from '../../utils/array'
import { isEmpty } from '../../utils/lang'
import { searchServiceCtx } from '../search/search.service.constants'
import { addressPropTypes, addressesPropTypes, descriptionPropTypes } from './address.proptypes'

const selectAddressState = store => store?.address

export const selectAddresses = createCustomSelector(selectAddressState, address => address?.addresses ?? [])
selectAddresses.propTypes = addressesPropTypes

export const selectHasOnlyOneAddress = createCustomSelector(selectAddresses, addresses => addresses.length === 1)

export const selectFirstAddress = createCustomSelector(selectAddresses, addresses => head(addresses) || {})
selectFirstAddress.propTypes = addressPropTypes

export const selectFirstAddressCoordinates = createCustomSelector(
  selectFirstAddress,
  address => address?.coordinates ?? {}
)

export const selectHasFirstAddress = createCustomSelector(selectFirstAddress, address => !isEmpty(address))
selectHasFirstAddress.propTypes = bool

export const getSelectedAddress = (store, props = {}) => {
  if (props.address) return props.address

  return selectFirstAddress(store)
}

export const selectSelectedAdressAsFavorite = createCustomSelector(getSelectedAddress, ({ label }) => {
  return {
    type: FAVORITE_TYPES.OTHER,
    address: label
  }
})

export const selectSelectedAddressLabelForUrl = createCustomSelector(getSelectedAddress, ({ label }) => label)

export const selectAddressContour = createCustomSelector(selectAddressState, address => address.contour)

export const selectIsAddressAMunicipality = createCustomSelector(selectFirstAddress, address => {
  return [
    searchServiceCtx.geocodeLevel.town,
    searchServiceCtx.geocodeLevel.district,
    searchServiceCtx.geocodeLevel.namedPlace
  ].includes(address.geocode_level)
})

export const selectIsAddressWithStreetNumber = createCustomSelector(
  selectFirstAddress,
  address => address.geocode_level === searchServiceCtx.geocodeLevel.addressPoint
)
selectIsAddressWithStreetNumber.propTypes = bool

export const selectIsEligibleFor360 = createCustomSelector(selectFirstAddress, address => {
  return [
    searchServiceCtx.geocodeLevel.addressPoint,
    searchServiceCtx.geocodeLevel.addressRange,
    searchServiceCtx.geocodeLevel.namedPlace,
    searchServiceCtx.geocodeLevel.landmark,
    searchServiceCtx.geocodeLevel.way,
    searchServiceCtx.geocodeLevel.district
  ].includes(address.geocode_level)
})

export const selectDescription = createCustomSelector(selectAddressState, address => address?.description)
selectDescription.propTypes = descriptionPropTypes

export const selectViewport = createCustomSelector(selectAddressState, address => address?.viewport)

export const selectAddressError = createCustomSelector(selectAddressState, address => address?.error)

export const selectZone = createCustomSelector(selectFirstAddress, address => {
  const { countryCode, countryLabel, geocode_level, regionLabel, regionCode, departmentLabel, departmentCode } = address
  const isDepartment = geocode_level === searchServiceCtx.geocodeLevel.admin7
  return {
    countryCode,
    countryLabel,
    label: isDepartment ? departmentLabel : regionLabel,
    code: isDepartment ? departmentCode : regionCode,
    isDepartment
  }
})
