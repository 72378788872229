export const searchServiceCtx = {
  filter: {
    default: 'places',
    address: 'address',
    poi: 'poi',
    poiXorAddress: 'poi_xor_address',
    state: 'state',
    county: 'county',
    town: 'town'
  },
  geocodeLevel: {
    addressPoint: 'address_point',
    addressRange: 'address_range',
    namedPlace: 'named_place',
    landmark: 'landmark',
    way: 'way',
    district: 'district',
    town: 'town',
    admin7: 'admin_7', // department
    admin2: 'admin_2',
    admin1: 'admin_1', // region
    country: 'country'
  }
}

export const geocodeForwardCtx = {
  filter: {
    country: 'country',
    state: 'state',
    province: 'province',
    county: 'county',
    town: 'town',
    hamlet: 'hamlet',
    landmark: 'landmark',
    way: 'way',
    municipality: 'municipality' // (town, district, hamlet)
  }
}

export const geocodeLevelsWithContour = [
  searchServiceCtx.geocodeLevel.town,
  searchServiceCtx.geocodeLevel.district,
  searchServiceCtx.geocodeLevel.admin7,
  searchServiceCtx.geocodeLevel.admin1
]
