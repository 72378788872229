export const SET_GEOENTITY_LIST = 'SET_GEOENTITY_LIST'
export const SET_SINGLE_GEOENTITY = 'SET_SINGLE_GEOENTITY'
export const ADD_GEOENTITY_LIST = 'ADD_GEOENTITY_LIST'
export const RESET_GEOENTITY_LIST = 'RESET_GEOENTITY_LIST'
export const SELECT_GEOENTITY = 'SELECT_GEOENTITY'
export const REFRESH_SELECTED_GEOENTITY_DATA = 'REFRESH_SELECTED_GEOENTITY_DATA'
export const POPULATE_GEOENTITY_TAB_CONTENT = 'POPULATE_GEOENTITY_TAB_CONTENT'
export const UNSELECT_GEOENTITY = 'UNSELECT_GEOENTITY'
export const REQUEST_UNSELECT_GEOENTITY = 'REQUEST_UNSELECT_GEOENTITY'
export const GEOENTITY_NO_RESULT = 'GEOENTITY_NO_RESULT'
export const GEOENTITY_NOT_FOUND = 'GEOENTITY_NOT_FOUND'
export const SET_GEOENTITY_TAB_ID = 'SET_GEOENTITY_TAB_ID'
export const SET_GEOENTITY_TAB_BLOCKS_IDX = 'SET_GEOENTITY_TAB_BLOCKS_IDX'
export const FOCUS_GEOENTITY = 'FOCUS_GEOENTITY'
export const UNFOCUS_GEOENTITY = 'UNFOCUS_GEOENTITY'
export const REQUEST_UNFOCUS_GEOENTITY = 'REQUEST_UNFOCUS_GEOENTITY'
export const SET_GEOENTITIES_REQUESTED = 'SET_GEOENTITIES_REQUESTED'
export const SET_GEOENTITIES_RECEIVED = 'SET_GEOENTITIES_RECEIVED'
export const SET_GEOENTITY_FILTER_PARAMETER = 'SET_GEOENTITY_FILTER_PARAMETER'
export const RESET_GEOENTITY_FILTER_PARAMETER = 'RESET_GEOENTITY_FILTER_PARAMETER'
export const SET_FUEL_INFORMATION = 'SET_FUEL_INFORMATION'
