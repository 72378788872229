import { SAVE_CURRENT_SEARCH_RESULT, CLEAR_HISTORY } from './searchHistory.actionTypes'

export const saveCurrentSearchResult =
  ({ label, split_label, type }) =>
  (dispatch, getState) =>
    dispatch({
      type: SAVE_CURRENT_SEARCH_RESULT,
      payload: {
        date: Date.now(),
        label,
        split_label,
        type
      }
    })

export const resetSearchHistory = () => (dispatch, getState) =>
  dispatch({
    type: CLEAR_HISTORY
  })
