import {
  SET_QUIZ_CITIES,
  SET_QUIZ_CITY_ANSWER,
  SET_QUIZ_GUESS_COORDINATES,
  SET_QUIZ_CURRENT_CITY_INDEX,
  SET_QUIZ_LEVEL,
  RESET_QUIZ,
  SET_QUIZ_TYPE,
  SET_QUIZ_CONFIG,
  SET_QUIZ_LINKS
} from './quiz.actionTypes'

export const getInitialState = () => {
  return {
    type: undefined,
    cities: null,
    currentCityIndex: 0,
    level: undefined,
    config: {},
    links: {}
  }
}

export default function uiReducer(state = getInitialState(), action) {
  switch (action && action.type) {
    case RESET_QUIZ: {
      return {
        ...getInitialState(),
        links: state.links
      }
    }
    case SET_QUIZ_CITIES: {
      return {
        ...state,
        cities: action.payload,
        currentCityIndex: 0
      }
    }

    case SET_QUIZ_TYPE: {
      return {
        ...state,
        type: action.payload
      }
    }

    case SET_QUIZ_CONFIG: {
      return {
        ...state,
        config: action.payload
      }
    }

    case SET_QUIZ_LEVEL: {
      return {
        ...state,
        level: action.payload
      }
    }

    case SET_QUIZ_CURRENT_CITY_INDEX: {
      return {
        ...state,
        currentCityIndex: action.payload
      }
    }

    case SET_QUIZ_GUESS_COORDINATES:
    case SET_QUIZ_CITY_ANSWER: {
      const { cityIndex, answer } = action.payload
      const cities = state.cities?.slice(0)
      const currentCity = cities?.[cityIndex]
      if (!currentCity?.answer) return state
      cities[cityIndex] = {
        ...currentCity,
        answer: {
          ...currentCity.answer,
          ...answer
        }
      }

      return {
        ...state,
        cities
      }
    }

    case SET_QUIZ_LINKS: {
      return {
        ...state,
        links: {
          ...state.links,
          ...action.payload
        }
      }
    }

    default:
      return state
  }
}
