import { string } from 'prop-types'
import { isPagelessRoute } from '../../domain/router/routeUtils'
import { createCustomSelector } from '../../domain/utils/react-reselect'
import {
  ROUTE_ADDRESS,
  ROUTE_BICYCLE_CITY,
  ROUTE_BICYCLE_DEPARTMENT,
  ROUTE_BICYCLE_REGION,
  ROUTE_DEPARTMENT_HOME,
  ROUTE_GEOENTITY_AT_ADDRESS,
  ROUTE_GEOENTITY_ON_SEARCH_BY_APP,
  ROUTE_GEOENTITY_ON_SEARCH_BY_CATEGORY,
  ROUTE_GEOENTITY_ON_SEARCH_BY_QUERY,
  ROUTE_GEOENTITY_ON_SEARCH_BY_RUBRIC,
  ROUTE_GEOENTITY_ON_SEARCH_BY_SUBCATEGORY,
  ROUTE_HOME,
  ROUTE_ITINERARY_COMPUTING,
  ROUTE_ITINERARY_COMPUTING_BY_MODE,
  ROUTE_ITINERARY_EXPENSE_REPORT,
  ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_APP,
  ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_CATEGORY,
  ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_SUBCATEGORY,
  ROUTE_ITINERARY_HOME,
  ROUTE_ITINERARY_HP_BY_MODE,
  ROUTE_ITINERARY_RESULTS_BY_PROVIDER,
  ROUTE_ITINERARY_RESULTS_BY_ROUTE,
  ROUTE_ITINERARY_ROADBOOK,
  ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_APP,
  ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_CATEGORY,
  ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_SUBCATEGORY,
  ROUTE_ITINERARY_TO,
  ROUTE_QUIZ_CITIES,
  ROUTE_QUIZ_CITIES_DEPARTMENT,
  ROUTE_QUIZ_CITIES_REGION,
  ROUTE_QUIZ_HOME,
  ROUTE_REGION_HOME,
  ROUTE_RESTRICTION_CITY,
  ROUTE_RESTRICTION_DEPARTMENT,
  ROUTE_RESTRICTION_REGION,
  ROUTE_SEARCH_BY_APP,
  ROUTE_SEARCH_BY_CATEGORY,
  ROUTE_SEARCH_BY_QUERY,
  ROUTE_SEARCH_BY_RUBRIC,
  ROUTE_SEARCH_BY_SUBCATEGORY,
  ROUTE_SEO_ADDRESS,
  ROUTE_SEO_CITY_ACTIVITY,
  ROUTE_SEO_CITY_APP,
  ROUTE_SEO_COUNTRY,
  ROUTE_SEO_DEPARTMENT,
  ROUTE_SEO_GEOENTITY,
  ROUTE_SEO_ITINERARY,
  ROUTE_SEO_ITINERARY_MODE,
  ROUTE_SEO_REGION,
  ROUTE_SEO_SEARCH_BY_APP,
  ROUTE_SEO_SEARCH_BY_RUBRIC,
  ROUTE_SUGGEST,
  ROUTE_TOOLS_COMPUTE_RADIUS,
  ROUTE_TRAFFIC_CITY,
  ROUTE_TRAFFIC_DEPARTMENT,
  ROUTE_TRAFFIC_REGION,
  ROUTE_TRAVEL_OPTIONS_DIALOG,
  ROUTE_TRAVEL_TIME_OPTIONS_DIALOG,
  ROUTE_ZFE_CITY,
  ROUTE_ZFE_DEPARTMENT,
  ROUTE_ZFE_REGION
} from '../../routes'
import { invert, omit } from '../../utils/object'
import { selectSearchParameters } from '../search/search.selectors'

const selectHistoryState = store => store?.history

export const selectCurrentHistoryRouteOptions = createCustomSelector(
  selectHistoryState,
  history => history?.routeOptions ?? {}
)

export const selectPageTagContext = createCustomSelector(
  selectCurrentHistoryRouteOptions,
  routeOptions => routeOptions?.pageTagContext
)

export const selectShouldAvoidSendPageTag = createCustomSelector(
  selectCurrentHistoryRouteOptions,
  routeOptions => routeOptions?.avoidSendPageTag
)

export const isItineraryRoute = route =>
  [
    ROUTE_ITINERARY_HOME,
    ROUTE_ITINERARY_HP_BY_MODE,
    ROUTE_ITINERARY_TO,
    ROUTE_TRAVEL_OPTIONS_DIALOG,
    ROUTE_TRAVEL_TIME_OPTIONS_DIALOG,
    ROUTE_ITINERARY_ROADBOOK,
    ROUTE_ITINERARY_EXPENSE_REPORT,
    ROUTE_SEO_ITINERARY,
    ROUTE_SEO_ITINERARY_MODE
  ].includes(route) ||
  isItineraryResultRoute(route) ||
  isASearchOnItineraryPage(route)

export const isSearchOnItineraryRoute = route =>
  [
    ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_APP,
    ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_CATEGORY,
    ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_SUBCATEGORY
  ].includes(route)

export const isGeoentityOnItineraryRoute = route =>
  [
    ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_APP,
    ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_CATEGORY,
    ROUTE_ITINERARY_GEOENTITY_ON_SEARCH_POIS_ON_ROUTE_BY_SUBCATEGORY
  ].includes(route)

export const isASearchOnItineraryPage = route => isSearchOnItineraryRoute(route) || isGeoentityOnItineraryRoute(route)

export const isItineraryResultRoute = route =>
  [
    ROUTE_ITINERARY_COMPUTING,
    ROUTE_ITINERARY_COMPUTING_BY_MODE,
    ROUTE_ITINERARY_RESULTS_BY_PROVIDER,
    ROUTE_ITINERARY_RESULTS_BY_ROUTE,
    ROUTE_ITINERARY_ROADBOOK
  ].includes(route)

export const isPlacesRoute = route => !isItineraryRoute(route)

export const isAddressRoute = route => [ROUTE_ADDRESS, ROUTE_SEO_ADDRESS, ROUTE_GEOENTITY_AT_ADDRESS].includes(route)

export const isGeoentityRoute = route => [ROUTE_SEO_GEOENTITY].includes(route)

export const isZoneRoute = route => [ROUTE_SEO_DEPARTMENT, ROUTE_SEO_REGION, ROUTE_SEO_COUNTRY].includes(route)

export const isCountryRoute = route => [ROUTE_SEO_COUNTRY].includes(route)

export const isLayerCityRoute = route =>
  [ROUTE_TRAFFIC_CITY, ROUTE_BICYCLE_CITY, ROUTE_ZFE_CITY, ROUTE_RESTRICTION_CITY].includes(route)

export const isLayerDepartmentRoute = route =>
  [ROUTE_TRAFFIC_DEPARTMENT, ROUTE_BICYCLE_DEPARTMENT, ROUTE_ZFE_DEPARTMENT, ROUTE_RESTRICTION_DEPARTMENT].includes(
    route
  )

export const isLayerRegionRoute = route =>
  [ROUTE_TRAFFIC_REGION, ROUTE_BICYCLE_REGION, ROUTE_ZFE_REGION, ROUTE_RESTRICTION_REGION].includes(route)

export const isLayerResponseRoute = route =>
  isLayerCityRoute(route) || isLayerDepartmentRoute(route) || isLayerRegionRoute(route)

export const selectCurrentHistoryRoute = createCustomSelector(selectHistoryState, history => history?.route ?? '')

export const selectCurrentHistoryFullRoute = createCustomSelector(
  selectHistoryState,
  history => history?.fullRoute ?? ''
)
selectCurrentHistoryRoute.propTypes = string

export const selectIsCurrentHistoryRoutePageless = createCustomSelector(selectCurrentHistoryRoute, route =>
  isPagelessRoute(route)
)

export const selectPreviousHistoryRoute = createCustomSelector(selectHistoryState, history => history?.prevRoute)

export const selectIsPreviousHistoryRoutePageless = createCustomSelector(selectPreviousHistoryRoute, prevRoute =>
  isPagelessRoute(prevRoute)
)

export const selectPrevFullRouteNonPageLess = createCustomSelector(
  selectHistoryState,
  state => state?.prevFullRouteNonPageLess
)

export const selectIsGeoentityAtAddressPage = createCustomSelector(
  selectCurrentHistoryRoute,
  route => route === ROUTE_GEOENTITY_AT_ADDRESS
)

export const selectIsHomePage = createCustomSelector(selectCurrentHistoryRoute, route => route === ROUTE_HOME)

export const selectCurrentRouteIgnoringSuggestion = createCustomSelector(
  selectCurrentHistoryRoute,
  selectPreviousHistoryRoute,
  (route, prevRoute) => (route === ROUTE_SUGGEST ? prevRoute : route)
)

export const selectIsSuggestRouteFromItinerary = createCustomSelector(
  selectCurrentHistoryRoute,
  selectPreviousHistoryRoute,
  (route, prevRoute) => route === ROUTE_SUGGEST && isItineraryRoute(prevRoute)
)

export const selectIsSuggestRouteFromItineraryResult = createCustomSelector(
  selectCurrentHistoryRoute,
  selectPreviousHistoryRoute,
  (route, prevRoute) => route === ROUTE_SUGGEST && isItineraryResultRoute(prevRoute)
)

export const selectIsSuggestRouteFromPlaces = createCustomSelector(
  selectCurrentHistoryFullRoute,
  selectPreviousHistoryRoute,
  (route, prevRoute) => route === ROUTE_SUGGEST && isPlacesRoute(prevRoute)
)

export const selectIsSuggestRouteFromGeoentity = createCustomSelector(
  selectCurrentHistoryRoute,
  selectPreviousHistoryRoute,
  (route, prevRoute) => route === ROUTE_SUGGEST && isGeoentityRoute(prevRoute)
)

export const selectIsItineraryUniverse = createCustomSelector(
  selectCurrentHistoryRoute,
  selectIsSuggestRouteFromItinerary,
  (route, isSuggestFromItinerary) => isItineraryRoute(route) || isSuggestFromItinerary
)

export const selectIsGeoentityOnItineraryPage = createCustomSelector(selectCurrentHistoryRoute, route =>
  isASearchOnItineraryPage(route)
)

export const selectIsSearchOnItineraryRoute = createCustomSelector(selectCurrentHistoryRoute, route =>
  isSearchOnItineraryRoute(route)
)

export const selectIsPlacesUniverse = createCustomSelector(
  selectCurrentHistoryRoute,
  selectIsSuggestRouteFromPlaces,
  (route, isSuggestFromPlaces) => isPlacesRoute(route) || isSuggestFromPlaces
)

export const selectIsAnItineraryResultPage = createCustomSelector(
  selectCurrentHistoryRoute,
  selectIsSuggestRouteFromItineraryResult,
  (route, isSuggestFromItineraryResult) => isItineraryResultRoute(route) || isSuggestFromItineraryResult
)

export const selectIsAnItineraryRoadbookPage = createCustomSelector(
  selectCurrentHistoryRoute,
  route => route === ROUTE_ITINERARY_ROADBOOK
)

export const selectIsAGeoentityPage = createCustomSelector(
  selectCurrentHistoryRoute,
  selectIsSuggestRouteFromGeoentity,
  (route, isSuggestFromGeoentities) => isGeoentityRoute(route) || isSuggestFromGeoentities
)

export const selectIsRegionPage = createCustomSelector(
  selectCurrentHistoryRoute,
  selectPreviousHistoryRoute,
  (route, routeFrom) => route === ROUTE_REGION_HOME
)

export const selectIsDepartmentPage = createCustomSelector(
  selectCurrentHistoryRoute,
  selectPreviousHistoryRoute,
  (route, routeFrom) => route === ROUTE_DEPARTMENT_HOME
)

export const selectIsZonePage = createCustomSelector(
  selectIsRegionPage,
  selectIsDepartmentPage,
  (isRegionPage, isDepartmentPage) => isRegionPage || isDepartmentPage
)

export const selectIsCurrentAndPreviousRouteTheSame = createCustomSelector(
  selectCurrentHistoryRoute,
  selectPreviousHistoryRoute,
  (currentRoute, previousRoute) => currentRoute === previousRoute
)

export const searchRoutes = [
  ROUTE_SEARCH_BY_CATEGORY,
  ROUTE_SEARCH_BY_SUBCATEGORY,
  ROUTE_SEARCH_BY_RUBRIC,
  ROUTE_SEARCH_BY_QUERY,
  ROUTE_SEARCH_BY_APP,
  ROUTE_SEO_SEARCH_BY_APP,
  ROUTE_SEO_SEARCH_BY_RUBRIC
]

export const isSearchRoute = route => searchRoutes.includes(route)

export const geoentityOnSearchRoutes = [
  ROUTE_GEOENTITY_ON_SEARCH_BY_CATEGORY,
  ROUTE_GEOENTITY_ON_SEARCH_BY_SUBCATEGORY,
  ROUTE_GEOENTITY_ON_SEARCH_BY_APP,
  ROUTE_GEOENTITY_ON_SEARCH_BY_RUBRIC,
  ROUTE_GEOENTITY_ON_SEARCH_BY_QUERY
]

export const isGeoentityOnSearchRoute = route => geoentityOnSearchRoutes.includes(route)

export const selectIsSearchPage = createCustomSelector(selectCurrentHistoryRoute, route => isSearchRoute(route))

export const selectIsAddressPage = createCustomSelector(selectCurrentHistoryRoute, route => isAddressRoute(route))

export const selectIsAGeoentityOnSearchPage = createCustomSelector(selectCurrentHistoryRoute, route =>
  isGeoentityOnSearchRoute(route)
)

export const selectIsASearchPage = createCustomSelector(
  selectCurrentHistoryRoute,
  route => isSearchRoute(route) || isGeoentityOnSearchRoute(route)
)

const geoentityOnSearchRouteMatching = {
  [ROUTE_SEARCH_BY_CATEGORY]: ROUTE_GEOENTITY_ON_SEARCH_BY_CATEGORY,
  [ROUTE_SEARCH_BY_SUBCATEGORY]: ROUTE_GEOENTITY_ON_SEARCH_BY_SUBCATEGORY,
  [ROUTE_SEO_SEARCH_BY_APP]: ROUTE_GEOENTITY_ON_SEARCH_BY_APP,
  [ROUTE_SEARCH_BY_APP]: ROUTE_GEOENTITY_ON_SEARCH_BY_APP,
  [ROUTE_SEO_SEARCH_BY_RUBRIC]: ROUTE_GEOENTITY_ON_SEARCH_BY_RUBRIC,
  [ROUTE_SEARCH_BY_RUBRIC]: ROUTE_GEOENTITY_ON_SEARCH_BY_RUBRIC,
  [ROUTE_SEARCH_BY_QUERY]: ROUTE_GEOENTITY_ON_SEARCH_BY_QUERY
}

export const selectGeoentityOnSearchRouteBaseParameters = createCustomSelector(
  selectCurrentHistoryRoute,
  selectSearchParameters,
  (route, searchParameters) => {
    return {
      route: geoentityOnSearchRouteMatching[route] || route,
      routeOptions: {
        ...searchParameters
      }
    }
  }
)

export const selectSearchRouteBaseParameters = createCustomSelector(
  selectCurrentHistoryRoute,
  selectSearchParameters,
  (route, searchParameters) => {
    const invertMatching = invert(geoentityOnSearchRouteMatching)
    return {
      route: invertMatching[route] || route,
      routeOptions: {
        ...omit(searchParameters, 'geoentityId')
      }
    }
  }
)

export const selectIsSeoCityActivityPage = createCustomSelector(selectCurrentHistoryRoute, route =>
  [ROUTE_SEO_CITY_ACTIVITY, ROUTE_SEARCH_BY_RUBRIC, ROUTE_SEO_SEARCH_BY_RUBRIC].includes(route)
)
export const selectIsSeoCityAppPage = createCustomSelector(
  selectCurrentHistoryRoute,
  route => route === ROUTE_SEO_CITY_APP
)

export const selectCurrentHistoryRouteDone = createCustomSelector(selectHistoryState, history => {
  return history?.routeDone
})

export const selectIsPageElligibleForPoisOnRoute = createCustomSelector(
  selectCurrentHistoryRoute,
  route => isItineraryResultRoute(route) || isASearchOnItineraryPage(route)
)

export const quizRoutes = [ROUTE_QUIZ_CITIES, ROUTE_QUIZ_CITIES_REGION, ROUTE_QUIZ_CITIES_DEPARTMENT, ROUTE_QUIZ_HOME]

export const isQuizRoute = route => quizRoutes.includes(route)

export const selectIsQuizPage = createCustomSelector(selectCurrentHistoryRoute, isQuizRoute)

export const isToolsComputeRadius = route => route === ROUTE_TOOLS_COMPUTE_RADIUS

export const selectIsToolsComputeRadiusPage = createCustomSelector(
  selectCurrentRouteIgnoringSuggestion,
  isToolsComputeRadius
)
