import { polylineActionSanitizer, polylineStateSanitizer } from './storeSanitizers'

export const shouldUseDevTools = () => __BROWSER__ && global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__

export const composeWithDevTools = () =>
  global.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    trace: true,
    maxAge: 150,
    latency: 5000,
    actionSanitizer: polylineActionSanitizer,
    stateSanitizer: polylineStateSanitizer
  })
