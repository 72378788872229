import { SET_LOCALE } from './locale.actionTypes'

const getInitialState = () => ({
  value: 'fr-FR'
})

export default function localeReducer(state = getInitialState(), action) {
  switch (action && action.type) {
    case SET_LOCALE:
      return {
        value: action.payload
      }
    default:
      return state
  }
}
