import {
  HISTORY_PUSH,
  REVERSE_RESOLVE_ROUTE_AND_REGISTER,
  HISTORY_POP,
  HISTORY_SAVE_POST_POP,
  HISTORY_GO_TO_EXTERNAL,
  HISTORY_OPEN_POPUP,
  HISTORY_NAVIGATION_DONE
} from './history.actionTypes'
import {
  ROUTE_HOME,
  ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_APP,
  ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_CATEGORY,
  ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_SUBCATEGORY,
  ROUTE_SEARCH_BY_APP,
  ROUTE_SEARCH_BY_CATEGORY,
  ROUTE_SEARCH_BY_QUERY,
  ROUTE_SEARCH_BY_RUBRIC,
  ROUTE_SEARCH_BY_SUBCATEGORY
} from '../../routes'
import { selectNavigateToGeoentityParameters } from './history.navigate.selectors'
import { parseRouteOptions, routeFormatter } from './history.utils'
import { selectCurrentHistoryFullRoute, selectCurrentHistoryRoute } from './history.selectors'
import { PARAM_REDIRECT } from '../../domain/router/queryParameters.constants'

export const navigateTo =
  ({ route, routeOptions, selectedStoreState }) =>
  (dispatch, getState) => {
    const storeState = getState()
    const targetFullRoute = routeFormatter({
      storeState,
      route,
      selectedStoreState,
      routeOptions
    })

    const currentFullRoute = selectCurrentHistoryFullRoute(storeState)
    if (!targetFullRoute || currentFullRoute === targetFullRoute) return

    dispatch({
      type: HISTORY_PUSH,
      payload: {
        route,
        routeOptions,
        selectedStoreState,
        fullRoute: targetFullRoute
      }
    })
  }

export const navigateBack = () => dispatch =>
  dispatch({
    type: HISTORY_POP
  })

export const savePostPop = () => dispatch =>
  dispatch({
    type: HISTORY_SAVE_POST_POP
  })

export const registerRoute = (fullRoute, routeOptions) => dispatch =>
  dispatch({
    type: REVERSE_RESOLVE_ROUTE_AND_REGISTER,
    payload: {
      fullRoute,
      routeOptions: parseRouteOptions(routeOptions)
    }
  })

export const navigateToExternal =
  ({ url }) =>
  dispatch =>
    dispatch({
      type: HISTORY_GO_TO_EXTERNAL,
      payload: { url }
    })

export const navigateToPopup =
  ({ url, popupName, width, height }) =>
  dispatch =>
    dispatch({
      type: HISTORY_OPEN_POPUP,
      payload: { url, popupName, width, height }
    })

export const navigateToPoisOnRouteByCategory = category => (dispatch, getState) => {
  navigateTo({
    route: ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_CATEGORY,
    routeOptions: { category }
  })(dispatch, getState)
}

export const navigateToPoisOnRouteBySubcategory = subcategory => (dispatch, getState) => {
  navigateTo({
    route: ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_SUBCATEGORY,
    routeOptions: { subcategory }
  })(dispatch, getState)
}

export const navigateToPoisOnRouteByApp = app => (dispatch, getState) => {
  navigateTo({
    route: ROUTE_ITINERARY_SEARCH_POIS_ON_ROUTE_BY_APP,
    routeOptions: { app }
  })(dispatch, getState)
}

export const navigateToSearchByCategory = category => (dispatch, getState) => {
  navigateTo({
    route: ROUTE_SEARCH_BY_CATEGORY,
    routeOptions: { category }
  })(dispatch, getState)
}

export const navigateToSearchBySubcategory = subcategory => (dispatch, getState) => {
  navigateTo({
    route: ROUTE_SEARCH_BY_SUBCATEGORY,
    routeOptions: { subcategory }
  })(dispatch, getState)
}

export const navigateToSearchByApp = (app, routeOptions) => (dispatch, getState) => {
  navigateTo({
    route: ROUTE_SEARCH_BY_APP,
    routeOptions: { app, ...routeOptions }
  })(dispatch, getState)
}

export const navigateToSearchByRubric =
  (rubricId, options = {}) =>
  (dispatch, getState) => {
    navigateTo({
      route: ROUTE_SEARCH_BY_RUBRIC,
      routeOptions: { rubric: rubricId, ...options }
    })(dispatch, getState)
  }

export const navigateToSearchByQuery =
  (query, options = {}) =>
  (dispatch, getState) =>
    navigateTo({
      route: ROUTE_SEARCH_BY_QUERY,
      routeOptions: { query, ...options }
    })(dispatch, getState)

export const navigateToGeoentity =
  ({ id, scrollTop }) =>
  (dispatch, getState) => {
    const params = selectNavigateToGeoentityParameters(getState(), { id, scrollTop })
    navigateTo(params)(dispatch, getState)
  }

export const navigateToHomepage = () => (dispatch, getState) => {
  navigateTo({ route: ROUTE_HOME })(dispatch, getState)
}

export const noticeNavigationDone = routeContext => (dispatch, getState) => {
  const currentRoute = selectCurrentHistoryRoute(getState())

  if (!routeContext?.query?.get(PARAM_REDIRECT))
    routeContext?.query.set(PARAM_REDIRECT, routeContext.state?.query?.redirect)

  return dispatch({
    type: HISTORY_NAVIGATION_DONE,
    payload: { route: currentRoute, ...(routeContext?.query && { query: routeContext.query }) }
  })
}
