export const SEND_PAGE_TAG = 'SEND_PAGE_TAG'
export const SEND_PAGE_TAG_V3 = 'SEND_PAGE_TAG_V3'

export const ReponseItineraireChoix = 'ReponseItineraireChoix'

export const TAG_PRINT_ROADBOOK = 'PRINT_ROADBOOK'
export const TAG_FOOTER_SOCIAL_NETWORKS_LINKS = 'TAG_FOOTER_SOCIAL_NETWORKS_LINKS'
export const TAG_FOOTER_AUTOPROMO = 'TAG_FOOTER_AUTOPROMO'
export const TAG_BLOCK_AUTOPROMO = 'TAG_BLOCK_AUTOPROMO'
export const TAG_NDF = 'TAG_NDF'

export const TAG_MULTIPATH_CTA = 'TAG_MULTIPATH_CTA'

export const TAG_VDE_CTA = 'TAG_VDE_CTA'
export const TAG_VDE_CLOSE = 'TAG_VDE_CLOSE'
export const TAG_CLICK_POI = 'TAG_CLICK_POI'
export const TAG_CLICK_MY_MAP = 'TAG_CLICK_MY_MAP'
export const TAG_GOTO = 'TAG_GOTO'
export const TAG_GOTO_AJOUT_ETAPE = 'TAG_GOTO_AJOUT_ETAPE'
export const TAG_SUGGEST = 'TAG_SUGGEST'
export const TAG_HOME_WORK = 'TAG_HOME_WORK'
export const TAG_SLIDER = 'TAG_SLIDER'
export const TAG_BACK = 'TAG_BACK'
export const TAG_POI_ON_ROUTE = 'TAG_POI_ON_ROUTE'
export const TAG_LOGO_MAPPY = 'TAG_LOGO_MAPPY'
export const TAG_LANDING_VIEW_MORE = 'TAG_LANDING_VIEW_MORE'
export const TAG_LANDING_GOTO_MP = 'TAG_LANDING_GOTO_MP'
export const TAG_CITY_ACTIVITY_LANDING_GOTO_POI = 'TAG_CITY_ACTIVITY_LANDING_GOTO_POI'
export const TAG_CATEGORY_POPIN = 'TAG_CATEGORY_POPIN'
export const TAG_CATEGORY_LIST = 'TAG_CATEGORY_LIST'
export const TAG_PUBLISHER = 'TAG_PUBLISHER'
export const TAG_CLICK_POI_FICHE_ADDRESSE = 'TAG_CLICK_POI_FICHE_ADDRESSE'
export const TAG_ADDRESS_IMMERSIVE = 'TAG_ADDRESS_IMMERSIVE'
export const TAG_BLOC_HOTEL = 'TAG_BLOC_HOTEL'
export const TAG_CLICK_SHARE = 'TAG_CLICK_SHARE'
export const TAG_CLICK_CATEGORY_MORE = 'TAG_CLICK_CATEGORY_MORE'
export const TAG_FICHEPOI_IMMERSIVE = 'TAG_FICHEPOI_IMMERSIVE'
export const TAG_UPI_IMMERSIVE = 'TAG_UPI_IMMERSIVE'
export const TAG_CLICK_CATEGORY_BAR = 'TAG_CLICK_CATEGORY_BAR'
export const TAG_CLICK_CATEGORY_FILTER = 'TAG_CLICK_CATEGORY_FILTER'
export const TAG_CLICK_CAMPAGNE_PUSH = 'TAG_CLICK_CAMPAGNE_PUSH'

export const TAG_CLICK_USER_BURGER = 'TAG_CLICK_USER_BURGER'
export const TAG_CLICK_USER_LOGIN_BUTTON = 'TAG_CLICK_USER_LOGIN_BUTTON'
export const TAG_CLICK_USER_ACCOUNT_BUTTON = 'TAG_CLICK_USER_ACCOUNT_BUTTON'
export const TAG_CLICK_USER_LOGOUT_BUTTON = 'TAG_CLICK_USER_LOGOUT_BUTTON'
export const TAG_CLICK_USER_FAVORITES_LIST = 'TAG_CLICK_USER_FAVORITES_LIST'
export const TAG_CLICK_USER_FAVORITES_BUTTON = 'TAG_CLICK_USER_FAVORITES_BUTTON'

export const TAG_CLICK_TRAVEL_OPTIONS_MODEFAVORI = 'TAG_CLICK_TRAVEL_OPTIONS_MODEFAVORI'
export const TAG_CLICK_TRAVEL_OPTIONS_CAR_VEHICLE = 'TAG_CLICK_TRAVEL_OPTIONS_CAR_VEHICLE'
export const TAG_CLICK_TRAVEL_OPTIONS_MOTORBIKE_VEHICLE = 'TAG_CLICK_TRAVEL_OPTIONS_MOTORBIKE_VEHICLE'

export const TAG_CLICK_CONTEXT_MENU = 'TAG_CLICK_CONTEXT_MENU'

export const TAG_CLICK_TOGGLE_HD_MAP = 'TAG_CLICK_TOGGLE_HD_MAP'
export const TAG_CLICK_TOGGLE_FULLSCREEN = 'TAG_CLICK_TOGGLE_FULLSCREEN'

export const TAG_DRAG_AND_DROP = 'TAG_DRAG_AND_DROP'

/* TAGS V3 */
export const TAG_SEARCH_GEOLOCATION = 'TAG_SEARCH_GEOLOCATION'
export const TAG_CLICK_QUIZ_REPLAY = 'TAG_CLICK_QUIZ_REPLAY'
export const TAG_CLICK_QUIZ_SHARE = 'TAG_CLICK_QUIZ_SHARE'
export const TAG_CLICK_QUIZ_PLAY = 'TAG_CLICK_QUIZ_PLAY'
export const TAG_CLICK_COMPUTE_RADIUS = 'TAG_CLICK_COMPUTE_RADIUS'
export const TAG_CLICK_HELP_BUTTON = 'TAG_CLICK_HELP_BUTTON'
export const TAG_CLICK_EVENT_MODE = 'TAG_CLICK_EVENT_MODE'

export const RI_CLIC_ITI = 'RI_CLIC_ITI'
export const RI_CLIC_ITI_MORE_RESULTS = 'RI_CLIC_ITI_MORE_RESULTS'
export const RI_AFFICHAGE_ITI = 'RI_AFFICHAGE_ITI'

export const SUGGEST_CLICK = 'SUGGEST_CLICK'
