import { CRITAIR_DEFAULT_ID } from '../../domain/travelOptions/travelOptions.constants'
import { FAVORITE_TYPES } from '../../domain/UserAccount/UserAccount.constants'
import { equalsIgnoreCase } from '../../utils/string'
import { getInitialState } from './userAccount.reducers'

export const formatFavoriteToService = fav => {
  const poiIDconversion = fav.poiId ? { poiID: fav.poiId } : {}
  return {
    ...fav,
    ...poiIDconversion,
    type: fav.type.toUpperCase()
  }
}

const convertPoiIDToPoiId = fav => {
  const poiIdconversion = fav.poiID ? { poiId: fav.poiID } : {}
  return {
    ...fav,
    ...poiIdconversion
  }
}

const addLabel = (homeLabel, workLabel) => fav => {
  const label = equalsIgnoreCase(fav.type, FAVORITE_TYPES.HOME)
    ? homeLabel
    : equalsIgnoreCase(fav.type, FAVORITE_TYPES.WORK)
    ? workLabel
    : fav.label
  return {
    ...fav,
    label
  }
}

const formatFavoriteFromService = (homeLabel, workLabel) => fav =>
  addLabel(homeLabel, workLabel)(convertPoiIDToPoiId(fav))

export const formatFavoritesFromService = (response = {}, homeLabel, workLabel) => {
  const data = response.data || []
  if (data && data.length) {
    return data.reduce((acc, favorite) => {
      const { type } = favorite
      if (equalsIgnoreCase(type, FAVORITE_TYPES.OTHER)) {
        return { ...acc, others: (acc.others || []).concat(convertPoiIDToPoiId(favorite)) }
      }
      return { ...acc, [type.toLowerCase()]: formatFavoriteFromService(homeLabel, workLabel)(favorite) }
    }, getInitialState().favorites)
  }
}

const isCritairValid = critairId => /^\d+$/.test(critairId)

const reduceObject = (result, [key, value]) => {
  if (typeof value === 'object') {
    return { ...result, [key]: Object.entries(value).reduce(reduceObject, {}) }
  }
  /*
   * We replace old critair value (from v1 accounts) by a default value
   * We should remove all that behavior when UBS-275 is resolved
   */
  if (key === 'critAirId' && !isCritairValid(value)) {
    return { ...result, [key]: CRITAIR_DEFAULT_ID }
  }

  return { ...result, [key]: value }
}

export const formatTravelOptionsFromService = data => {
  if (!data) return data
  return Object.entries(data).reduce(reduceObject, {})
}
