import AddressNotFoundError from '../../domain/error/AddressNotFoundError'
import { head, uniqBy } from '../../utils/array'
import { isEmpty } from '../../utils/lang'
import { ARRIVAL_LATER, DEPARTURE_NOW } from '../travelTimeOptions/travelTimeOptionsWhenTypes'
import {
  ADD_ROUTES,
  ADD_STEP,
  DROP_PROVIDER_ROUTES,
  INVERT_STEPS,
  MULTIPATH_COMPLETE,
  MULTIPATH_COMPUTE,
  MULTIPATH_COMPUTE_PROVIDER,
  MULTIPATH_RESET,
  MULTIPATH_RESET_EXCEPT_STEPS,
  MULTIPATH_RESET_ROUTES,
  REMOVE_PROVIDER_OPTIONAL_OR_SIMPLIFIED_STATUS,
  REMOVE_STEP,
  RESET_ACTIVE_SORT,
  ROUTE_API_UNKNOW_ERROR,
  ROUTE_NOT_FOUND_ERROR,
  SET_ACTIVE_SORT,
  SET_CURRENT_MODE,
  SET_CURRENT_PROVIDER,
  SET_CURRENT_ROUTE,
  SET_CURRENT_STEPS,
  SET_ITINERARY_AVAILABILITY_INFO,
  SET_PROVIDERS,
  SET_PROVIDERS_COLORS,
  SET_STEP_IDX,
  SET_STEP_LOCATION,
  SET_STEP_RESOLVED_LOCATION
} from './itinerary.actionTypes'

import { findAddressforGeolocation } from '../../dataSource/address/address.request'
import {
  requestInventory,
  requestLocation,
  requestRoute,
  requestTransports
} from '../../dataSource/itinerary/itinerary.requests'
import NoRouteError from '../../domain/error/NoRouteError'
import AdSearchExtension from '../../domain/itinerary/AdSearchExtension'
import {
  areStepsFilled,
  selectAllSteps,
  selectArrivalStep,
  selectBboxFromSteps,
  selectDepartureStep,
  selectStepsLength,
  selectStopSteps
} from '../../domain/itinerary/steps/steps.selectors'
import { PARAM_ARRIVAL_DATE_TIME, PARAM_DEPARTURE_DATE_TIME } from '../../domain/router/queryParameters.constants'
import { PREFERRED_VEHICLE_ID } from '../../domain/travelOptions/travelOptions.constants'
import { getCurrentDateISO } from '../../domain/utils/date'
import { isCoords, lngLatString } from '../../domain/utils/location'
import { ROUTE_ITINERARY_RESULTS_BY_PROVIDER, ROUTE_ITINERARY_RESULTS_BY_ROUTE } from '../../routes'
import { navigateTo } from '../history/history.actions'
import { selectLocale } from '../locale/locale.selectors'
import { requestMove } from '../map/map.actions'
import { selectGeolocationPosition, selectMapBbox } from '../map/map.selectors'
import { POI } from '../search/location.types'
import {
  selectPreferredOptions,
  selectPreferredSpeed,
  selectSkippedProviders
} from '../travelOptions/travelOptions.selectors'
import { setArrivalLater, setDate, setDepartureLater, setTime } from '../travelTimeOptions/travelTimeOptions.actions'
import { selectTravelTimeOptions } from '../travelTimeOptions/travelTimeOptions.selectors'
import { SET_STEP_FROM } from './itinerary.constants'
import { decorateRouteWithId, extractColors } from './itinerary.dataParser'
import {
  getProvider,
  getProvidersByMode,
  selectCurrentMode,
  selectCurrentProvider,
  selectProviderByName
} from './itinerary.selectors'
import { selectCurrentPolylineBbox } from './polylines.selectors'
import { selectSortedRoutes } from './routes.selectors'

export const reset = () => dispatch => {
  dispatch({
    type: MULTIPATH_RESET
  })
}

export const resetExceptSteps = () => dispatch => {
  dispatch({
    type: MULTIPATH_RESET_EXCEPT_STEPS
  })
}

export const resetRoutes = () => dispatch => {
  dispatch({
    type: MULTIPATH_RESET_ROUTES
  })
}

export const setStepIdx =
  ({ idx }) =>
  dispatch => {
    dispatch({
      type: SET_STEP_IDX,
      payload: { idx }
    })
  }

export const setStepFromSuggestion =
  ({ suggest }) =>
  dispatch => {
    const { label, split_label, lng, lat, from, type } = suggest
    const action = {
      type: SET_STEP_LOCATION,
      payload: {
        data: {
          addresses: [
            {
              label,
              split_label,
              coordinates: { lng, lat },
              from,
              type
            }
          ]
        },
        options: { from: SET_STEP_FROM.searchForm }
      }
    }
    dispatch(action)
  }

export const setStepFromAddress =
  (address, type = SET_STEP_LOCATION, options = { from: SET_STEP_FROM.searchForm }) =>
  dispatch => {
    dispatch({
      type,
      payload: {
        data: {
          addresses: [address]
        },
        options
      }
    })
  }

export const setStepFromPoint = (lngLat, idx) => (dispatch, getState) => {
  const locale = selectLocale(getState())
  return findAddressforGeolocation({ ...lngLat, locale })
    .then(({ addresses }) => {
      if (addresses.length < 1) return Promise.reject(new Error('No address found at this point !'))
      return addresses[0]
    })
    .then(address => {
      setStepIdx({ idx })(dispatch, getState)
      setStepFromAddress(address, SET_STEP_LOCATION, { from: SET_STEP_FROM.mapAction })(dispatch, getState)
    })
}

export const setDepartureFromPoint = lngLat => (dispatch, getState) => {
  return setStepFromPoint(lngLat, 0)(dispatch, getState)
}

export const setArrivalFromPoint = lngLat => (dispatch, getState) => {
  const stepsLength = selectStepsLength(getState())
  return setStepFromPoint(lngLat, stepsLength - 1)(dispatch, getState)
}

export const setStepFromParams =
  (steps, options = {}) =>
  (dispatch, getState) => {
    if (!areStepsFilled(getState()) || options.force) {
      dispatch({
        type: SET_CURRENT_STEPS,
        payload: { steps }
      })
    }
  }

const REGEX_DATETIME = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/

const checkDateTimeQueryParams = dateTimeQueryParam => {
  if (REGEX_DATETIME.test(dateTimeQueryParam)) {
    const { date } = splitDateTimeFromQueryParams(dateTimeQueryParam)
    const inputDate = new Date(date)
    const now = new Date(getCurrentDateISO())
    return inputDate >= now
  }
  return false
}

const splitDateTimeFromQueryParams = dateTimeQueryParam => {
  const [date, time] = dateTimeQueryParam.split('T')
  return { date, time }
}
const setDateTimeFromQueryParams = dateTimeQueryParam => dispatch => {
  const { date, time } = splitDateTimeFromQueryParams(dateTimeQueryParam)
  setDate(date)(dispatch)
  setTime(time)(dispatch)
}

export const setTravelTimeOptionsFromQueryParams = query => (dispatch, getState) => {
  if (query.has(PARAM_ARRIVAL_DATE_TIME)) {
    const datetimeParam = query.get(PARAM_ARRIVAL_DATE_TIME)
    if (checkDateTimeQueryParams(datetimeParam)) {
      setArrivalLater()(dispatch)
      setDateTimeFromQueryParams(datetimeParam)(dispatch)
    }
  } else if (query.has(PARAM_DEPARTURE_DATE_TIME)) {
    const datetimeParam = query.get(PARAM_DEPARTURE_DATE_TIME)
    if (checkDateTimeQueryParams(datetimeParam)) {
      setDepartureLater()(dispatch)
      setDateTimeFromQueryParams(datetimeParam)(dispatch)
    }
  }
}

export const addStep = () => (dispatch, getState) => {
  dispatch({
    type: ADD_STEP
  })
}

export const removeStep = stepId => (dispatch, getState) => {
  dispatch({
    type: REMOVE_STEP,
    payload: stepId
  })
}

export const invertSteps = () => (dispatch, getState) => {
  dispatch({
    type: INVERT_STEPS
  })
}

export const setRouteFromParams =
  ({ mode, provider, routeId }) =>
  (dispatch, getState) => {
    const {
      itinerary: { currentRouteId, routes }
    } = getState()

    if (mode) setCurrentMode(mode)(dispatch, getState)
    if (provider) setCurrentProvider(provider)(dispatch, getState)

    if (routeId && routeId !== currentRouteId) {
      const typedRouteId = parseInt(routeId, 10)
      const routeWanted = routes.find(route => route?.routeId === typedRouteId)
      if (routeWanted) {
        setCurrentRoute(typedRouteId)(dispatch, getState)
      }
    }
  }

export const findLocation = (location, idx) => (dispatch, getState) => {
  if (isCoords(location.coordinates) && location.type !== POI && location.countryCode) {
    return Promise.resolve(location)
  } else {
    const locale = selectLocale(getState())
    const bbox = selectMapBbox(getState())
    return requestLocation(location, bbox, locale).then(location => {
      if (location) {
        setStepIdx({ idx })(dispatch, getState)
        return setStepFromAddress(location, SET_STEP_RESOLVED_LOCATION)(dispatch, getState)
      }

      return Promise.reject(new AddressNotFoundError('No '))
    })
  }
}

const excludeEmptyStep = step => step.label

const getStepParameters = store =>
  selectStopSteps(store)
    .filter(excludeEmptyStep)
    .map(({ coordinates }) => lngLatString(coordinates))

export const findTransports = () => (dispatch, getState) => {
  const state = getState()
  const locale = selectLocale(state)
  const preferredOptions = selectPreferredOptions(state)
  const [from, to] = [selectDepartureStep(state), selectArrivalStep(state)]
  return requestTransports({
    from,
    to,
    options: {
      stop: getStepParameters(state),
      vehicle: preferredOptions?.car?.[PREFERRED_VEHICLE_ID] ?? undefined,
      preferred_tm: preferredOptions?.favoriteMode ?? undefined,
      skipped_providers: selectSkippedProviders(state).join(',')
    },
    locale
  })
}

export const findRoute = provider => (dispatch, getState) => {
  const state = getState()
  const travelTimeOptions = selectTravelTimeOptions(state)
  const locale = selectLocale(state)
  const { name } = provider

  return requestRoute({
    provider,
    from: selectDepartureStep(state),
    to: selectArrivalStep(state),
    stop: getStepParameters(state),
    dateTimeOptions: getDateTimeOptions(travelTimeOptions),
    ModePreferredOptions: getModePreferredOptions(name, state),
    currentMode: selectCurrentMode(state),
    locale
  })
    .then(routes => {
      if (routes?.availability_info) {
        dispatch({ type: SET_ITINERARY_AVAILABILITY_INFO, payload: routes.availability_info })
        return []
      }

      const { label } = provider
      if ((routes || []).length === 0) {
        throw new NoRouteError(`Il n'y a aucun itinéraire pour le mode de transport ${label}`)
      }

      dispatch({
        type: ADD_ROUTES,
        payload: {
          routes
        }
      })
      return routes
    })
    .catch(err => {
      const error =
        err?.name !== 'NoRouteError'
          ? new NoRouteError('Aucun résultat pour votre itinéraire avec le mode de transport sélectionné')
          : err

      const { name, mode, label } = provider
      dispatch({
        type: ROUTE_NOT_FOUND_ERROR,
        payload: {
          provider: name,
          mode: {
            id: mode,
            label
          },
          error
        }
      })
      throw err
    })
}

const dropPreviousProviderRoutes = providerName => dispatch =>
  dispatch({
    type: DROP_PROVIDER_ROUTES,
    payload: { providerName }
  })

const addOptionalRoutes = (dispatch, getState) => {
  const {
    itinerary: { providers = [] }
  } = getState()

  const currentMode = selectCurrentMode(getState())

  const routes = uniqBy(providers, ({ mode }) => mode)
    .filter(({ optional, mode }) => optional && mode !== currentMode)
    .map(({ name, icon, mode }) =>
      decorateRouteWithId({
        provider: {
          id: name
        },
        icon,
        mode,
        sections: [],
        isFake: true,
        isOptional: true
      })
    )
  if (routes.length > 0) {
    dispatch({
      type: ADD_ROUTES,
      payload: {
        routes
      }
    })
  }
}

const removeProviderSimpliedOrOptionalStatus = providerName => dispatch =>
  dispatch({
    type: REMOVE_PROVIDER_OPTIONAL_OR_SIMPLIFIED_STATUS,
    payload: {
      providerName
    }
  })

export const computeProvider = providerName => (dispatch, getState) => {
  removeProviderSimpliedOrOptionalStatus(providerName)(dispatch)
  dropPreviousProviderRoutes(providerName)(dispatch)

  const provider = selectProviderByName(providerName)(getState)

  dispatch({
    type: MULTIPATH_COMPUTE_PROVIDER
  })

  return findRoute(provider)(dispatch, getState).catch(error => console.warn('computeProvider/findRoute', error))
}

export const setRouteIdForCurrentProviderOrMode = () => (dispatch, getState) => {
  const routes = selectSortedRoutes(getState())
  const currentProvider = selectCurrentProvider(getState())
  const firstRouteForProvider = routes.find(({ provider: { id } }) => id === currentProvider)
  if (firstRouteForProvider) {
    navigateToItineraryRoute({
      routeId: firstRouteForProvider.routeId,
      provider: firstRouteForProvider.provider.id
    })(dispatch, getState)
    return
  }
  const currentMode = selectCurrentMode(getState())
  const firstRouteForMode = routes.find(({ mode }) => mode === currentMode)
  if (firstRouteForMode) {
    navigateToItineraryRoute({
      routeId: firstRouteForMode.routeId,
      provider: firstRouteForMode.provider.id
    })(dispatch, getState)
  }
}

export const getDesiredProvider = ({ currentProvider, currentMode, providers }) => {
  const provider = getProvider(providers, currentProvider)
  if (provider) return provider
  if (currentMode) {
    const providersForMode = getProvidersByMode(providers, currentMode)
    if (!isEmpty(providersForMode)) return providersForMode?.[0]
  }
  return providers?.[0]
}

export const removeSimplifiedAndOptionalForProvidersInCurrentMode = (
  providers,
  currentMode = false,
  currentProvider = false
) => {
  return providers.map(provider => {
    const { mode, simplified, optional, name } = provider
    const force = mode === currentMode || name === currentProvider
    return {
      ...provider,
      simplified: force ? false : simplified,
      optional: force ? false : optional
    }
  })
}

export const filterSteps = steps => {
  return steps.map((step, idx) => ({ ...step, idx })).filter(excludeEmptyStep)
}

export const compute = () => (dispatch, getState) => {
  dispatch({
    type: MULTIPATH_COMPUTE
  })

  const steps = selectAllSteps(getState())
  const currentProvider = selectCurrentProvider(getState())
  const currentMode = selectCurrentMode(getState())

  return Promise.all(filterSteps(steps).map(step => findLocation(step, step.idx)(dispatch, getState)))
    .then(() => AdSearchExtension.search({ steps: selectAllSteps(getState()) }))
    .then(() => {
      fetchProvidersColors()(dispatch, getState)
      return findTransports()(dispatch, getState)
    })
    .then(rawProviders => {
      const currentProviderMode = rawProviders.find(({ name }) => name === currentProvider)?.mode
      const providers = removeSimplifiedAndOptionalForProvidersInCurrentMode(
        rawProviders,
        currentMode || currentProviderMode,
        currentProvider
      )
      dispatch({
        type: SET_PROVIDERS,
        payload: providers
      })

      const desiredProvider = getDesiredProvider({ currentProvider, currentMode, providers })
      setCurrentProvider(desiredProvider.name)(dispatch, getState)

      const desiredProviderRoutesPromise = findRoute(desiredProvider)(dispatch, getState).catch(error =>
        console.warn('compute/findRoute desiredProvider', error)
      )
      const otherProvidersRoutesPromises = providers
        .filter(p => p.name !== desiredProvider.name)
        .filter(p => p.optional === false)
        .map(provider =>
          findRoute(provider)(dispatch, getState).catch(error => console.warn('compute/findRoute otherProvider', error))
        )

      Promise.all([desiredProviderRoutesPromise, ...otherProvidersRoutesPromises]).then(() => {
        addOptionalRoutes(dispatch, getState)
        dispatch({
          type: MULTIPATH_COMPLETE
        })
      })

      return desiredProviderRoutesPromise
    })
    .then(routes => {
      setCurrentRoute(routes?.[0]?.routeId)(dispatch, getState)
    })
    .catch(error =>
      dispatch({
        type: ROUTE_API_UNKNOW_ERROR,
        payload: {
          error
        }
      })
    )
}

export const computeETAFromGeoloc = toPosition => (dispatch, getState) => {
  const geolocationPosition = selectGeolocationPosition(getState())
  if (!geolocationPosition || !toPosition) return Promise.resolve()
  const from = { coordinates: geolocationPosition }
  const to = { coordinates: toPosition }
  const locale = selectLocale(getState())

  return requestTransports({ locale, from, to })
    .then(providers => {
      if (!providers?.length || providers.length === 0) throw new Error('missing provider')
      return requestRoute({ provider: providers[0], from, to, locale })
    })
    .then(routes => {
      if (Array.isArray(routes)) return head(routes)
    })
    .catch(e => {
      console.error('itinerary.actions', e) // fail silently
    })
}

export const setCurrentProvider = provider => (dispatch, getState) => {
  const {
    itinerary: { currentProvider }
  } = getState()

  if (provider && provider !== currentProvider) {
    dispatch({
      type: SET_CURRENT_PROVIDER,
      payload: { provider }
    })
  }
}

export const setCurrentRoute = routeId => (dispatch, getState) => {
  const {
    itinerary: { currentRouteId }
  } = getState()

  if (currentRouteId !== routeId) {
    return dispatch({
      type: SET_CURRENT_ROUTE,
      payload: { routeId }
    })
  }
}

export const setCurrentMode = mode => dispatch =>
  dispatch({
    type: SET_CURRENT_MODE,
    payload: mode
  })

const getDateTimeOptions = travelTimeOptions => {
  if (!travelTimeOptions) return {}
  const { when, date, time } = travelTimeOptions
  const datetime = when !== DEPARTURE_NOW && date && time ? `${formatDate(date)}T${formatTime(time)}` : undefined
  return {
    departure: when !== ARRIVAL_LATER,
    datetime
  }
}

const getModePreferredOptions = (name, storeState) => {
  const { travelOptions } = storeState
  switch (name) {
    case 'car':
    case 'motorbike': {
      const { vehicleId, fuelTypeId, fuelPrice, fuelConsumption } = travelOptions?.preferredOptions?.[name] ?? {}
      const prefix = name === 'motorbike' ? `${name}_` : ''
      return {
        [`${prefix}vehicle`]: vehicleId,
        [`${prefix}fuel`]: fuelTypeId,
        gas_cost: fuelPrice,
        [`${name}_consumption`]: fuelConsumption
      }
    }
    case 'bss':
      return {
        bike_speed: selectPreferredSpeed('bike')(storeState),
        walk_speed: selectPreferredSpeed('walk')(storeState)
      }
    case 'walk':
    case 'tc':
      return {
        walk_speed: selectPreferredSpeed('walk')(storeState)
      }
    case 'bike':
      return {
        bike_speed: selectPreferredSpeed('bike')(storeState)
      }
    case 'trottinette':
      return {
        trottinette_speed: selectPreferredSpeed('trottinette')(storeState)
      }
  }
  return {}
}

export const fetchProvidersColors = () => (dispatch, getState) => {
  const locale = selectLocale(getState())

  return requestInventory(locale).then(data => {
    dispatch({
      type: SET_PROVIDERS_COLORS,
      payload: extractColors(data)
    })
  })
}

const formatDate = date => date.replace(/-/g, '')
const formatTime = time => time.replace(':', '')

export const mapFitCurrentSteps = () => (dispatch, getState) => {
  const bbox = selectBboxFromSteps(getState())
  if (bbox) {
    requestMove({ bbox })(dispatch, getState)
  }
}

export const mapFitCurrentPolyline = () => (dispatch, getState) => {
  const bbox = selectCurrentPolylineBbox(getState())
  if (bbox) {
    requestMove({ bbox })(dispatch, getState)
  }
}

export const setActiveSort = sortType => dispatch => {
  dispatch({
    type: SET_ACTIVE_SORT,
    payload: sortType
  })
}

export const resetActiveSort = () => dispatch => {
  dispatch({
    type: RESET_ACTIVE_SORT
  })
}

export const navigateToItineraryRoute =
  ({ routeId, provider }, routeOptions = {}) =>
  (dispatch, getState) => {
    navigateTo({
      route: ROUTE_ITINERARY_RESULTS_BY_ROUTE,
      routeOptions: {
        avoidRefetchingPageData: true,
        forceNotReplace: true,
        ...routeOptions
      },
      selectedStoreState: {
        routeId,
        provider
      }
    })(dispatch, getState)
  }

export const navigateToFirstSortedItineraryRoute = () => (dispatch, getState) => {
  const sortedRoutes = selectSortedRoutes(getState())
  if (sortedRoutes.length === 0) return
  const { routeId, provider } = sortedRoutes[0]
  navigateToItineraryRoute({ routeId, provider: provider.id })(dispatch, getState)
}

export const navigateToProvider =
  ({ provider, mode }, routeOptions = {}) =>
  (dispatch, getState) => {
    navigateTo({
      route: ROUTE_ITINERARY_RESULTS_BY_PROVIDER,
      routeOptions: {
        avoidRefetchingPageData: true,
        forceNotReplace: true,
        ...routeOptions
      },
      selectedStoreState: {
        provider,
        mode
      }
    })(dispatch, getState)
  }
