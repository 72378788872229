import { oncePerHour } from '../../domain/utils/oncePerTime'
import request from '../../domain/utils/request'
import { getService, getSvgIconUrl } from '../../parameters'
import SERVICES from '../../services.constants'

export const requestPoiassetsConfig = oncePerHour(() => {
  const FR = 'fr_FR'
  return request.get(getService(SERVICES.POIASSETS, `/configuration/configuration.${FR}.json`))
})

export const requestSvgIcon = (timestamp, icon) => {
  const url = getSvgIconUrl(timestamp, icon)
  return request.get(url)
}

export const requestSvgUrl = url => request.get(url)

export const requestGeoentitiesAtAddressConfig = oncePerHour(() =>
  request.get(getService(SERVICES.POIASSETS, '/configuration/quickaccess.web.json'))
)

export const requestPoisOnRouteConfig = oncePerHour(() =>
  request.get(getService(SERVICES.POIASSETS, '/configuration/surmaroute.web.json'))
)
