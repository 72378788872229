import { LOAD_SINGLE_ADDRESS_COMPUTE_RADIUS_SUCCESS } from '../address/address.actionTypes'
import { setComputeRadiusPosition } from './tools.actions'
import { MAP_SET_STATUS, SET_GEOLOC_POSITION } from '../map/map.actionTypes'
import { selectComputePosition } from './tools.selectors'
import { selectCurrentHistoryRoute, selectPreviousHistoryRoute } from '../history/history.selectors'
import { ROUTE_SUGGEST, ROUTE_TOOLS_COMPUTE_RADIUS } from '../../routes'
import { selectGeolocationPosition, selectGeolocationStatus, selectIsMapReady } from '../map/map.selectors'
import { hideUIElement, setCurrentSearch } from '../ui/ui.actions'
import { GEOLOC_STATUS_ACTIVE, GEOLOC_STATUS_FOLLOW } from '../map/map.constants'
import { selectFirstAddress } from '../address/address.selectors'
import { updateCurrentSearchFromLngLat } from '../address/address.computeRadius.actions'
import { SET_COMPUTE_RADIUS_POSITION } from './tools.actionTypes'
import { requestMove, setGeolocationStatus } from '../map/map.actions'
import { HISTORY_PUSH } from '../history/history.actionTypes'
import { UI_ELEMENTS } from '../ui/ui.constants'
import { dispatchTag } from '../analytics/analytics.actions'
import { TAG_CLICK_COMPUTE_RADIUS } from '../analytics/analytics.actionTypes'
import { navigateBack } from '../history/history.actions'
import { isEmpty } from '../../utils/lang'

export const setGeoLocPositionInComputeRadiusPositionMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    switch (action.type) {
      case LOAD_SINGLE_ADDRESS_COMPUTE_RADIUS_SUCCESS:
        if (
          !isEmpty(selectComputePosition(getState())) &&
          selectGeolocationStatus(getState()) === GEOLOC_STATUS_FOLLOW
        ) {
          setGeolocationStatus(GEOLOC_STATUS_ACTIVE)(dispatch, getState)
        }
        const addresses = action.payload?.data?.addresses
        const coordinates = addresses[0]?.coordinates
        setComputeRadiusPosition(coordinates)(dispatch, getState)
        requestMove({ center: coordinates, options: { zoom: 10 } })(dispatch, getState)
        break
    }

    return next(action)
  }

export const moveToGeolocPositionIfComputeRadiusPageMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    switch (action.type) {
      case SET_GEOLOC_POSITION:
      case MAP_SET_STATUS:
      case HISTORY_PUSH:
        const store = getState()
        const currentRoute = selectCurrentHistoryRoute(store)
        if (currentRoute !== ROUTE_TOOLS_COMPUTE_RADIUS || selectComputePosition(store)) break
        const geoLoc = selectGeolocationPosition(store)
        if (selectIsMapReady(store) && geoLoc) {
          updateCurrentSearchFromLngLat(geoLoc)(dispatch, getState)
        }
        break
    }
    return r
  }

export const handleActionWhenLoadSingleAddressComputeRadiusMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    switch (action.type) {
      case LOAD_SINGLE_ADDRESS_COMPUTE_RADIUS_SUCCESS:
        hideUIElement(UI_ELEMENTS.SEARCH_DIALOG)(dispatch, getState)
        const { label } = selectFirstAddress(getState())
        setCurrentSearch(label)(dispatch, getState)
        dispatchTag({
          type: TAG_CLICK_COMPUTE_RADIUS,
          tagInfo: {
            action: 'Adresse'
          }
        })(dispatch, getState)
        break
    }

    return r
  }

export const handleBackFromSuggestToComputeRadius =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    if (
      action.type === LOAD_SINGLE_ADDRESS_COMPUTE_RADIUS_SUCCESS &&
      selectCurrentHistoryRoute(getState()) === ROUTE_SUGGEST &&
      selectPreviousHistoryRoute(getState()) === ROUTE_TOOLS_COMPUTE_RADIUS
    ) {
      navigateBack()(dispatch, getState)
    }

    return r
  }

export const launchGeocodeReverseOnMarkerDrag =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)
    const { type, payload } = action
    if (type === SET_COMPUTE_RADIUS_POSITION) {
      const { position, fromMarker } = payload
      if (fromMarker) {
        updateCurrentSearchFromLngLat(position)(dispatch, getState)
      }
    }
    return r
  }

export default [
  setGeoLocPositionInComputeRadiusPositionMiddleware,
  moveToGeolocPositionIfComputeRadiusPageMiddleware,
  handleActionWhenLoadSingleAddressComputeRadiusMiddleware,
  handleBackFromSuggestToComputeRadius,
  launchGeocodeReverseOnMarkerDrag
]
