import {
  SET_POIASSETS_CONFIG,
  SET_GEOENTITIES_AT_ADDRESS_CONFIG,
  SET_POIS_ON_ROUTE_CONFIG,
  SET_POIASSETS_STATUS
} from './poiassets.actionTypes'
import { pick } from '../../utils/object'
import { POIASSETS_STATUS } from './poiassets.constants'

export const getInitialState = () => {
  return { status: POIASSETS_STATUS.INITIAL }
}

export default function poiassetsReducers(state = getInitialState(), action) {
  switch (action && action.type) {
    case SET_POIASSETS_STATUS: {
      const { status } = action.payload
      return { ...state, status }
    }
    case SET_POIASSETS_CONFIG: {
      return {
        ...state,
        status: POIASSETS_STATUS.DONE,
        ...pick(action.payload, ['menus', 'apps', 'freeapps', 'categories', 'subcategories'])
      }
    }
    case SET_GEOENTITIES_AT_ADDRESS_CONFIG: {
      return {
        ...state,
        geoentitiesAtAddressConfig: action.payload
      }
    }
    case SET_POIS_ON_ROUTE_CONFIG: {
      return {
        ...state,
        poisOnRouteConfig: action.payload
      }
    }
  }
  return state
}
