import {
  requestFuels,
  requestGeoentitiesForApp,
  requestGeoentity,
  requestGeoentityTab
} from '../../dataSource/geoentity/geoentity.requests'
import {
  ADD_GEOENTITY_LIST,
  FOCUS_GEOENTITY,
  GEOENTITY_NOT_FOUND,
  POPULATE_GEOENTITY_TAB_CONTENT,
  REFRESH_SELECTED_GEOENTITY_DATA,
  REQUEST_UNFOCUS_GEOENTITY,
  REQUEST_UNSELECT_GEOENTITY,
  RESET_GEOENTITY_FILTER_PARAMETER,
  RESET_GEOENTITY_LIST,
  SELECT_GEOENTITY,
  SET_FUEL_INFORMATION,
  SET_GEOENTITIES_RECEIVED,
  SET_GEOENTITIES_REQUESTED,
  SET_GEOENTITY_FILTER_PARAMETER,
  SET_GEOENTITY_LIST,
  SET_GEOENTITY_TAB_BLOCKS_IDX,
  SET_GEOENTITY_TAB_ID,
  SET_SINGLE_GEOENTITY,
  UNFOCUS_GEOENTITY,
  UNSELECT_GEOENTITY
} from './geoentity.actionTypes'
import { parseGeoentityFilters, parseGeoentityListResponse, parseGeoentityResponse } from './geoentity.dataParser'
import {
  selectAllGeoentities,
  selectCurrentFuelFilterInfo,
  selectCurrentGeoentityId,
  selectFuelInformation,
  selectGeoentityComputedBbox,
  selectGeoentityRubricId,
  selectHasGeoentities,
  selectHasVdeGeoentities,
  selectSelectedGeoentityContextualPoiUrl,
  selectSelectedGeoentityCurrentTab
} from './geoentity.selectors'

import requestSearch from '../../dataSource/search/search.requests'
import { SPD_DETAIL_GEOENTITY_LIST } from '../../domain/analytics/BiTagger'
import GeoentityNotFoundError from '../../domain/error/GeoentityNotFoundError'
import { selectLocale } from '../locale/locale.selectors'
import { selectMapActiveBbox, selectMapBbox } from '../map/map.selectors'
import { selectSearchCategoryParameter } from '../search/search.selectors'
import { searchServiceCtx } from '../search/search.service.constants'

const dispatchGeoentitiesReceived = dispatch => () =>
  dispatch({
    type: SET_GEOENTITIES_RECEIVED
  })

export const searchGeoentities =
  ({
    appOrRubricIds,
    filterParams,
    max,
    biTagId,
    bbox,
    vde,
    extendBbox,
    setGeoentityListActionType = SET_GEOENTITY_LIST,
    noRequestedDispatch
  }) =>
  (dispatch, getState) => {
    if (!bbox) bbox = selectMapActiveBbox(getState())

    if (!noRequestedDispatch)
      dispatch({
        type: SET_GEOENTITIES_REQUESTED
      })

    const fuels = selectFuelInformation(getState())
    const currentFuelInformation = selectCurrentFuelFilterInfo(getState())
    const locale = selectLocale(getState())

    return requestGeoentitiesForApp({ appOrRubricIds, filterParams, max, biTagId, bbox, extendBbox, locale })
      .then(response => {
        const data = response?.data
        if (data) {
          const category = selectSearchCategoryParameter(getState())
          dispatch({
            type: setGeoentityListActionType,
            payload: {
              pois: parseGeoentityListResponse(data, vde),
              viewport: data.viewport,
              filters: parseGeoentityFilters(data, category, fuels, currentFuelInformation),
              extendBbox
            }
          })
        }
      })
      .catch(e => {
        dispatch({
          type: RESET_GEOENTITY_LIST
        })
      })
      .finally(dispatchGeoentitiesReceived(dispatch))
  }

export const loadFuelInformation = () => (dispatch, getState) => {
  try {
    return requestFuels().then(response => {
      const data = response?.data
      if (data) setFuelInformation(data.fuels)(dispatch, getState)
    })
  } catch (e) {
    console.error('Failed to load fuel intormation : ', e)
    return Promise.resolve()
  }
}

export const searchGeoentitiesAround =
  ({ biTagId }) =>
  (dispatch, getState) => {
    const store = getState()
    const appOrRubricIds = selectGeoentityRubricId(store)
    const bbox = selectGeoentityComputedBbox(store)
    const locale = selectLocale(getState())

    return requestGeoentitiesForApp({ appOrRubricIds, bbox, biTagId, locale }).then(response => {
      if (response.data) {
        addGeoentitiesFromServiceResponse(response.data)(dispatch, getState)
      }
    })
  }

export const searchGeoentitiesAtAddress = terms => (dispatch, getState) => {
  dispatch({
    type: SET_GEOENTITIES_REQUESTED
  })

  const state = getState()
  const bbox = selectMapBbox(state)
  const locale = selectLocale(state)

  return requestSearch({
    terms,
    searchFilterType: searchServiceCtx.filter.poi,
    locale,
    bbox
  })
    .then(({ pois = [], viewport }) => {
      if (pois.length > 0) {
        dispatch({
          type: SET_GEOENTITY_LIST,
          payload: {
            pois,
            viewport
          }
        })
      }
    })
    .finally(dispatchGeoentitiesReceived(dispatch))
}

export const searchSingleGeoentity = poiId => (dispatch, getState) => {
  dispatch({
    type: SET_GEOENTITIES_REQUESTED
  })

  const contextualUrl = selectSelectedGeoentityContextualPoiUrl(getState(), { id: poiId })
  const locale = selectLocale(getState())

  return requestGeoentity({
    id: poiId,
    relativeUrl: contextualUrl,
    locale
  })
    .then(data =>
      dispatch({
        type: SET_SINGLE_GEOENTITY,
        payload: parseGeoentityResponse(data)
      })
    )
    .finally(dispatchGeoentitiesReceived(dispatch))
}

export const requestGeoentitiesForBI = id => (dispatch, getState) => {
  const store = getState()
  const appOrRubricIds = selectGeoentityRubricId(store)
  const bbox = selectGeoentityComputedBbox(store)
  const tabUrl = selectSelectedGeoentityCurrentTab(getState())?.url
  const locale = selectLocale(getState())

  return Promise.all([
    requestGeoentity({ id, locale }),
    tabUrl ? requestGeoentityTab(tabUrl, locale) : Promise.resolve(),
    requestGeoentitiesForApp({ appOrRubricIds, bbox, biTagId: SPD_DETAIL_GEOENTITY_LIST, locale })
  ])
}

export const addGeoentitiesFromServiceResponse = data => dispatch => {
  dispatch({
    type: ADD_GEOENTITY_LIST,
    payload: { pois: parseGeoentityListResponse(data) }
  })
}

export const resetGeoentities = () => (dispatch, getState) => {
  if (selectHasGeoentities(getState()) || selectHasVdeGeoentities(getState())) {
    dispatch({
      type: RESET_GEOENTITY_LIST
    })
  }
}

export const resetVdeGeoentities = () => (dispatch, getState) => {
  if (selectHasVdeGeoentities(getState())) {
    dispatch({
      type: RESET_GEOENTITY_LIST,
      payload: { filter: { vde: true } }
    })
  }
}

export const selectGeoentity = currentPoiId => (dispatch, getState) => {
  const pois = selectAllGeoentities(getState())
  const idx = pois.findIndex(({ id }) => id === currentPoiId)
  if (idx === -1) throw new GeoentityNotFoundError()
  dispatch({
    type: SELECT_GEOENTITY,
    payload: { currentPoiId }
  })
}

export const refreshAndPopulateCurrentGeoentity = () => (dispatch, getState) =>
  requestGeoentity({
    id: selectCurrentGeoentityId(getState()),
    locale: selectLocale(getState())
  })
    .then(data =>
      dispatch({
        type: REFRESH_SELECTED_GEOENTITY_DATA,
        payload: parseGeoentityResponse(data)
      })
    )
    .then(() => populateCurrentGeoentityTabContent()(dispatch, getState))

export const populateCurrentGeoentityTabContent = () => (dispatch, getState) => {
  const tabRelativeUrl = selectSelectedGeoentityCurrentTab(getState())?.url
  const locale = selectLocale(getState())
  if (!tabRelativeUrl) return

  return requestGeoentityTab(tabRelativeUrl, locale).then(data =>
    dispatch({
      type: POPULATE_GEOENTITY_TAB_CONTENT,
      payload: data.tabs
    })
  )
}
export const loadGeoentityTabForAppId = appId => (dispatch, getState) => {
  dispatch({
    type: SET_GEOENTITY_TAB_ID,
    payload: appId
  })
  return populateCurrentGeoentityTabContent()(dispatch, getState)
}

export const unselectGeoentity = () => dispatch => {
  dispatch({
    type: UNSELECT_GEOENTITY
  })
}

export const requestUnselectGeoentity = currentPoiId => dispatch => {
  dispatch({
    type: REQUEST_UNSELECT_GEOENTITY,
    payload: { currentPoiId }
  })
}

export const setGeoentityTabBlocksIdx = idx => dispatch => {
  dispatch({
    type: SET_GEOENTITY_TAB_BLOCKS_IDX,
    payload: idx
  })
}

export const focusGeoentity = focusedPoiId => (dispatch, getState) => {
  const pois = selectAllGeoentities(getState())
  const idx = pois.findIndex(({ id }) => id === focusedPoiId)
  if (idx === -1) throw new GeoentityNotFoundError()
  dispatch({
    type: FOCUS_GEOENTITY,
    payload: { focusedPoiId }
  })
}

export const unfocusGeoentity = () => dispatch => {
  dispatch({
    type: UNFOCUS_GEOENTITY
  })
}

export const requestUnfocusGeoentity = focusedPoiId => dispatch => {
  dispatch({
    type: REQUEST_UNFOCUS_GEOENTITY,
    payload: { focusedPoiId }
  })
}

export const setGeoentityFilterParameter = value => dispatch => {
  dispatch({ type: SET_GEOENTITY_FILTER_PARAMETER, payload: value })
}

export const resetGeoentityFilterParameters = () => dispatch =>
  dispatch({
    type: RESET_GEOENTITY_FILTER_PARAMETER
  })

export const setGeoentityNotFound = () => dispatch =>
  dispatch({
    type: GEOENTITY_NOT_FOUND
  })

export const setFuelInformation = fuels => dispatch =>
  dispatch({
    type: SET_FUEL_INFORMATION,
    payload: fuels
  })
