import { HEADER_DEFAULT_HEIGHT_GE, HEADER_DEFAULT_HEIGHT_PE } from '../../domain/header/header-constants'
import { LOAD_MULTI_ADDRESS_SUCCESS } from '../address/address.actionTypes'
import { HISTORY_PUSH, HISTORY_SAVE_POST_POP, REVERSE_RESOLVE_ROUTE_AND_REGISTER } from '../history/history.actionTypes'
import {
  selectCurrentHistoryRoute,
  selectIsCurrentHistoryRoutePageless,
  isItineraryRoute,
  isASearchOnItineraryPage
} from '../history/history.selectors'
import { ADD_STEP, MULTIPATH_RESET, REMOVE_STEP, SET_STEP_LOCATION } from '../itinerary/itinerary.actionTypes'
import {
  hideUIElement,
  setItineraryResultsUserAccountIncentiveDisplayed,
  setUserAccountIncentivePopinDisplayed
} from './ui.actions'
import {
  DISPLAY_MODAL,
  HIDE_UI_ELEMENT,
  SET_HEADER_HEIGHT,
  SHOW_UI_ELEMENT,
  TOGGLEOFF_ONBOARDING_CONTEXT
} from './ui.actionTypes'
import { selectIsOnboardingContext } from './ui.selectors'
import { MODAL_IDS, UI_ELEMENTS } from './ui.constants'
import { isPE } from '../../domain/Device'

const HEADER_DEFAULT_HEIGHT = isPE() ? HEADER_DEFAULT_HEIGHT_PE : HEADER_DEFAULT_HEIGHT_GE

export const onChangingRouteToggleoffOnboardingContext =
  ({ dispatch, getState }) =>
  next =>
  action => {
    if (
      selectIsOnboardingContext(getState()) &&
      action.type === HISTORY_PUSH &&
      action.origType !== REVERSE_RESOLVE_ROUTE_AND_REGISTER
    ) {
      dispatch({
        type: TOGGLEOFF_ONBOARDING_CONTEXT
      })
    }

    return next(action)
  }
const getItineraryHeaderHeight = getState => {
  const {
    itinerary: { steps }
  } = getState()
  return 48 * steps.length + (isPE() ? 15 : 0)
}

export const headerHeightMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)
    let height = null

    switch (action.type) {
      case HISTORY_PUSH:
      case HISTORY_SAVE_POST_POP: {
        const route = action?.payload?.route
        if (!selectIsCurrentHistoryRoutePageless(getState())) {
          if (isItineraryRoute(route) && !isASearchOnItineraryPage(route)) {
            height = getItineraryHeaderHeight(getState)
          } else {
            height = HEADER_DEFAULT_HEIGHT
          }
        }
        break
      }
      case ADD_STEP:
      case REMOVE_STEP:
      case SET_STEP_LOCATION:
      case MULTIPATH_RESET: {
        const route = selectCurrentHistoryRoute(getState())
        if (isItineraryRoute(route) && !isASearchOnItineraryPage(route)) {
          height = getItineraryHeaderHeight(getState)
        }
        break
      }
      case HIDE_UI_ELEMENT:
        const route = selectCurrentHistoryRoute(getState())
        if (action.payload === UI_ELEMENTS.SEARCH_DIALOG && isItineraryRoute(route)) {
          height = getItineraryHeaderHeight(getState)
        }
        break
      case SHOW_UI_ELEMENT:
        if (action.payload === UI_ELEMENTS.SEARCH_DIALOG) height = HEADER_DEFAULT_HEIGHT
        break
    }

    if (height !== null) {
      dispatch({
        type: SET_HEADER_HEIGHT,
        payload: height
      })
    }

    return r
  }

export const hideSuggestOnReceivingMultipleAddresses =
  ({ dispatch, getState }) =>
  next =>
  action => {
    if (action.type === LOAD_MULTI_ADDRESS_SUCCESS) {
      hideUIElement(UI_ELEMENTS.SUGGEST)(dispatch, getState)
    }

    return next(action)
  }

export const updateIncentiveTimestampsOnDisplay =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const { payload } = action

    if (action.type === DISPLAY_MODAL) {
      if (payload?.modalId === MODAL_IDS.USER_ACCOUNT_INCENTIVE_POPIN) {
        setUserAccountIncentivePopinDisplayed()(dispatch, getState)
      } else if (payload?.modalId === MODAL_IDS.USER_ACCOUNT_INCENTIVE_ITINERARY_RESULTS) {
        setItineraryResultsUserAccountIncentiveDisplayed()(dispatch, getState)
      }
    }

    return next(action)
  }

export default [
  onChangingRouteToggleoffOnboardingContext,
  headerHeightMiddleware,
  hideSuggestOnReceivingMultipleAddresses,
  updateIncentiveTimestampsOnDisplay
]
