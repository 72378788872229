import { TAG_PUBLISHER } from './analytics.actionTypes'
import { resolveClickPublisherTag, resolveImpressionPublisherTag } from '../../domain/analytics/PublisherTagsService'
import { sendPublisherTag } from '../../domain/analytics/ATInternetService'
import { getPageActiveObj, replaceValues } from '../../domain/analytics/analyticsUtils'

export const preparePublisherTag = ({ tagInfo, getState }) => {
  const { PageActive } = getPageActiveObj({ store: getState() })
  if (!PageActive) return

  const tagParamValues = {
    PageActive,
    ...tagInfo
  }

  return replaceValues(tagInfo.tag, tagParamValues)
}

const sendTag = ({ action, getState }) => {
  const { tagInfo, isImpression } = action

  const preparedTag = preparePublisherTag({ tagInfo, getState })
  if (!preparedTag) return
  const resolveTag = isImpression ? resolveImpressionPublisherTag : resolveClickPublisherTag
  const resolvedTag = resolveTag(preparedTag)

  sendPublisherTag(resolvedTag)
}

export const publisherTagsMiddleware =
  ({ getState }) =>
  next =>
  action => {
    const r = next(action)

    if (__BROWSER__) {
      try {
        if (action.type === TAG_PUBLISHER) {
          sendTag({ action, getState })
        }
      } catch (error) {
        console.error(error)
      }
    }

    return r
  }
