import { arrayOf, bool, object, string } from 'prop-types'
import { FAVORITE_TYPES } from '../../domain/UserAccount/UserAccount.constants'
import { POIS_FILTERS } from '../../domain/search/filters/filters.constants'
import { isATransactionalHotelAppId, populateGeoentityUrl } from '../../domain/utils/geoentity'
import { computeBBoxFromCoordinates } from '../../domain/utils/map'
import { createCustomSelector } from '../../domain/utils/react-reselect'
import { isFranceCountryCode } from '../../routes/address/Countries'
import { isEmpty } from '../../utils/lang'
import { selectIsCurrentAndPreviousRouteTheSame } from '../history/history.selectors'
import { selectIsGE } from '../tools/tools.selectors'
import {
  selectAreGeoentitiesDisplayedAsList,
  selectIsOnboardingContext,
  selectUIGeoentityActiveFilterId
} from '../ui/ui.selectors'
import { DEFAULT_FUEL_ID } from './geoentity.constants'
import { getMarkerInformations } from './geoentity.helpers'
import {
  geoentitiesPropTypes,
  geoentityBlocksPropTypes,
  geoentityIdentityCardPropTypes,
  geoentityLatLngPropTypes,
  geoentityPropTypes,
  geoentityTabContentPropTypes,
  geoentityTabPropTypes,
  transportInfoPropTypes
} from './geoentity.proptypes'

const selectGeoentityState = store => store?.geoentity ?? {}

export const selectAllGeoentities = createCustomSelector(selectGeoentityState, ({ pois = [] }) => pois)
selectAllGeoentities.propTypes = geoentitiesPropTypes

export const selectGeoentities = createCustomSelector(selectAllGeoentities, pois => pois.filter(({ vde }) => !vde))
selectGeoentities.propTypes = geoentitiesPropTypes

export const selectVdeGeoentities = createCustomSelector(selectAllGeoentities, pois =>
  pois.filter(({ vde }) => Boolean(vde))
)
selectVdeGeoentities.propTypes = geoentitiesPropTypes

export const selectCurrentGeoentityId = createCustomSelector(selectGeoentityState, ({ currentPoiId }) => currentPoiId)
selectCurrentGeoentityId.propTypes = string

const geoentityByIdCombiner = (geoentities = [], id) => geoentities.find(geoentity => geoentity.id === id)

export const selectGeoentityById = createCustomSelector(
  selectAllGeoentities,
  (_, props) => props?.id,
  geoentityByIdCombiner
)
selectGeoentityById.propTypes = geoentityPropTypes

export const selectCurrentGeoentity = createCustomSelector(
  selectAllGeoentities,
  selectCurrentGeoentityId,
  geoentityByIdCombiner
)
selectCurrentGeoentity.propTypes = geoentityPropTypes

export const selectFirstGeoentity = createCustomSelector(selectGeoentities, geoentities => geoentities?.[0])
selectFirstGeoentity.propTypes = geoentityPropTypes

export const selectCurrentGeoentityAsFavorite = createCustomSelector(selectCurrentGeoentity, currentGeoentity => {
  if (!currentGeoentity) return null
  const { id, label, addressLabel } = currentGeoentity
  return {
    type: FAVORITE_TYPES.OTHER,
    poiId: id,
    label,
    address: addressLabel
  }
})

export const selectSelectedGeoentity = createCustomSelector(
  (_, props) => props?.geoentity,
  selectGeoentityById,
  selectCurrentGeoentity,
  (geoentityFromProps, geoentityById, currentGeoentity) => geoentityFromProps || geoentityById || currentGeoentity
)
selectSelectedGeoentity.propTypes = geoentityPropTypes
export const selectGeoentityRubric = createCustomSelector(selectSelectedGeoentity, geoentity => geoentity?.rubric)
export const selectGeoentityRubricId = createCustomSelector(selectGeoentityRubric, rubric => rubric?.id)
export const selectGeoentityRubricSegment = createCustomSelector(selectGeoentityRubric, rubric => rubric?.segment)
export const selectGeoentityParentRubricId = createCustomSelector(selectGeoentityRubric, rubric => rubric?.parent)

export const selectSelectedGeoentityLatLng = createCustomSelector(
  selectSelectedGeoentity,
  geoentity => geoentity?.coordinates ?? {}
)
selectSelectedGeoentityLatLng.propTypes = geoentityLatLngPropTypes

const BBOX_SIZE_IN_METERS_AROUND_POI = 250

export const selectGeoentityComputedBbox = createCustomSelector(selectSelectedGeoentityLatLng, point => {
  if (point.lng && point.lat) {
    return computeBBoxFromCoordinates(BBOX_SIZE_IN_METERS_AROUND_POI)(point)
  }
  return null
})

export const selectSelectedGeoentityIdentityCard = createCustomSelector(selectSelectedGeoentity, geoentity => {
  if (!geoentity) return
  const type = geoentity?.rubric?.label
  const icon = geoentity?.rubric?.icon
  const rubricId = geoentity?.rubric?.id
  const isRating = rubricId === 'hotel' || rubricId === 'camping'
  const rating = isRating && '*******'.substring(0, geoentity?.additionalInfo?.rating ?? 0)

  return {
    name: geoentity.name,
    address: geoentity.addressLabel,
    type,
    icon,
    rating,
    rubricId
  }
})
selectSelectedGeoentityIdentityCard.propTypes = geoentityIdentityCardPropTypes

export const selectSelectedGeoentityIdentityCardTransportInfo = createCustomSelector(
  selectSelectedGeoentity,
  geoentity => {
    if (!geoentity) return
    return geoentity?.transportInfo
  }
)
selectSelectedGeoentityIdentityCard.propTypes = arrayOf(transportInfoPropTypes)

export const selectSelectedGeoentityContextualPoiUrl = createCustomSelector(
  selectSelectedGeoentity,
  geoentity => geoentity?.contextualPoiUrl
)
selectSelectedGeoentityContextualPoiUrl.propTypes = string

export const selectSelectedGeoentityCurrentTab = createCustomSelector(selectSelectedGeoentity, geoentity => {
  if (!geoentity) return
  const { currentTabId, tabs = [] } = geoentity
  return tabs.find(({ appId }) => currentTabId === appId)
})
selectSelectedGeoentityCurrentTab.propTypes = arrayOf(geoentityTabPropTypes)

export const selectGeoentityCurrentTabId = createCustomSelector(
  selectSelectedGeoentity,
  ({ currentTabId } = {}) => currentTabId
)
selectGeoentityCurrentTabId.propTypes = string

export const selectSelectedGeoentityTabContent = createCustomSelector(
  selectSelectedGeoentity,
  selectSelectedGeoentityCurrentTab,
  (geoentity, tab) => {
    const currentBlocksIdx = geoentity?.currentBlocksIdx ?? 0
    return tab?.content?.[currentBlocksIdx] ?? {}
  }
)
selectSelectedGeoentityTabContent.propTypes = geoentityTabContentPropTypes

export const selectSelectedGeoentityBlocksContext = createCustomSelector(
  selectSelectedGeoentityTabContent,
  content => content.context
)
selectSelectedGeoentityBlocksContext.propTypes = string

export const selectSelectedGeoentityBlocks = createCustomSelector(
  selectSelectedGeoentityTabContent,
  content => content?.blocks ?? []
)
selectSelectedGeoentityBlocks.propTypes = geoentityBlocksPropTypes

export const selectHasCurrentGeoentityTabBlocks = createCustomSelector(
  selectSelectedGeoentity,
  selectSelectedGeoentityCurrentTab,
  selectSelectedGeoentityBlocks,
  (geoentity, tab, blocks) => {
    if ((geoentity?.tabs ?? []).length === 0) return false
    if (tab?.content) return !isEmpty(blocks)
    return true
  }
)
selectHasCurrentGeoentityTabBlocks.propTypes = bool

export const selectCurrentGeoentityTabBlocksCount = createCustomSelector(
  selectSelectedGeoentityBlocks,
  blocks => blocks.length
)

export const selectGeoentitiesFilters = createCustomSelector(
  selectGeoentityState,
  geoentity => geoentity?.filters ?? []
)
selectGeoentitiesFilters.propTypes = arrayOf(object)

export const selectOtherGeoentities = createCustomSelector(
  selectGeoentities,
  selectCurrentGeoentityId,
  (pois, currentPoiId) => pois.filter(({ id }) => id !== currentPoiId)
)

export const selectFirstGeoentitySatisfying = createCustomSelector(
  selectGeoentities,
  (_, props) => props?.keys,
  (pois, keys) =>
    pois.find(poi => {
      const poiKeys = Object.keys(poi)
      return keys.every(key => poiKeys.includes(key))
    })
)
selectFirstGeoentitySatisfying.propTypes = geoentityPropTypes

export const selectSelectedGeoentityLabelForUrl = createCustomSelector(
  selectSelectedGeoentity,
  ({ name, way, postcode, town }) => `${name} ${way} ${postcode} ${town}`
)

export const selectCurrentGeoentityLabelForUrl = createCustomSelector(selectCurrentGeoentity, geoentity => {
  if (!geoentity) return null
  const { name, way, postcode, town } = geoentity
  return `${name} ${way} ${postcode} ${town}`
})

export const selectGeoentityApplicationId = createCustomSelector(
  selectSelectedGeoentity,
  selectGeoentityCurrentTabId,
  (geoentity, currentTabId) => currentTabId || geoentity?.additionalInfo?.appId
)

export const selectIsTransactionalHotelGeoentity = createCustomSelector(
  selectGeoentityApplicationId,
  isATransactionalHotelAppId
)

export const selectReservationLink = createCustomSelector(
  selectSelectedGeoentity,
  (_, { type } = {}) => type,
  (geoentity, type) => populateGeoentityUrl(geoentity?.additionalInfo?.reservationTemplatizedUrl, type)
)

export const selectGeoentityPjId = createCustomSelector(selectSelectedGeoentity, geoentity => geoentity?.pjId)

export const selectIsPJGeoentity = createCustomSelector(selectSelectedGeoentity, geoentity =>
  ['pj', 'pagesjaunes'].includes(geoentity?.prov)
)

export const selectHasMultipleBlocks = createCustomSelector(
  selectSelectedGeoentityCurrentTab,
  tab => (tab?.content ?? []).length > 1
)
selectHasMultipleBlocks.propTypes = bool

export const selectBlocksTitles = createCustomSelector(selectSelectedGeoentityCurrentTab, tab =>
  (tab?.content ?? []).map(({ title }) => title)
)
selectBlocksTitles.propTypes = arrayOf(string)

export const selectSelectedGeoentityIllustrationUrl = createCustomSelector(
  selectSelectedGeoentity,
  geoentity => geoentity?.additionalInfo?.illustration?.url
)
selectSelectedGeoentityIllustrationUrl.propTypes = string

export const selectGeoentityImagesAlt = createCustomSelector(
  selectSelectedGeoentity,
  ({ name, rubric, town }) => `${name} - ${rubric.label} - ${town}`
)

export const selectHasOnlyCurrentGeoentity = createCustomSelector(
  selectGeoentities,
  selectCurrentGeoentityId,
  (geoentities, currentId) => geoentities.length === 1 && geoentities[0].id === currentId
)

export const selectHasGeoentities = createCustomSelector(selectGeoentities, geoentities => geoentities.length > 0)
selectHasGeoentities.propTypes = bool

export const selectHasVdeGeoentities = createCustomSelector(selectVdeGeoentities, geoentities => geoentities.length > 0)
selectHasVdeGeoentities.propTypes = bool

export const selectFocusedPoiId = createCustomSelector(selectGeoentityState, geoentity => geoentity?.focusedPoiId)

export const selectFocusedPoi = createCustomSelector(
  selectAllGeoentities,
  selectFocusedPoiId,
  (geoentities, focusedPoiId) => geoentities.find(({ id }) => focusedPoiId === id)
)

export const selectFocusedPoiIllustrationUrl = createCustomSelector(
  selectFocusedPoi,
  poi => poi?.additionalInfo?.illustration?.url
)

const buildMarker = (pois = [], currentPoiId, focusedPoiId) =>
  pois
    .map(poi => {
      const isCurrent = poi.id === currentPoiId
      const isFocus = poi.id === focusedPoiId
      const markerInfos = getMarkerInformations({ poi, isCurrent, isFocus })
      return { ...poi, ...markerInfos, isCurrent: isCurrent || isFocus }
    })
    .sort((a, b) => (a.zIndex > b.zIndex ? 1 : b.zIndex > a.zIndex ? -1 : 0))

export const selectGeoentityMarkers = createCustomSelector(
  selectGeoentities,
  selectCurrentGeoentityId,
  selectFocusedPoiId,
  buildMarker
)

export const selectVdeGeoentityMarkers = createCustomSelector(
  selectVdeGeoentities,
  selectCurrentGeoentityId,
  selectFocusedPoiId,
  buildMarker
)

export const selectHasSelectedGeoentityFullContent = createCustomSelector(selectSelectedGeoentity, selectedGeoentity =>
  (selectedGeoentity?.tabs?.length ?? 0) > 0 ? selectedGeoentity.tabs.some(tab => !isEmpty(tab.content)) : true
)

export const selectIsWaitingForRequestedGeoentities = createCustomSelector(
  selectGeoentityState,
  geoentity => geoentity?.isWaitingForRequestedGeoentities
)

export const selectGeoentityViewport = createCustomSelector(selectGeoentityState, geoentity => geoentity?.viewport)

export const selectShouldDisplaySubHeaderForFilters = createCustomSelector(
  selectGeoentitiesFilters,
  filters => filters.length > 0
)

export const selectGeoentityActiveFilter = createCustomSelector(
  selectGeoentitiesFilters,
  selectUIGeoentityActiveFilterId,
  (filters, activeId) => activeId && filters.find(({ id }) => id === activeId)
)

export const selectHasFilterMultiDatePicker = createCustomSelector(selectGeoentitiesFilters, filters =>
  Boolean(filters.find(({ id }) => id === POIS_FILTERS.multidatepicker.id))
)

export const selectShouldDisplaySearchActiveFilter = createCustomSelector(
  selectUIGeoentityActiveFilterId,
  selectAreGeoentitiesDisplayedAsList,
  selectIsGE,
  (_, props = {}) => props,
  (activeFilterId, geoentitiesAsList, ge, { displayOverMap }) => {
    const noFilterId = !activeFilterId
    const overMapAndGE = ge && displayOverMap
    const noDisplayPE = !ge && ((displayOverMap && geoentitiesAsList) || (!displayOverMap && !geoentitiesAsList))
    return !noFilterId && !overMapAndGE && !noDisplayPE
  }
)

export const selectGeoentityFilterParameters = createCustomSelector(
  selectGeoentityState,
  geoentity => geoentity?.filterParameters
)

export const selectGeoentityActiveFilterParameter = createCustomSelector(
  selectGeoentityFilterParameters,
  selectUIGeoentityActiveFilterId,
  (filterParameters, filterId) => filterParameters && filterParameters[filterId]
)

export const selectIsGeoentityExtendBbox = createCustomSelector(
  selectGeoentityState,
  geoentity => geoentity?.extendBbox ?? false
)
export const selectFilterHotelDates = createCustomSelector(
  selectGeoentityFilterParameters,
  filterParameters => filterParameters && filterParameters[POIS_FILTERS.multidatepicker.id]
)

export const selectGeoentityError = createCustomSelector(selectGeoentityState, ({ error }) => error)

export const selectOptionsForRequestMove = createCustomSelector(
  selectIsOnboardingContext,
  selectIsCurrentAndPreviousRouteTheSame,
  (onboardingContext, isRouteSame) =>
    // `avoidAnimation` is needed here because it prevents the animation from stopping in the middle of nowhere
    // the padding is calculated during the bbox animation and stops the transition if not avoided
    onboardingContext ? { avoidAnimation: true, zoom: 14 } : { avoidAnimation: !isRouteSame }
)

export const selectIsFrenchGeoentity = createCustomSelector(selectSelectedGeoentity, (geoentity = {}) => {
  const { countryCode } = geoentity
  return isFranceCountryCode(countryCode)
})

export const selectFuelInformation = createCustomSelector(selectGeoentityState, (geoentity = {}) => geoentity?.fuels)

export const selectCurrentFuelFilterId = createCustomSelector(
  selectGeoentityFilterParameters,
  (parameters = {}) => parameters['combobox-fuel']?.currentFuel ?? DEFAULT_FUEL_ID
)

export const selectCurrentFuelFilterIdWithoutFallback = createCustomSelector(
  selectGeoentityFilterParameters,
  (parameters = {}) => parameters['combobox-fuel']?.currentFuel
)

export const selectCurrentFuelFilterInfo = createCustomSelector(
  selectFuelInformation,
  selectCurrentFuelFilterId,
  (info, filterId) => info?.find(fuel => fuel.id === filterId)
)
