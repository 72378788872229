import { OUTDOOR_STATUS_NONE } from '../../domain/outdoor/Utils'
import { pick } from '../../utils/object'
import { selectSelectedGeoentityIdentityCard } from '../geoentity/geoentity.selectors'
import {
  CLOSE_NPS_SURVEY,
  DISPLAY_MAP_TOO_SLOW_TOOLTIP,
  DISPLAY_MODAL,
  DISPLAY_OUTDOOR,
  HIDE_HOME_BANNER,
  HIDE_MAP_TOO_SLOW_TOOLTIP,
  HIDE_MODAL,
  HIDE_OUTDOOR,
  HIDE_PREFERENCES_USER_INCENTIVE,
  HIDE_UI_ELEMENT,
  INCREMENT_VISIT,
  RESET_CURRENT_SEARCH,
  RESET_GEOENTITY_ACTIVE_FILTER,
  RESET_GEOENTITY_DISPLAY_MODE_TOGGLE,
  RESET_ITINERARY_ELEVATION_POINT_OVER,
  SET_CURRENT_SEARCH,
  SET_GEOENTITY_ACTIVE_FILTER,
  SET_GEOENTITY_DISPLAY_MODE,
  SET_ITINERARY_ELEVATION_POINT_OVER,
  SET_ITINERARY_RESULTS_INCENTIVE_DISPLAYED,
  SET_MAP_TOO_SLOW_TIMESTAMP,
  SET_POIS_ON_ROUTE_INCENTIVE_POPIN_TIMESTAMP,
  SET_POPIN_INCENTIVE_DISPLAYED,
  SET_USER_UI_SCREEN,
  SHOW_PREFERENCES_USER_INCENTIVE,
  SHOW_UI_ELEMENT,
  TOGGLE_GEOENTITY_DISPLAY_MODE
} from './ui.actionTypes'
import { UI_ELEMENTS, USER_SCREENS } from './ui.constants'
import { GEOENTITY_DISPLAY_MODE_LIST, GEOENTITY_DISPLAY_MODE_MAP } from './ui.geoentities.constants'
import {
  selectCurrentSearch,
  selectIsPreferencesUserAccountIncentiveDisplayed,
  selectOutdoorStatus,
  selectUIGeoentityActiveFilterId
} from './ui.selectors'

export const setCurrentSearch = currentSearch => (dispatch, getState) => {
  if (selectCurrentSearch(getState()) !== currentSearch) {
    dispatch({
      type: SET_CURRENT_SEARCH,
      payload: { currentSearch }
    })
  }
}

export const setSelectedGeoentityAsCurrentSearch = () => (dispatch, getState) => {
  const { name, address } = selectSelectedGeoentityIdentityCard(getState())
  setCurrentSearch(`${name} - ${address}`)(dispatch, getState)
}

export const resetCurrentSearch = () => (dispatch, getState) => {
  dispatch({
    type: RESET_CURRENT_SEARCH
  })
}

export const showUIElement = uiElement => (dispatch, getState) => {
  dispatch({
    type: SHOW_UI_ELEMENT,
    payload: uiElement
  })
}

export const hideUIElement = uiElement => (dispatch, getState) => {
  dispatch({
    type: HIDE_UI_ELEMENT,
    payload: uiElement
  })
}

export const displayCompleteSuggest = () => (dispatch, getState) => {
  showUIElement(UI_ELEMENTS.SUGGEST)(dispatch, getState)
  showUIElement(UI_ELEMENTS.SEARCH_DIALOG)(dispatch, getState)
}

export const hideCompleteSuggest = () => (dispatch, getState) => {
  hideUIElement(UI_ELEMENTS.SEARCH_DIALOG)(dispatch, getState)
  hideUIElement(UI_ELEMENTS.SUGGEST)(dispatch, getState)
}

export const displayModal = modalId => dispatch =>
  dispatch({
    type: DISPLAY_MODAL,
    payload: { modalId }
  })

export const hideModal = modalId => dispatch =>
  dispatch({
    type: HIDE_MODAL,
    payload: { modalId }
  })

export const closeNPSSurvey = () => dispatch =>
  dispatch({
    type: CLOSE_NPS_SURVEY,
    payload: Date.now()
  })

export const displayOutdoor = panoData => dispatch =>
  dispatch({
    type: DISPLAY_OUTDOOR,
    payload: pick(panoData, ['status', 'panoramicId', 'target', 'lat', 'lng', 'url'])
  })

export const hideOutdoor = () => (dispatch, getState) =>
  selectOutdoorStatus(getState()) !== OUTDOOR_STATUS_NONE &&
  dispatch({
    type: HIDE_OUTDOOR
  })

export const toggleGeoentitiesDisplayMode = () => dispatch =>
  dispatch({
    type: TOGGLE_GEOENTITY_DISPLAY_MODE
  })

export const setGeoentitiesDisplayMode = displayMode => dispatch => {
  if (displayMode === GEOENTITY_DISPLAY_MODE_LIST || displayMode === GEOENTITY_DISPLAY_MODE_MAP)
    dispatch({
      type: SET_GEOENTITY_DISPLAY_MODE,
      payload: displayMode
    })
}

export const resetGeoentitiesDisplayMode = () => dispatch =>
  dispatch({
    type: RESET_GEOENTITY_DISPLAY_MODE_TOGGLE
  })

export const resetGeoentityActiveFilter = () => dispatch =>
  dispatch({
    type: RESET_GEOENTITY_ACTIVE_FILTER
  })

export const setGeoentityActiveFilter = id => (dispatch, getState) => {
  const currentId = selectUIGeoentityActiveFilterId(getState())
  dispatch({
    type: SET_GEOENTITY_ACTIVE_FILTER,
    payload: { id: currentId !== id ? id : undefined }
  })
}

export const setElevationPointOver = point => dispatch => {
  dispatch({
    type: SET_ITINERARY_ELEVATION_POINT_OVER,
    payload: point
  })
}

export const resetElevationPointOver = () => dispatch => {
  dispatch({
    type: RESET_ITINERARY_ELEVATION_POINT_OVER
  })
}

export const setUserUIScreen =
  (name, params = {}) =>
  dispatch =>
    dispatch({
      type: SET_USER_UI_SCREEN,
      payload: { name, params }
    })

export const hideAndResetUserUIScreen = () => (dispatch, getState) => {
  hideUIElement(UI_ELEMENTS.USER)(dispatch, getState)
  setUserUIScreen(USER_SCREENS.default)(dispatch, getState)
}

export const setUserAccountIncentivePopinDisplayed =
  (timestamp = Date.now()) =>
  dispatch =>
    dispatch({ type: SET_POPIN_INCENTIVE_DISPLAYED, payload: { timestamp } })

export const incrementVisitCount = () => dispatch => dispatch({ type: INCREMENT_VISIT })

export const showUserAccountPreferencesIncentive = () => (dispatch, getState) => {
  const isDisplayed = selectIsPreferencesUserAccountIncentiveDisplayed(getState())
  if (!isDisplayed) dispatch({ type: SHOW_PREFERENCES_USER_INCENTIVE })
}

export const hideUserAccountPreferencesIncentive = () => (dispatch, getState) => {
  const isDisplayed = selectIsPreferencesUserAccountIncentiveDisplayed(getState())
  if (isDisplayed) dispatch({ type: HIDE_PREFERENCES_USER_INCENTIVE })
}

export const setItineraryResultsUserAccountIncentiveDisplayed =
  (timestamp = Date.now()) =>
  dispatch =>
    dispatch({ type: SET_ITINERARY_RESULTS_INCENTIVE_DISPLAYED, payload: { timestamp } })

export const displayMapTooSlowTooltip = () => (dispatch, getState) => {
  dispatch({ type: DISPLAY_MAP_TOO_SLOW_TOOLTIP })
}

export const hideMapTooSlowTooltip = () => (dispatch, getState) => {
  dispatch({ type: HIDE_MAP_TOO_SLOW_TOOLTIP })
}

export const setMapTooSlowTimestamp = () => (dispatch, getState) => {
  dispatch({ type: SET_MAP_TOO_SLOW_TIMESTAMP })
}

export const setPoisOnRouteIncentivePopin = () => (dispatch, getState) =>
  dispatch({ type: SET_POIS_ON_ROUTE_INCENTIVE_POPIN_TIMESTAMP })

export const hideHomeBanner = () => (dispatch, getState) => dispatch({ type: HIDE_HOME_BANNER })
