import { saveCurrentSearchResult } from './searchHistory.actions'
import {
  LOAD_SINGLE_ADDRESS_COMPUTE_RADIUS_SUCCESS,
  LOAD_SINGLE_ADDRESS_SUCCESS
} from '../../address/address.actionTypes'
import { SET_SINGLE_GEOENTITY } from '../../geoentity/geoentity.actionTypes'
import { SET_STEP_LOCATION } from '../../itinerary/itinerary.actionTypes'
import { SET_STEP_FROM } from '../../itinerary/itinerary.constants'

import SentryService from '../../../domain/error/SentryService'

export const searchHistoryPersistorMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    switch (action.type) {
      case LOAD_SINGLE_ADDRESS_SUCCESS:
      case LOAD_SINGLE_ADDRESS_COMPUTE_RADIUS_SUCCESS:
      case SET_STEP_LOCATION:
        try {
          const {
            data: {
              addresses: [{ label, split_label, type }]
            }
          } = action.payload
          const from = action?.payload?.options?.from
          if (from !== SET_STEP_FROM.mapAction) {
            saveCurrentSearchResult({
              label,
              split_label,
              type
            })(dispatch, getState)
          }
        } catch (error) {
          SentryService.captureException(error)
        }
        break
      case SET_SINGLE_GEOENTITY:
        try {
          const { label, split_label, type } = action.payload
          saveCurrentSearchResult({
            label,
            split_label,
            type
          })(dispatch, getState)
        } catch (error) {
          SentryService.captureException(error)
        }
        break
    }
    return next(action)
  }

export default [searchHistoryPersistorMiddleware]
