export const TOGGLEOFF_ONBOARDING_CONTEXT = 'TOGGLEOFF_ONBOARDING_CONTEXT'

export const SET_CURRENT_SEARCH = 'SET_CURRENT_SEARCH'
export const RESET_CURRENT_SEARCH = 'RESET_CURRENT_SEARCH'

export const SET_HEADER_HEIGHT = 'SET_HEADER_HEIGHT'

export const SHOW_UI_ELEMENT = 'SHOW_UI_ELEMENT'
export const HIDE_UI_ELEMENT = 'HIDE_UI_ELEMENT'

export const DISPLAY_GEOLOC = 'DISPLAY_GEOLOC'
export const HIDE_GEOLOC = 'HIDE_GEOLOC'

export const CLOSE_AD_BANNER = 'CLOSE_AD_BANNER'

export const CLOSE_NPS_SURVEY = 'CLOSE_NPS_SURVEY'

export const DISPLAY_OUTDOOR = 'DISPLAY_OUTDOOR'
export const HIDE_OUTDOOR = 'HIDE_OUTDOOR'

export const DISPLAY_MODAL = 'DISPLAY_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'

export const RESET_GEOENTITY_DISPLAY_MODE_TOGGLE = 'RESET_GEOENTITY_DISPLAY_MODE_TOGGLE'
export const TOGGLE_GEOENTITY_DISPLAY_MODE = 'TOGGLE_GEOENTITY_DISPLAY_MODE'
export const SET_GEOENTITY_DISPLAY_MODE = 'SET_GEOENTITY_DISPLAY_MODE'

export const SET_GEOENTITY_ACTIVE_FILTER = 'SET_GEOENTITY_ACTIVE_FILTER'
export const RESET_GEOENTITY_ACTIVE_FILTER = 'RESET_GEOENTITY_ACTIVE_FILTER'

export const SET_ITINERARY_ELEVATION_POINT_OVER = 'SET_ITINERARY_ELEVATION_POINT_OVER'
export const RESET_ITINERARY_ELEVATION_POINT_OVER = 'RESET_ITINERARY_ELEVATION_POINT_OVER'

export const SET_USER_UI_SCREEN = 'SET_USER_UI_SCREEN'

export const INCREMENT_VISIT = 'INCREMENT_VISIT'

export const SET_POPIN_INCENTIVE_DISPLAYED = 'SET_POPIN_INCENTIVE_DISPLAYED'
export const SHOW_PREFERENCES_USER_INCENTIVE = 'SHOW_PREFERENCES_USER_INCENTIVE'
export const HIDE_PREFERENCES_USER_INCENTIVE = 'HIDE_PREFERENCES_USER_INCENTIVE'
export const SET_ITINERARY_RESULTS_INCENTIVE_DISPLAYED = 'SET_ITINERARY_RESULTS_INCENTIVE_DISPLAYED'

export const DISPLAY_MAP_TOO_SLOW_TOOLTIP = 'DISPLAY_MAP_TOO_SLOW_TOOLTIP'
export const HIDE_MAP_TOO_SLOW_TOOLTIP = 'HIDE_MAP_TOO_SLOW_TOOLTIP'
export const SET_MAP_TOO_SLOW_TIMESTAMP = 'SET_MAP_TOO_SLOW_TIMESTAMP'

export const SET_POIS_ON_ROUTE_INCENTIVE_POPIN_TIMESTAMP = 'SET_POIS_ON_ROUTE_INCENTIVE_POPIN_TIMESTAMP'

export const HIDE_HOME_BANNER = 'HIDE_HOME_BANNER'
