import { merge, pick } from '../../utils/object'
import {
  SET_DEFAULT_VEHICULE_OPTIONS,
  SET_DEFAULT_FUEL_PRICES,
  SET_PREFERRED_OPTIONS_FOR_MODE,
  SET_PREFERRED_MODE,
  SET_PREFERRED_OPTIONS
} from './travelOptions.actionTypes'
import { PREFERRED_FUEL_ID, PREFERRED_VEHICLE_ID } from '../../domain/travelOptions/travelOptions.constants'

export const getInitialState = () => {
  return {
    preferredOptions: {},
    defaultOptions: {
      vehiclesOptions: [],
      fuelPrices: {},
      transportModes: []
    }
  }
}

function compileRelevantPrevPreferredOptionsForMode({ modeId, preferredOptions, newPreferredOptionsForMode }) {
  const shouldOverrideVehicleOptions = Boolean(newPreferredOptionsForMode[PREFERRED_VEHICLE_ID])
  const shouldOverrideGasOptions = Boolean(newPreferredOptionsForMode[PREFERRED_FUEL_ID])

  if (shouldOverrideVehicleOptions) {
    return {}
  }

  if (shouldOverrideGasOptions) {
    return pick(preferredOptions[modeId], PREFERRED_VEHICLE_ID)
  }

  return preferredOptions[modeId]
}

export default function travelOptionsReducers(state = getInitialState(), action) {
  switch (action && action.type) {
    case SET_DEFAULT_VEHICULE_OPTIONS:
      const { vehiclesOptions, transportModes } = action.payload
      return {
        ...state,
        defaultOptions: {
          ...state.defaultOptions,
          vehiclesOptions,
          transportModes
        }
      }
    case SET_DEFAULT_FUEL_PRICES:
      const { fuelPrices } = action.payload

      return {
        ...state,
        defaultOptions: {
          ...state.defaultOptions,
          fuelPrices
        }
      }
    case SET_PREFERRED_OPTIONS: {
      const { options } = action.payload
      return {
        ...state,
        preferredOptions: merge(state.preferredOptions, options)
      }
    }
    case SET_PREFERRED_OPTIONS_FOR_MODE: {
      const { modeId, ...newPreferredOptionsForMode } = action.payload
      const { preferredOptions } = state
      const relevantPrevPreferredOptionsForMode = compileRelevantPrevPreferredOptionsForMode({
        modeId,
        preferredOptions,
        newPreferredOptionsForMode
      })

      return {
        ...state,
        preferredOptions: {
          ...preferredOptions,
          [modeId]: {
            ...relevantPrevPreferredOptionsForMode,
            ...newPreferredOptionsForMode
          }
        }
      }
    }
    case SET_PREFERRED_MODE: {
      const { modeId } = action.payload
      const { preferredOptions } = state
      return {
        ...state,
        preferredOptions: {
          ...preferredOptions,
          favoriteMode: modeId
        }
      }
    }
    default:
      return state
  }
}
