import { findAddressforGeolocation, geocodeForward } from '../../dataSource/address/address.request'
import { selectLocale } from '../locale/locale.selectors'
import {
  LOAD_ADDRESS_ERROR,
  LOAD_ADDRESS_REQUEST,
  LOAD_SINGLE_ADDRESS_COMPUTE_RADIUS_SUCCESS
} from './address.actionTypes'

const handleError = dispatch => error => {
  dispatch({
    type: LOAD_ADDRESS_ERROR,
    payload: { error }
  })

  throw error
}

export const updateCurrentSearchFromLngLat = lngLat => (dispatch, getState) => {
  const locale = selectLocale(getState())
  return findAddressforGeolocation({ ...lngLat, locale })
    .then(({ addresses, viewport }) => {
      dispatch({
        type: LOAD_SINGLE_ADDRESS_COMPUTE_RADIUS_SUCCESS,
        payload: {
          data: {
            addresses,
            viewport
          }
        }
      })
    })
    .catch(handleError(dispatch))
}

export const updateCurrentSearchFromAddress = data => dispatch => {
  dispatch({
    type: LOAD_SINGLE_ADDRESS_COMPUTE_RADIUS_SUCCESS,
    payload: { data }
  })
}

export const searchSingleComputeRadiusAddress =
  ({ terms }) =>
  (dispatch, getState) => {
    dispatch({
      type: LOAD_ADDRESS_REQUEST
    })

    const locale = selectLocale(getState())

    return (
      geocodeForward({ location: terms, contour: 1, locale })
        // eslint-disable-next-line sonarjs/no-identical-functions
        .then(({ addresses, viewport }) => {
          dispatch({
            type: LOAD_SINGLE_ADDRESS_COMPUTE_RADIUS_SUCCESS,
            payload: {
              data: {
                addresses,
                viewport
              }
            }
          })
        })
        .catch(handleError(dispatch))
    )
  }
