import {
  SET_QUIZ_CITIES,
  SET_QUIZ_CITY_ANSWER,
  SET_QUIZ_GUESS_COORDINATES,
  SET_QUIZ_CURRENT_CITY_INDEX,
  SET_QUIZ_LEVEL,
  RESET_QUIZ,
  SET_QUIZ_TYPE,
  SET_QUIZ_CONFIG
} from './quiz.actionTypes'
import { selectQuizCurrentCityIndex, selectQuizCurrentGuessCoordinates } from './quiz.selectors'
import { fetchDepartmentQuizCities, fetchQuizCities, fetchRegionQuizCities } from '../../dataSource/quiz/quiz.request'
import { buildCityQuestion } from '../../domain/quiz/quiz.utils'
import { QUIZ_TYPES } from '../../domain/quiz/quiz.constants'
import { selectFirstAddress } from '../address/address.selectors'

const QUIZ_TIME_BEFORE_NEXT_QUESTION = 2000

export const resetQuiz = () => (dispatch, getState) =>
  dispatch({
    type: RESET_QUIZ
  })

export const setQuizCities = cities => (dispatch, getState) =>
  dispatch({
    type: SET_QUIZ_CITIES,
    payload: cities.map(buildCityQuestion)
  })

export const setQuizType = type => (dispatch, getState) =>
  dispatch({
    type: SET_QUIZ_TYPE,
    payload: type
  })

export const setQuizConfig = config => (dispatch, getState) =>
  dispatch({
    type: SET_QUIZ_CONFIG,
    payload: config
  })

export const setQuizLevel = level => (dispatch, getState) =>
  dispatch({
    type: SET_QUIZ_LEVEL,
    payload: level
  })

export const startNewCitiesQuiz =
  ({ level }) =>
  async (dispatch, getState) => {
    const cities = await fetchQuizCities({ level })
    setQuizType(QUIZ_TYPES.CITIES)(dispatch, getState)
    setQuizLevel(level)(dispatch, getState)
    setQuizCities(cities)(dispatch, getState)
  }

export const startNewRegionCitiesQuiz = () => async (dispatch, getState) => {
  const code = selectFirstAddress(getState()).regionCode
  const { config, list } = await fetchRegionQuizCities({ code })
  setQuizType(QUIZ_TYPES.REGION_CITIES)(dispatch, getState)
  setQuizConfig(config)(dispatch, getState)
  setQuizCities(list)(dispatch, getState)
}

export const startNewDepartmentCitiesQuiz = () => async (dispatch, getState) => {
  const code = selectFirstAddress(getState()).departmentCode
  const { config, list } = await fetchDepartmentQuizCities({ code })
  setQuizType(QUIZ_TYPES.DEPARTMENT_CITIES)(dispatch, getState)
  setQuizConfig(config)(dispatch, getState)
  setQuizCities(list)(dispatch, getState)
}

export const setCurrentCityGuessCoordinatesQuiz = lngLat => (dispatch, getState) => {
  if (selectQuizCurrentGuessCoordinates(getState())) return
  const cityIndex = selectQuizCurrentCityIndex(getState())
  dispatch({
    type: SET_QUIZ_GUESS_COORDINATES,
    payload: {
      cityIndex,
      answer: {
        guessCoordinates: lngLat
      }
    }
  })
}

export const setQuizCurrentCityIndex = index => (dispatch, getState) =>
  dispatch({
    type: SET_QUIZ_CURRENT_CITY_INDEX,
    payload: index
  })

export const waitForNextQuestion = () => (dispatch, getState) => {
  const index = selectQuizCurrentCityIndex(getState())
  setTimeout(() => {
    setQuizCurrentCityIndex(index + 1)(dispatch, getState)
  }, QUIZ_TIME_BEFORE_NEXT_QUESTION)
}

export const setCurrentCityAnswer = answer => (dispatch, getState) => {
  const cityIndex = selectQuizCurrentCityIndex(getState())
  dispatch({
    type: SET_QUIZ_CITY_ANSWER,
    payload: {
      cityIndex,
      answer
    }
  })
}
