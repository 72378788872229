import { decorateTrainRoutesWithLowerPrice } from '../../domain/itinerary/specialcases/itinerary.specialcases.utils'
import { createCustomSelector } from '../../domain/utils/react-reselect'
import { groupBy } from '../../utils/array'
import { isEmpty } from '../../utils/lang'
import { SORT_TYPES } from './itinerary.constants'
import {
  selectActiveSort,
  selectCurrentMode,
  selectCurrentProvider,
  selectItineraryState,
  selectProviders
} from './itinerary.selectors'
import {
  compareRoutesByCO2Emission,
  compareRoutesByDuration,
  compareRoutesByPrice,
  compareRoutesByTransportsServiceProviderPriority
} from './itinerary.sort.utils'
import { formatRoute } from './routes.utils'

export const selectRoutes = createCustomSelector(selectItineraryState, itinerary => itinerary?.routes)

export const selectSortedRoutes = createCustomSelector(
  selectRoutes,
  selectProviders,
  selectActiveSort,
  (routes = [], providers, activeSort) => {
    const routesToSort = [...routes]
    switch (activeSort) {
      case SORT_TYPES.price:
        routesToSort.sort(compareRoutesByPrice)
        break
      case SORT_TYPES.co2:
        routesToSort.sort(compareRoutesByCO2Emission)
        break
      case SORT_TYPES.duration:
        routesToSort.sort(compareRoutesByDuration)
        break
      default:
        routesToSort.sort(compareRoutesByTransportsServiceProviderPriority(providers))
    }

    return routesToSort
  }
)

export const selectRoutesForMonomode = createCustomSelector(
  selectSortedRoutes,
  selectCurrentMode,
  (routes, currentMode) => {
    const routesForProvider = routes.filter(({ mode }) => mode === currentMode).map(route => formatRoute(route))
    const groupedRoutes = groupBy(routesForProvider, route => route.provider)
    return decorateTrainRoutesWithLowerPrice(groupedRoutes)
  }
)

export const selectRoutesForMultimode = createCustomSelector(selectSortedRoutes, routes =>
  routes.map(route => formatRoute(route, 'multimode'))
)

export const selectDoesCurrentProviderHaveRoutes = createCustomSelector(
  selectRoutesForMonomode,
  selectCurrentProvider,
  (routes, currentProvider) => currentProvider in routes
)

export const selectDoesCurrentModeHaveRoutes = createCustomSelector(selectRoutesForMonomode, routesByProvider => {
  return Object.values(routesByProvider).length !== 0
})

export const selectCurrentRouteId = createCustomSelector(selectItineraryState, state => state?.currentRouteId)

export const selectCurrentItineraryRoute = createCustomSelector(
  selectRoutes,
  selectCurrentRouteId,
  (routes = [], currentRouteId) => routes.find(({ routeId }) => routeId === currentRouteId) || {}
)

export const selectCurrentItineraryRouteDistance = createCustomSelector(
  selectCurrentItineraryRoute,
  route => route?.length
)

export const selectCurrentRoute = createCustomSelector(
  selectCurrentRouteId,
  selectRoutes,
  (currentRouteId, routes = []) => routes.find(({ routeId }) => routeId === currentRouteId)
)

export const selectDisplayedRoutes = createCustomSelector(selectRoutes, selectCurrentMode, (routes = [], currentMode) =>
  routes.filter(({ mode }) => mode === currentMode)
)

export const selectNumberOfDisplayedRoutes = createCustomSelector(
  selectRoutes,
  selectCurrentMode,
  selectActiveSort,
  (routes = [], currentMode, activeSort) =>
    !activeSort ? routes.filter(({ mode }) => mode === currentMode).length : routes.length
)

export const selectCurrentItineraryRouteMode = createCustomSelector(
  selectCurrentItineraryRoute,
  selectProviders,
  (currentRoute, providers = []) => providers.find(p => p.name === currentRoute?.provider?.id)?.mode
)

export const selectPriceDetail = createCustomSelector(selectCurrentRoute, currentRoute =>
  (currentRoute?.legs ?? []).map(({ icon, price: { label } }) => ({
    icon,
    price: label
  }))
)

export const selectHasPriceDetail = createCustomSelector(selectPriceDetail, priceDetail => !isEmpty(priceDetail))

export const selectTotalPrice = createCustomSelector(
  selectCurrentRoute,
  currentRoute => currentRoute?.prices?.total?.label
)

export const selectTotalPriceValue = createCustomSelector(
  selectCurrentRoute,
  currentRoute => currentRoute?.prices?.total?.value
)

export const selectCO2Emission = createCustomSelector(selectCurrentRoute, currentRoute => currentRoute?.co2_emissions)

export const selectHasCO2Emission = createCustomSelector(selectCO2Emission, co2Emission => !isEmpty(co2Emission))

export const selectHasToll = createCustomSelector(
  selectCurrentRoute,
  currentRoute => currentRoute?.prices?.toll?.value > 0
)

export const selectCurrentRouteLengthLabel = createCustomSelector(
  selectCurrentRoute,
  currentRoute => currentRoute?.length?.label
)

export const selectCurrentRouteMonomodeTitle = createCustomSelector(
  selectCurrentRoute,
  currentRoute => currentRoute?.titles?.monomode?.text
)

export const getRouteTimeLabel = route => route?.time?.label

export const getRouteLengthLabel = route => route?.length?.label

export const getRouteLengthValue = route => route?.length?.value
