import {
  createFavorite,
  deleteFavorite,
  requestFavorites,
  requestSaveTravelOptions,
  requestSearchForAddressFavorite,
  requestSearchForPoiXorAddressFavorite,
  requestTravelOptions,
  updateFavorite
} from '../../dataSource/userAccount/userAccount.request'
import { translate } from '../../domain/i18n/T'
import { removeComma } from '../../utils/string'
import { selectLocale } from '../locale/locale.selectors'
import { selectMapBbox } from '../map/map.selectors'
import { SET_PREFERRED_OPTIONS } from '../travelOptions/travelOptions.actionTypes'
import { selectPreferredOptionsForService } from '../travelOptions/travelOptions.selectors'
import {
  ADD_USER_FAVORITE,
  DELETE_USER_FAVORITE,
  EDIT_USER_FAVORITE,
  SET_TOKEN,
  SET_USER_CONNECTED,
  SET_USER_FAVORITES,
  SET_USER_NOT_CONNECTED
} from './userAccount.actionTypes'
import { selectIsUserConnected, selectKeycloakToken } from './userAccount.selectors'

export const setUserConnected =
  ({ token, identity } = {}) =>
  dispatch =>
    dispatch({ type: SET_USER_CONNECTED, payload: { token, identity } })

export const setUserNotConnected = () => dispatch => dispatch({ type: SET_USER_NOT_CONNECTED })

export const setToken = token => dispatch => token && dispatch({ type: SET_TOKEN, payload: token })

export const loadFavorites = () => (dispatch, getState) => {
  const token = selectKeycloakToken(getState())
  if (!token) return Promise.reject(new Error('no token'))

  const locale = selectLocale(getState())
  const homeLabel = translate(locale)('useraccount.favorite.home')
  const workLabel = translate(locale)('useraccount.favorite.work')

  return Promise.resolve()
    .then(() => requestFavorites(token, homeLabel, workLabel))
    .then(favorites => {
      if (favorites) dispatch({ type: SET_USER_FAVORITES, payload: { favorites } })
    })
    .catch(error => {
      console.error(`An error has occured while loading favorites : ${error}`)
      return { error: `Une erreur est survenue lors du chargement des favoris.` }
    })
}

export const addOtherFavorite = favorite => (dispatch, getState) => {
  if (!favorite) return Promise.reject(new Error('no favorite'))

  const token = selectKeycloakToken(getState())

  if (!token) return Promise.reject(new Error('no token'))

  return requestFavorites(token)
    .then(allFavorites =>
      (allFavorites?.others || []).find(
        otherFavorites => removeComma(otherFavorites?.address) === removeComma(favorite?.address)
      )
    )
    .then(alreadyExistingFavorite => {
      if (alreadyExistingFavorite) {
        return {
          error: 'Ce lieu a déjà été ajouté à vos favoris.'
        }
      } else {
        dispatch({ type: ADD_USER_FAVORITE, payload: favorite })

        const bbox = selectMapBbox(getState())
        const locale = selectLocale(getState())
        return requestSearchForPoiXorAddressFavorite(locale, bbox, favorite).then(updatedFavorite =>
          createFavorite(token, updatedFavorite)
        )
      }
    })
    .catch(error => {
      console.log(`An error has occured while adding a new favorite : ${error}`)
      return { error: `Une erreur est survenue lors de l'ajout d'un autre favori.` }
    })
    .finally(() => {
      loadFavorites()(dispatch, getState)
    })
}

export const addOrEditHomeOrWorkFavorite = favorite => (dispatch, getState) => {
  if (!favorite) return Promise.reject(new Error('no favorite'))

  const token = selectKeycloakToken(getState())
  if (!token) return Promise.reject(new Error('no token'))

  dispatch({ type: EDIT_USER_FAVORITE, payload: favorite })

  const bbox = selectMapBbox(getState())
  const locale = selectLocale(getState())
  return requestSearchForAddressFavorite(locale, bbox, favorite)
    .then(updatedFavorite =>
      !updatedFavorite.uuid ? createFavorite(token, updatedFavorite) : updateFavorite(token, updatedFavorite)
    )
    .catch(error => {
      console.error(`An error has occured while editing a work/home favorite : ${error}`)
      return { error: `Une erreur est survenue lors de l'ajout d'un favori maison/boulot.` }
    })
    .finally(() => loadFavorites()(dispatch, getState))
}

export const deleteUserFavorite = favorite => (dispatch, getState) => {
  if (!favorite) return Promise.reject(new Error('no favorite'))

  const token = selectKeycloakToken(getState())
  if (!token) return Promise.reject(new Error('no token'))

  dispatch({ type: DELETE_USER_FAVORITE, payload: favorite })

  return deleteFavorite(token, favorite)
    .catch(error => {
      console.error(`An error has occured while deleting a favorite : ${error}`)
      return { error: `Une erreur est survenue lors de la suppression d'un favori.` }
    })
    .finally(() => loadFavorites()(dispatch, getState))
}

export const loadTravelOptions = () => (dispatch, getState) => {
  const token = selectKeycloakToken(getState())

  return !token
    ? Promise.reject(new Error('no token'))
    : requestTravelOptions(token)
        .then(options => {
          if (options) dispatch({ type: SET_PREFERRED_OPTIONS, payload: { options } })
        })
        .catch(error => {
          console.error(`An error has occured while loading travel options : ${error}`)
          return { error: `Une erreur est survenue lors du chargement des options de déplacement.` }
        })
}

export const saveTravelOptions = () => (dispatch, getState) => {
  if (!selectIsUserConnected(getState())) return Promise.resolve()

  const token = selectKeycloakToken(getState())
  if (!token) return Promise.reject(new Error('no token'))

  const options = selectPreferredOptionsForService(getState())
  return requestSaveTravelOptions(token, options).catch(error => {
    console.error(`An error has occured while saving travel options : ${error}`)
    return { error: `Une erreur est survenue lors de la sauvegarde des options de déplacement.` }
  })
}
