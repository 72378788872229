import { requestAbTest } from '../../dataSource/abtest/abtest.requests'
import { selectLocale } from '../locale/locale.selectors'
import {
  SET_AB_TEST_LOADING,
  SET_AB_TEST_CONFIG,
  SET_AB_TEST_READY,
  SET_AB_TEST_ENROLLMENT
} from './abtest.actionTypes'
import { parseAbTestResponse } from './abtest.dataParser'
import { selectAbTestConfig, selectAbTestEnrollment, selectShouldLoadAbTest } from './abtest.selectors'
import debug from 'debug'
import SentryService from '../../domain/error/SentryService'
import { ABTEST_ENABLE } from '../../domain/abtest/AbTest.constants'

const d = debug('abtest')

export const loadAbTest = () => (dispatch, getState) => {
  if (!selectShouldLoadAbTest(getState())) return Promise.resolve()

  dispatch({ type: SET_AB_TEST_LOADING })
  return requestAbTest()
    .then(response => {
      const locale = selectLocale(getState())
      const data = parseAbTestResponse(response?.data, locale)
      dispatch({ type: SET_AB_TEST_CONFIG, payload: Array.isArray(data) ? data : [] })
    })
    .catch(e => {
      SentryService.captureException(e)
      dispatch({ type: SET_AB_TEST_CONFIG, payload: [] })
    })
}

export const enrollForAbTest = () => (dispatch, getState) =>
  Promise.resolve().then(() => {
    const availableTestIds = selectAbTestConfig(getState()).map(({ id }) => id)
    const enrolledTests = selectAbTestEnrollment(getState()).filter(({ id }) => availableTestIds.includes(id))
    const enrolledTestsId = enrolledTests.map(({ id }) => id)
    const testsNotEnrolled = availableTestIds.filter(id => !enrolledTestsId.includes(id))
    const enrollment = testsNotEnrolled.map(id => {
      return { id, randomId: Math.floor(Math.random() * 100) }
    })
    const allTests = enrolledTests.concat(enrollment)
    d('tests enrolled', allTests)
    if (enrollment.length > 0) {
      dispatch({ type: SET_AB_TEST_ENROLLMENT, payload: allTests })
    }
  })

export const loadAndEnrollAbTest = () => (dispatch, getState) => {
  if (__SERVER__ || !ABTEST_ENABLE) return Promise.resolve()
  return loadAbTest()(dispatch, getState)
    .then(() => enrollForAbTest()(dispatch, getState))
    .then(() => dispatch({ type: SET_AB_TEST_READY }))
}
