import { applyMiddleware, createStore, compose } from 'redux'
import rootReducer from './reducers'
import { thunk } from 'redux-thunk'
import domainMiddlewares from './domainMiddlewares'
import { shouldUseDevTools, composeWithDevTools } from './debug/reduxDevTools'
import sentryReduxEnhancer from './debug/sentryReduxEnhancer'

export default function configureStore({ preloadedState, history } = {}) {
  const thirdPartyMiddlewares = [thunk]

  const middlewares = [...thirdPartyMiddlewares, ...domainMiddlewares(history)]

  const composeEnhancers = shouldUseDevTools() ? composeWithDevTools() : compose

  return createStore(
    rootReducer,
    preloadedState,
    __BROWSER__
      ? composeEnhancers(applyMiddleware(...middlewares), sentryReduxEnhancer)
      : composeEnhancers(applyMiddleware(...middlewares))
  )
}
