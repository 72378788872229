import React from 'react'
import { extractLanguageFromLocale } from './index'
import { useSelector } from 'react-redux'
import { selectLocale } from '../../store/locale/locale.selectors'
import { injectArgsInString } from '../../utils/string'

function getTranslations(locale) {
  const language = extractLanguageFromLocale(locale)
  if (__SERVER__) return require(`./${language}.json`)
  else return global.TRANSLATIONS || {}
}

export const insertTranslationArgs = (str, args) => injectArgsInString(str, ...args)

export const translate =
  locale =>
  (id, args = []) => {
    const translations = getTranslations(locale)
    const str = translations?.[id] ?? id

    return insertTranslationArgs(str, args)
  }

export const translateAsObject = locale => ids => {
  const translations = getTranslations(locale)
  const reducer = (acc, id) => {
    acc[id] = translations?.[id] ?? id
    return acc
  }
  return ids.reduce(reducer, {})
}

// eslint-disable-next-line react/display-name
export const withTranslation = WrappedComponent => props => {
  const locale = useSelector(selectLocale)
  return <WrappedComponent t={translate(locale)} tAsObject={translateAsObject(locale)} {...props} />
}

export const useT = (translationId, args = []) => {
  const locale = useSelector(selectLocale)
  const argsAsArray = args instanceof Array ? args : [args]
  return translate(locale)(translationId, argsAsArray)
}

export const useTranslateAsObject = ids => {
  const locale = useSelector(selectLocale)

  return translateAsObject(locale)(ids)
}

const T = ({ id, args }) => {
  const locale = useSelector(selectLocale)
  return translate(locale)(id, args)
}
export default T
