import { REGISTER_CONSENT } from './cmp.actionTypes'

export default (state = {}, action) => {
  switch (action && action.type) {
    case REGISTER_CONSENT:
      return {
        ...state,
        consent: {
          ...action.payload
        }
      }
  }
  return state
}
