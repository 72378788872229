import { isGE } from '../../domain/Device'
import { bboxArrayToBboxArrays } from '../../domain/map/conversion'
import { refreshGeoentities } from './search.actions'
import { selectCurrentHistoryRouteOptions, selectIsSearchPage } from '../history/history.selectors'
import { MAP_UPDATE } from '../map/map.actionTypes'
import { SET_GEOENTITY_LIST } from '../geoentity/geoentity.actionTypes'
import { selectGeoentityViewport, selectIsGeoentityExtendBbox } from '../geoentity/geoentity.selectors'
import { requestMove } from '../map/map.actions'
import { isEqual } from '../../utils/lang'
import { debounce } from '../../utils/function'

export const THRESHOLD_DISTANCE_IN_PX_TO_REFRESH_POI = isGE() ? 100 : 30
export const DELAY_BEFORE_SEARCH_GEOENTITIES = 500

const refresh = debounce((dispatch, getState) => {
  refreshGeoentities()(dispatch, getState)
}, DELAY_BEFORE_SEARCH_GEOENTITIES)

export const refreshGeoentitiesSearchOnMapUpdate =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const state = getState()
    const r = next(action)
    if (selectIsSearchPage(state) && action.type === MAP_UPDATE) {
      const { activeBbox, distanceFromPreviousChange, fromAppAction } = action.payload
      const routeParams = selectCurrentHistoryRouteOptions(state)
      const shouldRefresh =
        !fromAppAction &&
        !isEqual(routeParams.bbox, activeBbox) &&
        distanceFromPreviousChange > THRESHOLD_DISTANCE_IN_PX_TO_REFRESH_POI

      if (shouldRefresh) {
        refresh(dispatch, getState)
      }
    }
    return r
  }

export const fitMapOnExtendBbox =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)
    const storeState = getState()
    if (action.type === SET_GEOENTITY_LIST && selectIsGeoentityExtendBbox(storeState)) {
      const viewport = selectGeoentityViewport(storeState)
      requestMove({ bbox: bboxArrayToBboxArrays(viewport) })(dispatch, getState)
    }
    return r
  }

export default [refreshGeoentitiesSearchOnMapUpdate, fitMapOnExtendBbox]
