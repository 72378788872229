import { isPE } from '../../domain/Device'
import { bboxIsValid } from '../../domain/map/map.utils'
import { areLatLngAtMeterPrecisionEquals } from '../../domain/utils/location'
import { convertBboxToWestSouthEastNorthBbox, getBboxFromLngLatPoints } from '../../domain/utils/map'
import geoLocationService from '../../geoLocationService'
import { isEmpty } from '../../utils/lang'
import { omitBy } from '../../utils/object'
import { selectIsFrenchLanguage, selectLocale } from '../locale/locale.selectors'
import {
  ACTIVATE_GEOIP,
  CLEAN_GEOLOC_AFTER_FETCH,
  DISPLAY_POPUP,
  HIDE_POPUP,
  MAP_ACK_MOVE,
  MAP_ACK_ZOOM,
  MAP_DESTROYED,
  MAP_REQUEST_MOVE,
  MAP_REQUEST_ZOOM,
  MAP_RESET_STYLE,
  MAP_SET_MODE,
  MAP_SET_STATUS,
  MAP_SET_THEME,
  MAP_UPDATE,
  REQUEST_GEOLOC_STATUS,
  RESET_MAP_EVENT_POPIN,
  SEND_MAP_LOADING_TIME,
  SET_DEFAULT_BBOX,
  SET_GEOLOC_POSITION,
  SET_GEOLOC_STATUS,
  SET_MAP_EVENTS,
  SET_MAP_EVENT_DATE,
  SET_MAP_EVENT_POPIN,
  SET_MAP_EVENT_TIME,
  SWITCH_MAP_TYPE,
  TOGGLE_FULLSCREEN,
  TURN_OFF_FULLSCREEN
} from './map.actionTypes'
import { parseMapEvents } from './map.dataParser'
import { requestMapEvents } from './map.request'
import {
  selectAllMapEvents,
  selectGeolocationPosition,
  selectGeolocationRequestStatus,
  selectGeolocationStatus,
  selectIsMapFullScreen,
  selectIsMapReady,
  selectMapTargetData
} from './map.selectors'

export const setDefaultBbox =
  ({ bbox }) =>
  (dispatch, getState) => {
    dispatch({
      type: SET_DEFAULT_BBOX,
      payload: {
        bbox
      }
    })
  }

export const update =
  ({ bbox, zoom, activeBbox, searchBbox, distanceFromPreviousChange, fromAppAction }) =>
  dispatch =>
    dispatch({
      type: MAP_UPDATE,
      payload: {
        bbox,
        activeBbox,
        searchBbox,
        zoom,
        fromAppAction,
        distanceFromPreviousChange
      }
    })

export const acknowledgeMove = () => dispatch =>
  dispatch({
    type: MAP_ACK_MOVE
  })

export const acknowledgeZoom = () => dispatch =>
  dispatch({
    type: MAP_ACK_ZOOM
  })

export const requestMove =
  ({ bbox, center, options }) =>
  (dispatch, getState) => {
    if (__SERVER__ && !bbox) return
    if (bbox && !bboxIsValid(bbox)) return

    dispatch({
      type: __BROWSER__ ? MAP_REQUEST_MOVE : SET_DEFAULT_BBOX,
      payload: omitBy(
        {
          bbox: bbox ? convertBboxToWestSouthEastNorthBbox(bbox) : undefined,
          center,
          options: !selectIsMapReady(getState()) ? { ...options, avoidAnimation: true } : options
        },
        isEmpty
      )
    })
  }

export const fitAddresses =
  (addresses = []) =>
  (dispatch, getState) => {
    if (addresses.length === 0) return
    if (addresses.length === 1) {
      const bbox = addresses?.[0]?.bbox ?? []
      if (!bbox) return
      dispatch({
        type: MAP_REQUEST_MOVE,
        payload: { bbox }
      })
    } else {
      fitPoints(addresses.map(({ coordinates }) => coordinates))(dispatch, getState)
    }
  }

const fitPoints = points => (dispatch, getState) => {
  dispatch({
    type: MAP_REQUEST_MOVE,
    payload: {
      bbox: getBboxFromLngLatPoints(points),
      options: {
        withFastAnimation: isPE()
      }
    }
  })
}

export const requestZoom =
  ({ zoom }) =>
  (dispatch, getState) => {
    if (!zoom) return
    if (zoom === selectMapTargetData(getState())?.targetZoom) return
    dispatch({
      type: MAP_REQUEST_ZOOM,
      payload: { zoom }
    })
  }

export const setMapTheme = theme => dispatch =>
  dispatch({
    type: MAP_SET_THEME,
    payload: theme
  })

export const setMapMode = mode => dispatch =>
  dispatch({
    type: MAP_SET_MODE,
    payload: mode
  })

export const activateGeoIp = () => dispatch =>
  dispatch({
    type: ACTIVATE_GEOIP
  })

export const setMapStatus = status => dispatch =>
  dispatch({
    type: MAP_SET_STATUS,
    payload: status
  })

export const mapResetStyle = () => dispatch =>
  dispatch({
    type: MAP_RESET_STYLE
  })

export const mapDestroyed = () => dispatch =>
  dispatch({
    type: MAP_DESTROYED
  })

export const setGeolocationStatus = (status, options) => (dispatch, getState) => {
  const currentStatus = selectGeolocationStatus(getState())
  if (status === currentStatus) return
  dispatch({
    type: SET_GEOLOC_STATUS,
    payload: { status, options }
  })
}

export const setGeolocationPosition = position => (dispatch, getState) => {
  const geolocInStore = selectGeolocationPosition(getState())
  if (!areLatLngAtMeterPrecisionEquals(position, geolocInStore)) {
    dispatch({
      type: SET_GEOLOC_POSITION,
      payload: { ...position }
    })
  }
}

export const requestGeolocationStatus = (status, options) => (dispatch, getState) => {
  const currentStatus = selectGeolocationRequestStatus(getState())
  if (status === currentStatus) return
  dispatch({
    type: REQUEST_GEOLOC_STATUS,
    payload: { status, options }
  })
}

export const cleanGeolocationAfterFetch = () => (dispatch, getState) => {
  dispatch({
    type: CLEAN_GEOLOC_AFTER_FETCH
  })
}

export const getCurrentLocation = () => (_, getState) => {
  const position = selectGeolocationPosition(getState())
  if (!isEmpty(position)) return Promise.resolve(position)

  return new Promise((resolve, reject) =>
    geoLocationService.getCurrentPosition({
      positionHandler: ({ coords: { latitude, longitude } }) => resolve({ lat: latitude, lng: longitude }),
      errorHandler: reject
    })
  )
}

export const toggleFullscreen = () => dispatch =>
  dispatch({
    type: TOGGLE_FULLSCREEN
  })

export const turnOffFullscreen = () => (dispatch, getState) => {
  if (selectIsMapFullScreen(getState())) {
    dispatch({
      type: TURN_OFF_FULLSCREEN
    })
  }
}

export const displayPopup = type => dispatch =>
  dispatch({
    type: DISPLAY_POPUP,
    payload: type
  })

export const hidePopup = () => dispatch =>
  dispatch({
    type: HIDE_POPUP
  })

export const switchMapType = () => dispatch =>
  dispatch({
    type: SWITCH_MAP_TYPE
  })

export const sendMapLoadingTime =
  ({ loadTime, mapHasGoodSupport }) =>
  dispatch =>
    dispatch({
      type: SEND_MAP_LOADING_TIME,
      payload: { loadTime, mapHasGoodSupport }
    })

export const loadMapEvents = () => (dispatch, getState) => {
  const locale = selectLocale(getState())
  const mapEvents = selectAllMapEvents(getState())
  if (!mapEvents.length)
    return requestMapEvents(locale).then(response => {
      dispatch({
        type: SET_MAP_EVENTS,
        payload: parseMapEvents(response)
      })
    })
  return Promise.resolve()
}

export const setMapEventPopin = popinInformation => (dispatch, getState) => {
  const isFR = selectIsFrenchLanguage(getState())
  return dispatch({
    type: SET_MAP_EVENT_POPIN,
    payload: {
      title: isFR ? popinInformation.title_fr : popinInformation.title_en,
      description: isFR ? popinInformation.description_fr : popinInformation.description_en,
      asset_zone: popinInformation.asset_map_zone,
      asset_event: popinInformation.asset_event_title
    }
  })
}

export const resetMapEventPopin = () => dispatch => dispatch({ type: RESET_MAP_EVENT_POPIN })

export const setMapEventDate = date => dispatch =>
  dispatch({
    type: SET_MAP_EVENT_DATE,
    payload: date
  })

export const setMapEventTime = time => dispatch =>
  dispatch({
    type: SET_MAP_EVENT_TIME,
    payload: time
  })
