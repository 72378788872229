import { CLEAR_HISTORY, SAVE_CURRENT_SEARCH_RESULT } from './searchHistory/searchHistory.actionTypes'

import { RESET_SEARCH_PARAMETERS, SET_SEARCH_PARAMETERS } from './search.actionTypes'
import { flow } from '../../utils/function'
import { take, uniqBy } from '../../utils/array'

export const initialState = {
  parameters: {},
  searchHistory: []
}

export const SEARCH_HISTORY_SIZE = 50

export default function searchReducer(state = initialState, action) {
  switch (action.type) {
    case SET_SEARCH_PARAMETERS:
      return {
        ...state,
        parameters: action?.payload?.parameters ?? {}
      }
    case RESET_SEARCH_PARAMETERS:
      return {
        ...state,
        parameters: {}
      }
    case SAVE_CURRENT_SEARCH_RESULT: {
      const searchHistory = flow(
        array => uniqBy(array, item => item.label),
        array => take(array, SEARCH_HISTORY_SIZE)
      )([action.payload, ...state.searchHistory])

      return {
        ...state,
        searchHistory
      }
    }
    case CLEAR_HISTORY: {
      return initialState
    }
    default:
      return state
  }
}
