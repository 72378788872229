import {
  LOAD_ADDRESS_REQUEST,
  LOAD_SINGLE_ADDRESS_SUCCESS,
  LOAD_MULTI_ADDRESS_SUCCESS,
  LOAD_ADDRESS_ERROR
} from '../address/address.actionTypes'
import { searchServiceCtx } from './search.service.constants'
import {
  navigateToGeoentity,
  navigateToSearchByApp,
  navigateToSearchByQuery,
  navigateToSearchByRubric
} from '../history/history.actions'
import requestSearch from '../../dataSource/search/search.requests'
import AddressNotFoundError from '../../domain/error/AddressNotFoundError'
import {
  SET_GEOENTITIES_RECEIVED,
  SET_GEOENTITIES_REQUESTED,
  SET_GEOENTITY_LIST
} from '../geoentity/geoentity.actionTypes'
import { SET_STEP_LOCATION } from '../itinerary/itinerary.actionTypes'
import SilentError from '../../domain/error/SilentError'
import NoResultError from '../../domain/error/NoResultError'
import { RESET_SEARCH_PARAMETERS, SET_SEARCH_PARAMETERS } from './search.actionTypes'
import { bboxArrayToBboxArrays, bboxToRouteParameter } from '../../domain/map/conversion'
import { resetGeoentities, searchGeoentities } from '../geoentity/geoentity.actions'
import { getGeoentityTypeDataForSearch } from '../poiassets/poiassets.selectors'
import { getSearchMaxPois, selectSearchParameters } from './search.selectors'
import { selectMapActiveBbox, selectMapBbox } from '../map/map.selectors'
import { selectLocale } from '../locale/locale.selectors'
import { selectGeoentityFilterParameters } from '../geoentity/geoentity.selectors'

export const search =
  (params = {}) =>
  (dispatch, getState) => {
    const {
      searchFilterType = searchServiceCtx.filter.poiXorAddress,
      terms,
      options,
      setGeoentityListActionType = SET_GEOENTITY_LIST,
      navigateAfterSearch = true,
      extendBbox = 1,
      ...args
    } = params

    dispatch({
      type: LOAD_ADDRESS_REQUEST
    })
    dispatch({
      type: SET_GEOENTITIES_REQUESTED
    })

    const state = getState()
    const bbox = selectMapBbox(state)
    const locale = selectLocale(state)

    return requestSearch({ terms, searchFilterType, extendBbox, bbox, locale, ...args })
      .then(({ addresses = [], pois = [], viewport, header = {} }) => {
        const havePois = pois.length > 0
        const haveAddresses = addresses.length > 0

        if (header.storeChainsId) {
          return navigateAfterSearch && navigateToSearchByApp(header.storeChainsId)(dispatch, getState)
        }

        if (header.rubricId) {
          return navigateAfterSearch && navigateToSearchByRubric(header.rubricId)(dispatch, getState)
        }

        if (havePois) {
          dispatch({
            type: setGeoentityListActionType,
            payload: {
              pois,
              viewport,
              extendBbox
            }
          })
          if (!haveAddresses) {
            if (pois.length === 1) {
              const { id } = pois[0]
              return navigateToGeoentity({ id })(dispatch, getState)
            }

            const options = { avoidSearching: true }
            if (header.located) options.bbox = bboxToRouteParameter(bboxArrayToBboxArrays(viewport))
            return navigateAfterSearch && navigateToSearchByQuery(terms, options)(dispatch, getState)
          }
        }

        if (!havePois && !haveAddresses) throw new AddressNotFoundError()

        dispatch({
          type: addresses.length === 1 ? LOAD_SINGLE_ADDRESS_SUCCESS : LOAD_MULTI_ADDRESS_SUCCESS,
          payload: {
            data: {
              addresses,
              viewport
            },
            options
          }
        })
      })
      .catch(e => {
        const error = e instanceof NoResultError ? new AddressNotFoundError() : e
        resetGeoentities()(dispatch, getState)
        if (!(e instanceof SilentError)) {
          dispatch({
            type: LOAD_ADDRESS_ERROR,
            payload: { error }
          })
        }

        throw error
      })
      .finally(() =>
        dispatch({
          type: SET_GEOENTITIES_RECEIVED
        })
      )
  }

export const searchItineraryLocation =
  (params = {}) =>
  (dispatch, getState) => {
    const { terms, options, ...args } = params

    dispatch({
      type: LOAD_ADDRESS_REQUEST
    })

    const state = getState()
    const bbox = selectMapBbox(state)
    const locale = selectLocale(state)

    return requestSearch({ terms, searchFilterType: searchServiceCtx.filter.poiXorAddress, bbox, locale, ...args })
      .then(({ addresses = [], pois = [], viewport }) => {
        if (addresses.length === 0 && pois.length === 0) throw new AddressNotFoundError()

        const type = addresses.length > 1 ? LOAD_MULTI_ADDRESS_SUCCESS : SET_STEP_LOCATION
        const locations = addresses.length !== 0 ? addresses : pois

        dispatch({
          type,
          payload: {
            data: {
              addresses: locations,
              viewport
            },
            options
          }
        })
      })
      .catch(e => {
        const error = e instanceof NoResultError ? new AddressNotFoundError() : e
        dispatch({
          type: LOAD_ADDRESS_ERROR,
          payload: { error }
        })

        throw error
      })
  }

export const setSearchParameters =
  (parameters = {}) =>
  (dispatch, getState) => {
    dispatch({ type: SET_SEARCH_PARAMETERS, payload: { parameters } })
  }

export const resetSearchParameters = () => (dispatch, getState) => {
  dispatch({ type: RESET_SEARCH_PARAMETERS })
}

export const collectParameters = (parameters = {}) => {
  return Object.values(parameters).reduce((acc, cur) => {
    Object.entries(cur).forEach(([param, value]) => {
      if (acc[param]) {
        acc[param] = acc[param].concat(value)
      } else {
        acc[param] = [value]
      }
    })
    return acc
  }, {})
}

export const refreshGeoentities = bbox => (dispatch, getState) => {
  const state = getState()
  const { appOrRubricIds, searchTerms, biTagId } = getGeoentityTypeDataForSearch(state, selectSearchParameters(state))
  const filterParamsByFilter = collectParameters(selectGeoentityFilterParameters(state))

  if (appOrRubricIds) {
    return searchGeoentities({
      appOrRubricIds,
      filterParams: filterParamsByFilter,
      biTagId,
      max: getSearchMaxPois(),
      extendBbox: 0,
      noRequestedDispatch: true,
      bbox
    })(dispatch, getState)
  } else if (searchTerms) {
    return requestSearch({
      terms: searchTerms,
      searchFilterType: searchServiceCtx.filter.poi,
      bbox: selectMapActiveBbox(state),
      extendBbox: 0,
      locale: selectLocale(state)
    }).then(({ pois = [], viewport }) => {
      dispatch({
        type: SET_GEOENTITY_LIST,
        payload: {
          pois,
          viewport
        }
      })
    })
  }
}
