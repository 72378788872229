import React from 'react'
import { useSelector } from 'react-redux'
import { TAG_GOTO_AJOUT_ETAPE } from '../../../store/analytics/analytics.actionTypes'
import { selectGeoentityRubricId } from '../../../store/geoentity/geoentity.selectors'
import { selectIsItineraryUniverse } from '../../../store/history/history.selectors'
import { addStep, setStepFromPoint } from '../../../store/itinerary/itinerary.actions'
import { PAGE_POI_NAME } from '../../analytics/PageTagsService'
import RectangleActionButton, {
  COLOR_GREY_EMPTY,
  SIZE_NORMAL
} from '../../genericComponents/buttons/RectangleActionButton'
import { useAction, withEverySelectorTruthy } from '../../utils/react-redux'
import useEventCallback from '../../utils/useEventCallback'
import addStepIcon from './add-step.svg'
import { selectAllSteps, selectIsMaxStepNumberReached } from './steps.selectors'

const AddPoiStepButton = ({ location }) => {
  const getAllSteps = useSelector(selectAllSteps)
  const rubricId = useSelector(selectGeoentityRubricId)
  const isMaxStepNumberReached = useSelector(selectIsMaxStepNumberReached)

  const execAddStep = useAction(addStep)
  const execSetStepFromPoint = useAction(setStepFromPoint)

  const onClick = useEventCallback(() => {
    if (!isMaxStepNumberReached) execAddStep()
    execSetStepFromPoint(location.coordinates, getAllSteps.length - (isMaxStepNumberReached ? 2 : 1))
  })

  return (
    <RectangleActionButton
      label={'itinerary.step.button.poi'}
      iconUrl={addStepIcon}
      color={COLOR_GREY_EMPTY}
      size={SIZE_NORMAL}
      onClick={onClick}
      tagData={{
        type: TAG_GOTO_AJOUT_ETAPE,
        tagInfo: {
          ancrage: PAGE_POI_NAME,
          catId: rubricId
        }
      }}
    />
  )
}

export default withEverySelectorTruthy(selectIsItineraryUniverse)(AddPoiStepButton)
