import { identity } from '../utils/function'
import { isEmpty } from './lang'

export const areAllEntriesTruthy = array => {
  if (isEmpty(array)) return false

  return array.every(identity)
}

export const compact = array => array?.filter(Boolean) ?? []

export const head = array => (array?.at ? array.at(0) : array?.[0])

export const last = array => (array?.at ? array.at(array.length - 1) : array?.[array.length - 1])

export const flatten = array => array.flat()

export const uniq = array => [...new Set(array)]

export const uniqBy = (arr, iteratee) => {
  if (!arr) return []
  if (!iteratee) return arr

  if (typeof iteratee === 'string') {
    const prop = iteratee
    iteratee = item => item[prop]
  }

  return arr.filter((x, i, self) => i === self.findIndex(y => iteratee(x) === iteratee(y)))
}

export const compareByEgality = (item1, item2) => item1 === item2
export const compareById = (item1, item2) => item1?.id === item2?.id

export const difference = (arr1 = [], arr2 = [], comparator = compareByEgality) =>
  arr1.filter(item1 => !arr2.some(item2 => comparator(item1, item2)))

export const take = (arr = [], qty = 1) => [...arr].splice(0, qty)

export const sortBy = (array, key, order = 'asc') =>
  array.sort((a, b) => {
    if (typeof key === 'function') {
      const aValue = key(a)
      const bValue = key(b)

      const desc = order === 'desc'

      if (aValue < bValue) return desc ? 1 : -1
      if (aValue > bValue) return desc ? -1 : 1

      return 0
    }
    const aValue = a[key]
    const bValue = b[key]

    const desc = order === 'desc'

    if (aValue < bValue) return desc ? 1 : -1
    if (aValue > bValue) return desc ? -1 : 1

    return 0
  })

export const times = (length = 0) => Array.from({ length }, (_, x) => x)

export const groupBy = (arr, predicate) =>
  arr.reduce((acc, item) => {
    const key = predicate(item)
    acc[key] ? acc[key].push(item) : (acc[key] = [item])
    return acc
  }, {})

export const intersection = (arr, ...args) => arr.filter(item => args.every(arr => arr.includes(item)))

export const range = (start, end, increment) => {
  const isEndDef = typeof end !== 'undefined'
  end = isEndDef ? end : start
  start = isEndDef ? start : 0

  if (typeof increment === 'undefined') {
    increment = Math.sign(end - start)
  }

  const length = Math.abs((end - start) / (increment || 1))

  const { result } = Array.from({ length }).reduce(
    ({ result, current }) => ({
      result: [...result, current],
      current: current + increment
    }),
    { current: start, result: [] }
  )

  return result
}

export const transformElementToArray = stringOrArray => (Array.isArray(stringOrArray) ? stringOrArray : [stringOrArray])
