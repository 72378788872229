import { isEmpty } from './lang'

export const pick = (inputObject, keysToLookup) => {
  const keysArray = Array.isArray(keysToLookup) ? keysToLookup : [keysToLookup]

  if (!inputObject) return {}

  return keysArray.reduce((obj, key) => {
    const nestedKeys = key.split('.')
    let nestedObject = inputObject
    let targetObject = obj

    for (let i = 0; i < nestedKeys.length; i++) {
      const nestedKey = nestedKeys[i]
      if (typeof nestedObject === 'object' && nestedKey in nestedObject) {
        if (i === nestedKeys.length - 1) {
          // Last key in the path, assign the value to the target object.
          targetObject[nestedKey] = nestedObject[nestedKey]
        } else {
          // Not the last key, create nested objects if they don't exist.
          targetObject[nestedKey] = targetObject[nestedKey] || {}
          nestedObject = nestedObject[nestedKey]
          targetObject = targetObject[nestedKey]
        }
      } else if (i === 0 && key in nestedObject) {
        // copy all tree
        targetObject[key] = nestedObject[key]
        break
      } else {
        break
      }
    }

    return obj
  }, {})
}

export const isEmptyObj = object => isEmpty(pickBy(object, v => !isEmpty(v)))

export const getObjectValueByPath = (obj, path) => {
  const keys = path.split(/[.[\]]+/).filter(Boolean)
  let value = obj

  for (const key of keys) {
    if (value) {
      if (Array.isArray(value) && /^\d+$/.test(key)) {
        // Handle array index access
        const index = parseInt(key, 10)
        if (index < value.length) {
          value = value[index]
        } else {
          return undefined // Array index out of bounds
        }
      } else if (value.hasOwnProperty(key)) {
        value = value[key]
      } else {
        return undefined // Property not found
      }
    } else {
      return undefined // Property not found
    }
  }

  return value
}

export const merge = (...objects) => {
  const merged = Array.isArray(objects[0]) ? [] : {}

  for (const obj of objects) {
    for (const key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        if (!merged[key]) {
          merged[key] = Array.isArray(obj[key]) ? [] : {}
        }
        merged[key] = merge(merged[key], obj[key])
      } else {
        if (Array.isArray(merged)) {
          merged.push(obj[key])
        } else {
          merged[key] = obj[key]
        }
      }
    }
  }
  return merged
}

export const mergeObjectsAndConcatenateArrayProperties = (...objects) => {
  const merged = Array.isArray(objects[0]) ? [] : {}

  for (const obj of objects) {
    for (const key in obj) {
      if (Array.isArray(obj[key]) && obj[key] !== null) {
        if (merged[key] !== undefined) {
          merged[key] = merged[key].concat(obj[key])
        } else {
          merged[key] = obj[key]
        }
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        if (!merged[key]) {
          merged[key] = Array.isArray(obj[key]) ? [] : {}
        }
        merged[key] = mergeObjectsAndConcatenateArrayProperties(merged[key], obj[key])
      } else {
        if (Array.isArray(merged)) {
          merged.push(obj[key])
        } else {
          merged[key] = obj[key]
        }
      }
    }
  }
  return merged
}

export const omit = (obj, props) => {
  if (!props || !Array.isArray(props)) return obj ?? {}

  obj = { ...obj }
  props?.forEach(prop => delete obj[prop])

  return obj
}

export const omitBy = (obj, check) => {
  if (!check || !obj) return obj ?? {}

  obj = { ...obj }
  Object.entries(obj).forEach(([key, value]) => check(value) && delete obj[key])

  return obj
}

export const pickBy = (obj, check) => {
  if (!check || !obj) return obj ?? {}

  obj = { ...obj }
  Object.entries(obj).forEach(([key, value]) => !check(value) && delete obj[key])

  return obj
}

export const mapValues = (array = [], fnKey) => Object.fromEntries(Object.entries(array).map(([k, o]) => [k, fnKey(o)]))

export const invert = (object = {}) =>
  Object.entries(object).reduce((acc, current) => {
    acc[current[1]] = current[0]
    return acc
  }, {})

export const isObject = maybeObject => maybeObject instanceof Object

export const findKey = (obj, predicate = o => o) => Object.keys(obj).find(key => predicate(obj[key], key, obj))

export const matches = source => obj => {
  for (const key in source) {
    if (source.hasOwnProperty(key) && source[key] !== obj[key]) {
      return false
    }
  }
  return true
}
