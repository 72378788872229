import debug from 'debug'
import {
  ROUTE_ITINERARY_COMPUTING,
  ROUTE_ITINERARY_RESULTS_BY_PROVIDER,
  ROUTE_ITINERARY_RESULTS_BY_ROUTE
} from '../../../routes'
import {
  selectCurrentHistoryFullRoute,
  selectCurrentHistoryRoute,
  selectIsPreviousHistoryRoutePageless,
  selectShouldAvoidSendPageTag
} from '../../history/history.selectors'
import { RI_AFFICHAGE_ITI, SEND_PAGE_TAG as SEND_PAGE_TAG_V3 } from '../analytics.actionTypes'
import { HISTORY_NAVIGATION_DONE } from '../../history/history.actionTypes'
import { dispatchTagPageV3 } from '../analytics.actions'
import { sendEventTag } from '../../../domain/analytics/ATInternetService'
import {
  getCurrentModeAndProviderForDisplayTagObj,
  getItinerariesActiveSort,
  getItinerariesParsedDetailsObj,
  getItineraryPoiTypesObj,
  getItineraryZipCodesObj,
  getNumberOfDisplayedItinerariesObj,
  getPageActiveObj,
  getQidObj
} from '../../../domain/analytics/analyticsUtils'
import { TAG_TYPES, configureTagEvent } from './tagsV3.middlewares'
import {
  MODE_ID,
  NUMBER_OF_DISPLAYED_ROUTES_RESULTS,
  PAGE_ACTIVE,
  POI_ARRIVAL_TYPE,
  POI_DEPARTURE_TYPE,
  PROVIDER_ID,
  QID,
  ROUTE_RESULTS_DURATIONS,
  ROUTE_RESULTS_LABELS,
  ROUTE_RESULTS_LENGTHS,
  ROUTE_RESULTS_ORDER_TYPE,
  ROUTE_RESULTS_PRICES,
  ZIP_ARRIVAL,
  ZIP_DEPARTURE
} from '../../../domain/analytics/ClickTagsService'

const d = debug('clickTagsV3Middelware')

export const ROUTE_TO_PAGE_TAG = {
  [ROUTE_ITINERARY_RESULTS_BY_ROUTE]: () => {
    return {
      tag: {
        tag_id: RI_AFFICHAGE_ITI,
        event_name: 'affichage.Itineraire',
        mode_de_transport: `#${MODE_ID}_#${PROVIDER_ID}`,
        nombre_resultats: `!${NUMBER_OF_DISPLAYED_ROUTES_RESULTS}`,
        code_postal_depart: `#${ZIP_DEPARTURE}`,
        code_postal_arrivee: `#${ZIP_ARRIVAL}`,
        type_depart: `#${POI_DEPARTURE_TYPE}`,
        type_arrivee: `#${POI_ARRIVAL_TYPE}`,
        libelle_resultats: `#${ROUTE_RESULTS_LABELS}`,
        tarif_resultats: `#${ROUTE_RESULTS_PRICES}`,
        duree_resultats: `#${ROUTE_RESULTS_DURATIONS}`,
        distance_resultats: `#${ROUTE_RESULTS_LENGTHS}`,
        id_recherche_iti: `#${QID}`,
        tri_resultats: `#${ROUTE_RESULTS_ORDER_TYPE}`,
        page: `#${PAGE_ACTIVE}`
      },
      paramFns: [
        getPageActiveObj,
        getCurrentModeAndProviderForDisplayTagObj,
        getItineraryZipCodesObj,
        getItineraryPoiTypesObj,
        getQidObj,
        getNumberOfDisplayedItinerariesObj,
        getItinerariesParsedDetailsObj,
        getItinerariesActiveSort
      ]
    }
  }
}

const tagPage = ({ getState, action }) => {
  const store = getState()
  const route = selectCurrentHistoryRoute(store)
  const fullRoute = selectCurrentHistoryFullRoute(store)
  const { configuredTag } = configureTagEvent({ action, store, route }, TAG_TYPES.PAGE)

  if (
    route === ROUTE_ITINERARY_RESULTS_BY_PROVIDER ||
    route === ROUTE_ITINERARY_COMPUTING ||
    selectShouldAvoidSendPageTag(store)
  ) {
    // do not send tag page for navigation with special attribut or
    // for "calculation" routes because they don't have physically page.
    return d(`skipping tagging for ${fullRoute}`)
  }

  if (!configuredTag) return d(`No tag have been defined for ${fullRoute}`)

  if (configuredTag) {
    d('sending page tag V3 ', configuredTag)
    const event_name = configuredTag.event_name ?? 'affichage.page'
    sendEventTag(event_name, configuredTag)
  }
}

export const pageTagsMiddleware =
  ({ getState }) =>
  next =>
  action => {
    const r = next(action)
    if (__BROWSER__) {
      switch (action.type) {
        case SEND_PAGE_TAG_V3:
          if (selectIsPreviousHistoryRoutePageless(getState())) return r

          d('SEND_PAGE_TAG_V3 for route', selectCurrentHistoryRoute(getState()))
          try {
            tagPage({ getState, action })
          } catch (error) {
            console.error(error)
          }
      }
    }

    return r
  }

export const sendPageTagOnNavigationDone =
  ({ dispatch }) =>
  next =>
  action => {
    if (action.type === HISTORY_NAVIGATION_DONE) {
      dispatchTagPageV3(action)(dispatch)
    }

    return next(action)
  }

export default [pageTagsMiddleware, sendPageTagOnNavigationDone]
