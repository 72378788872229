import { selectIsItineraryUniverse, selectIsSearchOnItineraryRoute } from '../history/history.selectors'
import { MAP_UPDATE } from '../map/map.actionTypes'
import { refreshPoisOnRoute } from './itinerary.poisOnRoute.actions'
import { DELAY_BEFORE_SEARCH_GEOENTITIES, THRESHOLD_DISTANCE_IN_PX_TO_REFRESH_POI } from '../search/search.middlewares'
import { SET_SINGLE_GEOENTITY } from '../geoentity/geoentity.actionTypes'
import { SHOW_UI_ELEMENT } from '../ui/ui.actionTypes'
import { UI_ELEMENTS } from '../ui/ui.constants'
import { selectOptionsForRequestMove, selectSelectedGeoentityLatLng } from '../geoentity/geoentity.selectors'
import { requestMove } from '../map/map.actions'
import { debounce } from '../../utils/function'

const debouncedRefreshPoisOnRoute = debounce((dispatch, getState) => {
  refreshPoisOnRoute()(dispatch, getState)
}, DELAY_BEFORE_SEARCH_GEOENTITIES)

export const refreshPoisOnRouteWhenMapMoved =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    if (selectIsSearchOnItineraryRoute(getState()) && action.type === MAP_UPDATE) {
      const { distanceFromPreviousChange, fromAppAction } = action.payload
      const shouldRefresh = !fromAppAction && distanceFromPreviousChange > THRESHOLD_DISTANCE_IN_PX_TO_REFRESH_POI
      if (shouldRefresh) {
        debouncedRefreshPoisOnRoute(dispatch, getState)
      }
    }

    return r
  }

export const handleMapPositioningOnGeoentityOnRouteSelected =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    const isItineraryUniverse = selectIsItineraryUniverse(getState())
    const actionIsSetSingleGeoentity = action.type === SET_SINGLE_GEOENTITY
    const actionIsShowPopin = action.type === SHOW_UI_ELEMENT && action.payload === UI_ELEMENTS.POPIN

    if (isItineraryUniverse && (actionIsSetSingleGeoentity || actionIsShowPopin)) {
      const options = selectOptionsForRequestMove(getState())
      const center = selectSelectedGeoentityLatLng(getState())
      if (center && center.lat) requestMove({ center, options })(dispatch, getState)
    }

    return r
  }

export default [refreshPoisOnRouteWhenMapMoved, handleMapPositioningOnGeoentityOnRouteSelected]
