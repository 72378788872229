import {
  SET_AB_TEST_LOADING,
  SET_AB_TEST_CONFIG,
  SET_AB_TEST_ENROLLMENT,
  SET_AB_TEST_READY
} from './abtest.actionTypes'
import { AB_TEST_STATUS_READY, AB_TEST_STATUS_INITIAL, AB_TEST_STATUS_LOADING } from './abtest.constants'

export const getInitialState = () => ({
  status: AB_TEST_STATUS_INITIAL,
  config: [],
  enrollment: []
})

export default function abTestReducer(state = getInitialState(), action) {
  const payload = action?.payload
  switch (action && action.type) {
    case SET_AB_TEST_LOADING:
      return { ...state, status: AB_TEST_STATUS_LOADING }
    case SET_AB_TEST_CONFIG:
      return { ...state, config: payload }
    case SET_AB_TEST_ENROLLMENT:
      return { ...state, enrollment: payload }
    case SET_AB_TEST_READY:
      return { ...state, status: AB_TEST_STATUS_READY }
    default:
      return state
  }
}
