import {
  SET_DEPARTURE_NOW,
  SET_DEPARTURE_LATER,
  SET_ARRIVAL_LATER,
  SET_DATE,
  SET_TIME
} from './travelTimeOptions.actionTypes'

export const setDepartureNow = () => dispatch => {
  dispatch({
    type: SET_DEPARTURE_NOW
  })
}

export const setDepartureLater = () => dispatch => {
  dispatch({
    type: SET_DEPARTURE_LATER
  })
}

export const setArrivalLater = () => dispatch => {
  dispatch({
    type: SET_ARRIVAL_LATER
  })
}

export const setDate = date => dispatch => {
  dispatch({
    type: SET_DATE,
    payload: { date }
  })
}

export const setTime = time => dispatch => {
  dispatch({
    type: SET_TIME,
    payload: { time }
  })
}
