import React from 'react'
import { useSelector } from 'react-redux'
import { selectIsMapReady } from '../../store/map/map.selectors'
import { isNumber } from '../../utils/number'
import { getSearchPadding } from './map.padding.utils'

export const MAP_FROM_APP_ACTION_MOVE = 'move'
export const MAP_FROM_APP_ACTION_PADDING = 'padding'

/* eslint-disable react/display-name */
export const withMapReady = Component => props => {
  const ready = useSelector(selectIsMapReady)

  return ready ? <Component {...props} /> : <></>
}

export const getMapActiveDimensions = map => {
  const mapCanvas = map.getCanvas()
  const padding = map.getPadding()
  const width = (mapCanvas.offsetWidth || mapCanvas.width) - padding.left - padding.right
  const height = (mapCanvas.offsetHeight || mapCanvas.height) - padding.top - padding.bottom
  return { width, height }
}

export const pixelBoxToBbox = (map, pixelBox) =>
  pixelBox.map(point => map.unproject(point)).map(({ lng, lat }) => [lng, lat])

export const computeMapActiveBbox = map => {
  const { width, height } = getMapActiveDimensions(map)
  const padding = map.getPadding()
  return pixelBoxToBbox(map, [
    [padding.left, padding.top + height],
    [padding.left + width, padding.top]
  ])
}

export const computeSearchBbox = map => {
  const { width, height } = getMapActiveDimensions(map)
  const padding = map.getPadding()
  const searchPadding = getSearchPadding()

  const searchWidth = width + padding.left + padding.right - searchPadding.left - searchPadding.right
  const searchHeight = height + padding.top + padding.bottom - searchPadding.top - searchPadding.bottom

  const xDelta = (padding.left - searchPadding.left + searchPadding.right - padding.right) / 2
  const yDelta = (padding.top - searchPadding.top + searchPadding.bottom - padding.bottom) / 2

  const xLeft = searchPadding.left + xDelta
  const xRight = xLeft + searchWidth
  const yTop = searchPadding.top + yDelta
  const yBottom = yTop + searchHeight

  return pixelBoxToBbox(map, [
    [xLeft, yBottom],
    [xRight, yTop]
  ])
}

export const bboxContainsPoints = (bbox, points) => {
  if (!bbox) return false
  const [[west, south], [east, north]] = bbox
  return points.every(([lng, lat]) => lng >= west && lng <= east && lat >= south && lat <= north)
}

export const bboxContainsViewport = (bbox, viewport = []) =>
  bboxContainsPoints(bbox, [viewport.slice(0, 2), viewport.slice(2)])

const isATwoItemsArray = array => Array.isArray(array) && array.length === 2

const isFiniteNumber = coord => isNumber(coord) && Math.abs(coord) !== Infinity

export const pointIsValid = point => isATwoItemsArray(point) && isFiniteNumber(point[0]) && isFiniteNumber(point[1])

export const bboxIsValid = (bbox = []) => isATwoItemsArray(bbox) && pointIsValid(bbox[0]) && pointIsValid(bbox[1])

export const computePixelsDeltaBetweenBboxes = (mapInstance, bboxA, bboxB) => {
  const { x: xA, y: yA } = mapInstance.project(bboxA[0])
  const { x: xB, y: yB } = mapInstance.project(bboxB[0])
  return Math.sqrt(Math.pow(xA - xB, 2) + Math.pow(yA - yB, 2))
}

export const isWebglSupported = () => {
  if (window.WebGLRenderingContext) {
    const canvas = document.createElement('canvas')
    try {
      const context = canvas.getContext('webgl2') || canvas.getContext('webgl')
      if (context && typeof context.getParameter === 'function') {
        return true
      }
    } catch (e) {
      // WebGL is supported, but disabled
      console.error('isWebglSupported error', e)
    }
    return false
  }
  // WebGL not supported
  console.error('isWebglSupported no support')
  return false
}
