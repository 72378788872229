export const LOAD_ADDRESS_REQUEST = 'LOAD_ADDRESS_REQUEST'
export const LOAD_ADDRESS_ERROR = 'LOAD_ADDRESS_ERROR'
export const REMOVE_ADDRESS_ERROR = 'REMOVE_ADDRESS_ERROR'
export const LOAD_MULTI_ADDRESS_SUCCESS = 'LOAD_MULTI_ADDRESS_SUCCESS'
export const LOAD_SINGLE_ADDRESS_SUCCESS = 'LOAD_SINGLE_ADDRESS_SUCCESS'
export const LOAD_SINGLE_ADDRESS_COMPUTE_RADIUS_SUCCESS = 'LOAD_SINGLE_ADDRESS_COMPUTE_RADIUS_SUCCESS'
export const ADD_CONTOUR_TO_FIRST_ADDRESS = 'ADD_CONTOUR_TO_FIRST_ADDRESS'
export const RESET_ADDRESS = 'RESET_ADDRESS'
export const LOAD_DESCRIPTION = 'LOAD_DESCRIPTION'
export const RESET_DESCRIPTION = 'RESET_DESCRIPTION'
export const LOAD_AREA_FROM_GEOCODE = 'LOAD_AREA_FROM_GEOCODE'
