import {
  getBookingSearchExtensionDesktopAid,
  getBookingSearchExtensionDesktopLabel,
  getBookingSearchExtensionMobileAid,
  getBookingSearchExtensionMobileLabel
} from '../../parameters'
import { selectFilledSteps, isStepFilled, selectDepartureStep, selectArrivalStep } from './steps/steps.selectors'
import { getTomorrowDateISO, getFutureDateISO } from '../utils/date'
import { PUB_TAG, resolveClickPublisherTag } from '../analytics/PublisherTagsService'
import { sendPublisherTag } from '../analytics/ATInternetService'
import { getDistanceByFlyCrow } from '../utils/map'
import { selectIsLastTimeAdSearchExtensionDisplayedMoreThan1Day } from '../../store/ad/ad.selectors'
import { markLastTimeAdSearchExtensionDisplayed } from '../../store/ad/ad.actions'
import { changeDocumentLocation } from '../../store/history/history.utils'
import { isGE } from '../Device'
import { isNumber } from '../../utils/number'

const RE_INSEECODE = /(-\d{5})/

const removeInseeCode = str => str.replace(RE_INSEECODE, '')

export const buildBookingUrl = (step, timestamp = new Date()) => {
  const aid = isGE() ? getBookingSearchExtensionDesktopAid() : getBookingSearchExtensionMobileAid()
  const label = isGE() ? getBookingSearchExtensionDesktopLabel() : getBookingSearchExtensionMobileLabel()

  const searchStr = step?.town ?? false ? `${step?.town ?? ''} ${step?.postcode ?? ''}` : removeInseeCode(step?.label)
  const parameters = [
    `aid=${aid}`,
    `checkin=${getTomorrowDateISO(timestamp)}`,
    `checkout=${getFutureDateISO({ days: 2 }, timestamp)}`,
    `ss=${searchStr}`,
    `label=${label}-click_brand-partner`,
    'selected_currency=EUR'
  ]
  return `//booking.com/searchresults.html?${parameters.join('&')}`
}

export const MINIMAL_DISTANCE_IN_KM = 50

class AdSearchExtension {
  constructor(store) {
    this.popup = null
    this.store = store
  }

  setStore(store) {
    this.store = store
  }

  isEnabled = () => __BROWSER__ && selectIsLastTimeAdSearchExtensionDisplayedMoreThan1Day(this.store.getState())

  openPopup = ({ steps, idx, departureNow }) => {
    if (
      this.isEnabled() &&
      departureNow &&
      steps.length === 2 &&
      ((isStepFilled(selectDepartureStep(null, { steps })) && idx === 1) ||
        (isStepFilled(selectArrivalStep(null, { steps })) && idx === 0))
    ) {
      markLastTimeAdSearchExtensionDisplayed()(this.store.dispatch)
      this.popup = window.open('about:blank')
    }
  }

  closePopup = () => {
    if (this.popup) this.popup.close()
    this.popup = undefined
  }

  getFlyCrowDistanceBetweenSteps = steps => {
    if (steps && steps.length === 2) {
      const lat1 = steps?.[0]?.coordinates?.lat
      const lng1 = steps?.[0]?.coordinates?.lng
      const lat2 = steps?.[1]?.coordinates?.lat
      const lng2 = steps?.[1]?.coordinates?.lng
      if (isNumber(lat1) && isNumber(lng1) && isNumber(lat2) && isNumber(lng2)) {
        return getDistanceByFlyCrow(lng1, lat1, lng2, lat2)
      }
    }
    return 0
  }

  search = ({ steps }) =>
    new Promise((resolve, reject) => {
      try {
        const filledSteps = selectFilledSteps(null, { steps })
        if (
          this.popup &&
          filledSteps &&
          filledSteps.length === 2 &&
          this.getFlyCrowDistanceBetweenSteps(filledSteps) >= MINIMAL_DISTANCE_IN_KM
        ) {
          const lastStep = selectArrivalStep(null, { steps })
          const arrivalLabel = lastStep?.label
          const departureLabel = selectDepartureStep(null, { steps })?.label
          if (arrivalLabel && departureLabel) {
            sendPublisherTag(resolveClickPublisherTag(PUB_TAG.PUB_MULTIPATH_BOOKING_SEARCH_EXTENSION))
            this.popup.location = `/itineraire#/recherche/${encodeURIComponent(departureLabel)}/${encodeURIComponent(
              arrivalLabel
            )}`
            changeDocumentLocation(buildBookingUrl(lastStep))
            return reject(new Error('AdSearchExtension redirect should stop execution')) // never throw if correctly redirected
          }
        } else {
          // if not enough, too much steps or steps too close, closing popup and continue search
          this.closePopup()
          return resolve()
        }
      } catch (e) {
        // on any problem, close the popup and let the search be launched as usual
        this.closePopup()
        return resolve()
      }
    })
}

export const AdSearchExtensionClass = AdSearchExtension
export default new AdSearchExtension()
