import {
  MULTIPATH_RESET,
  MULTIPATH_RESET_EXCEPT_STEPS,
  MULTIPATH_RESET_ROUTES,
  SET_STEP_IDX,
  SET_STEP_LOCATION,
  SET_CURRENT_STEPS,
  ADD_ROUTES,
  MULTIPATH_COMPUTE,
  MULTIPATH_RECOMPUTE,
  MULTIPATH_COMPLETE,
  ROUTE_NOT_FOUND_ERROR,
  SET_CURRENT_PROVIDER,
  SET_CURRENT_ROUTE,
  SET_CURRENT_MODE,
  SET_PROVIDERS,
  SET_CURRENT_ROADBOOK,
  RESET_ROADBOOK,
  DROP_PROVIDER_ROUTES,
  SET_STEP_RESOLVED_LOCATION,
  REMOVE_PROVIDER_OPTIONAL_OR_SIMPLIFIED_STATUS,
  ROUTE_API_UNKNOW_ERROR,
  MULTIPATH_COMPLETE_PROVIDER,
  MULTIPATH_COMPUTE_PROVIDER,
  ADD_STEP,
  REMOVE_STEP,
  INVERT_STEPS,
  SET_POIS_ON_ROUTE,
  RESET_POIS_ON_ROUTE,
  ROADBOOK_ERROR,
  SET_PROVIDERS_COLORS,
  SET_ACTIVE_SORT,
  RESET_ACTIVE_SORT,
  SET_ROADBOOK_TIMESTAMP,
  SET_ITINERARY_AVAILABILITY_INFO,
  ROADBOOK_EXPIRED
} from './itinerary.actionTypes'
import { parseErrorFromReduxAction } from '../../domain/error/errorParser'
import { getProviderMode } from './itinerary.selectors'
import { head, last } from '../../utils/array'
import { merge } from '../../utils/object'

export const getInitialState = () => {
  return {
    idx: undefined,
    steps: [{ ui: { stepId: 'departure' } }, { ui: { stepId: 'arrival' } }],
    routes: [],
    currentMode: undefined,
    currentProvider: undefined,
    currentRouteId: 0,
    isComputingDone: true,
    computedCount: 0,
    activeSort: undefined,
    isRoadbookExpired: false
  }
}

const buildStep = (steps = [], addr = {}, idx, action) => {
  const stepsCopy = JSON.parse(JSON.stringify(steps))
  const newSteps = steps.slice()
  const {
    coordinates,
    label,
    split_label,
    type,
    postcode,
    regionCode,
    departmentCode,
    countryCode,
    towncode,
    town,
    rubric,
    geocode_level,
    mergedCity,
    townWithoutDistrict
  } = addr

  const from = action.type === SET_STEP_RESOLVED_LOCATION ? stepsCopy[idx].from : addr.from

  newSteps[idx] = {
    ...newSteps[idx],
    ...{
      coordinates,
      label,
      split_label,
      type,
      postcode,
      regionCode,
      departmentCode,
      countryCode,
      towncode,
      town,
      rubric,
      geocode_level,
      mergedCity,
      townWithoutDistrict,
      from
    }
  }
  return newSteps
}

const removeStep = (steps = [], stepId) => {
  const newSteps = steps.filter(step => step.ui.stepId !== stepId)
  for (let i = 1; i < newSteps.length - 1; i++) {
    newSteps[i].ui.stepId = `step-${i}`
  }
  return newSteps
}

const invertSteps = (steps = []) => {
  return steps
    .slice()
    .reverse()
    .map((step, idx) => ({ ...step, ui: { ...steps[idx].ui } }))
}

const addStep = (steps = []) => {
  const newSteps = steps.slice()
  const n = newSteps.length - 1
  newSteps.splice(-1, 0, {
    ui: {
      stepId: `step-${n}`
    }
  })
  return newSteps
}

const getFirstRouteIdForProvider = (provider, routes) => {
  const firstRoute = routes.find(route => route?.provider?.id === provider)
  return firstRoute?.routeId
}

const getProviderForRouteId = (routeId, routes) => {
  const firstRoute = routes.find(route => route?.routeId === routeId)
  return firstRoute?.provider.id
}

const resetProviderOptionalOrSimplifiedStatus = (providers, providerName) => {
  const provider = (providers || []).find(provider => provider.name === providerName)
  const providerIdx = providers.indexOf(provider)
  const newProvider = {
    ...provider,
    optional: false,
    simplified: false
  }

  return [...providers.slice(0, providerIdx), newProvider, ...providers.slice(providerIdx + 1)]
}

export default function itineraryReducer(state = getInitialState(), action) {
  switch (action?.type) {
    case MULTIPATH_RESET:
      return {
        ...getInitialState(),
        computedCount: state.computedCount
      }
    case MULTIPATH_RESET_EXCEPT_STEPS:
      return {
        ...getInitialState(),
        steps: state.steps,
        computedCount: state.computedCount
      }
    case MULTIPATH_RESET_ROUTES:
      return {
        ...state,
        currentRouteId: 0,
        routes: []
      }
    case SET_STEP_IDX:
      return {
        ...state,
        idx: action?.payload?.idx
      }
    case SET_STEP_LOCATION:
    case SET_STEP_RESOLVED_LOCATION:
      const currentIdx = state.idx
      const addr = action?.payload?.data?.addresses?.[0]
      return {
        ...state,
        idx: undefined,
        steps: buildStep(state.steps, addr, currentIdx, action)
      }
    case SET_CURRENT_STEPS:
      const fromAddress = action?.payload?.steps?.fromAddress
      const from = action?.payload?.steps?.from
      const toAddress = action?.payload?.steps?.toAddress
      const to = action?.payload?.steps?.to
      const steps = merge(getInitialState().steps, [
        fromAddress || (from ? { label: from } : head(state.steps)),
        toAddress || (to ? { label: to } : last(state.steps))
      ])
      return {
        ...state,
        steps
      }
    case ADD_STEP:
      return {
        ...state,
        steps: addStep(state.steps)
      }
    case REMOVE_STEP:
      return {
        ...state,
        steps: removeStep(state.steps, action?.payload)
      }
    case INVERT_STEPS:
      return {
        ...state,
        steps: invertSteps(state.steps)
      }
    case MULTIPATH_COMPUTE:
    case MULTIPATH_RECOMPUTE:
      return {
        ...state,
        routes: [],
        routeError: null,
        error: null,
        isComputingDone: false
      }

    case MULTIPATH_COMPUTE_PROVIDER:
      return {
        ...state,
        isComputingDone: false
      }
    case MULTIPATH_COMPLETE:
      return {
        ...state,
        isComputingDone: true,
        computedCount: state.computedCount + 1
      }
    case MULTIPATH_COMPLETE_PROVIDER:
      return {
        ...state,
        isComputingDone: true
      }
    case ADD_ROUTES:
      const routes = state.routes.concat(action?.payload?.routes ?? [])
      return {
        ...state,
        routes
      }
    case DROP_PROVIDER_ROUTES:
      const providerName = action?.payload?.providerName
      const filteredRoutes = state.routes.filter(route => route.provider.id !== providerName)
      return {
        ...state,
        routes: filteredRoutes
      }
    case SET_CURRENT_PROVIDER:
      const provider = action?.payload?.provider
      return {
        ...state,
        currentMode: getProviderMode({ providers: state.providers, currentProvider: provider }),
        currentProvider: provider,
        currentRouteId: getFirstRouteIdForProvider(provider, state.routes)
      }
    case SET_CURRENT_ROUTE:
      const routeId = action?.payload?.routeId
      const currentProvider = getProviderForRouteId(routeId, state.routes)
      return {
        ...state,
        currentMode: getProviderMode({ providers: state.providers, currentProvider }),
        currentProvider,
        currentRouteId: routeId
      }
    case SET_PROVIDERS:
      return {
        ...state,
        providers: action.payload
      }
    case REMOVE_PROVIDER_OPTIONAL_OR_SIMPLIFIED_STATUS: {
      return {
        ...state,
        providers: resetProviderOptionalOrSimplifiedStatus(state.providers, action.payload.providerName)
      }
    }
    case SET_CURRENT_ROADBOOK:
      return {
        ...state,
        roadbook: action.payload,
        isRoadbookExpired: false
      }
    case RESET_ROADBOOK:
      return {
        ...state,
        roadbook: null
      }
    case SET_ROADBOOK_TIMESTAMP:
      return {
        ...state,
        roadbookTimestamp: Date.now()
      }
    case SET_POIS_ON_ROUTE:
      return {
        ...state,
        poisOnRoute: action?.payload
      }
    case RESET_POIS_ON_ROUTE:
      return {
        ...state,
        poisOnRoute: null
      }
    case ROUTE_NOT_FOUND_ERROR: {
      const { mode, provider, error } = action.payload
      return {
        ...state,
        routeError: {
          ...state.routeError,
          [mode.id]: {
            errorMessage: error.message,
            origErrorMessage: error,
            provider
          }
        }
      }
    }
    case ROUTE_API_UNKNOW_ERROR:
      return {
        ...state,
        error: parseErrorFromReduxAction(action)
      }
    case SET_CURRENT_MODE: {
      return {
        ...state,
        currentMode: action.payload
      }
    }
    case ROADBOOK_ERROR: {
      return {
        ...state,
        roadbook_error: action.payload
      }
    }
    case ROADBOOK_EXPIRED: {
      return {
        ...state,
        isRoadbookExpired: true
      }
    }
    case SET_PROVIDERS_COLORS:
      return {
        ...state,
        colors: action.payload
      }
    case SET_ACTIVE_SORT:
      return {
        ...state,
        activeSort: action.payload
      }
    case RESET_ACTIVE_SORT:
      return {
        ...state,
        activeSort: undefined
      }
    case SET_ITINERARY_AVAILABILITY_INFO:
      return {
        ...state,
        tcAvailabilityInfo: action.payload
      }
    default:
      return state
  }
}
