import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { requestPano } from '../../dataSource/pano/pano.requests'
import { selectLocale } from '../../store/locale/locale.selectors'

export const OUTDOOR_STATUS_NONE = 'none'
export const OUTDOOR_STATUS_PROMPT = 'prompt'
export const OUTDOOR_STATUS_DISPLAY = 'display'

export const fetchPano = ({ lat, lng, preview }, locale) =>
  requestPano({ lat, lng, preview }, locale).then(result => {
    if (result?.data) return result.data
  })

export const usePano = ({ lat, lng, enabled, preview }) => {
  const [panoData, setPanoData] = useState({})
  const locale = useSelector(selectLocale)

  useEffect(() => {
    if (lat && lng && enabled) {
      fetchPano({ lat, lng, preview }, locale)
        .then(panoData => {
          if (panoData?.panoramicId) {
            setPanoData(panoData)
          }
        })
        .catch(() => setPanoData({}))
    }
  }, [lat, lng, enabled, preview])

  useEffect(() => {
    if (!enabled) {
      setPanoData({})
    }
  }, [enabled])

  return {
    target: {
      lat,
      lng
    },
    ...panoData
  }
}
