import {
  SET_DEPARTURE_NOW,
  SET_DEPARTURE_LATER,
  SET_ARRIVAL_LATER,
  SET_DATE,
  SET_TIME
} from './travelTimeOptions.actionTypes'
import { DEPARTURE_NOW, DEPARTURE_LATER, ARRIVAL_LATER } from './travelTimeOptionsWhenTypes'

import { getCurrentDateISO, getCurrentTime } from '../../domain/utils/date'

export const getInitialState = () => {
  return {
    when: DEPARTURE_NOW,
    datetime: undefined
  }
}

export default function travelTimeOptionsReducers(state = getInitialState(), action) {
  switch (action && action.type) {
    case SET_DEPARTURE_NOW: {
      return getInitialState()
    }
    case SET_DEPARTURE_LATER: {
      const { date, time } = state
      return {
        when: DEPARTURE_LATER,
        date: date || getCurrentDateISO(),
        time: time || getCurrentTime()
      }
    }
    case SET_ARRIVAL_LATER: {
      const { date, time } = state
      return {
        when: ARRIVAL_LATER,
        date: date || getCurrentDateISO(),
        time: time || getCurrentTime()
      }
    }
    case SET_DATE: {
      if (state.when === DEPARTURE_NOW) return state
      return {
        ...state,
        date: action?.payload?.date
      }
    }
    case SET_TIME: {
      if (state.when === DEPARTURE_NOW) return state
      return {
        ...state,
        time: action?.payload?.time
      }
    }
    default:
      return state
  }
}
