import React from 'react'
import { ROUTE_ITINERARY_TO } from '../../routes'
import { navigateTo } from '../../store/history/history.actions'
import { selectSelectedLocationLabelForUrl } from '../../store/location/location.selectors'
import { TAG_GOTO } from '../../store/analytics/analytics.actionTypes'
import { tagBi } from '../analytics/BiTagger'
import { useAction } from '../utils/react-redux'
import useEventCallback from '../utils/useEventCallback'
import RectangleActionButton, { COLOR_BLUE, SIZE_NORMAL } from './buttons/RectangleActionButton'
import { defer } from '../../utils/function'

const GoToItineraryButton = ({ location, tagProps = {}, biProps = {}, onBeforeNavigate, size = SIZE_NORMAL }) => {
  const execNavigateTo = useAction(navigateTo)

  const onClick = useEventCallback(() => {
    if (biProps.tagId) tagBi(biProps.tagId, biProps.params)
    if (onBeforeNavigate) onBeforeNavigate()
    defer(execNavigateTo, {
      route: ROUTE_ITINERARY_TO,
      routeOptions: {
        to: selectSelectedLocationLabelForUrl(null, { location }),
        toAddress: location,
        avoidReset: true
      }
    })
  }, [biProps.tagId, biProps.params, execNavigateTo, location])

  return (
    <RectangleActionButton
      label={'universes.itineraries'}
      iconId={'itinerary'}
      onClick={onClick}
      color={COLOR_BLUE}
      size={size}
      tagData={{
        type: TAG_GOTO,
        ...tagProps
      }}
    />
  )
}

export default GoToItineraryButton
