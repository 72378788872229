import { MAP_REQUEST_MOVE, SET_GEOLOC_POSITION, SET_GEOLOC_STATUS } from './map.actionTypes'
import {
  GEOLOC_STATUS_FETCH_WORKFLOW,
  GEOLOC_STATUS_ACTIVE,
  GEOLOC_STATUS_DISABLED,
  GEOLOC_STATUS_FETCH_ACTIVE,
  GEOLOC_STATUS_FETCH_FOLLOW,
  GEOLOC_STATUS_FOLLOW,
  GEOLOC_STATUS_BLOCKED,
  GEOLOC_MOVE_FROM_FOLLOW,
  GEOLOC_MOVE_FROM_INITIAL
} from './map.constants'
import geoLocationService from '../../geoLocationService'
import { cleanGeolocationAfterFetch, requestMove, setGeolocationPosition, setGeolocationStatus } from './map.actions'
import {
  selectGeolocationFetchOptions,
  selectGeolocationPosition,
  selectGeolocationRequestStatus,
  selectGeolocationStatus
} from './map.selectors'
import { computeBBoxFromCoordinates } from '../../domain/utils/map'
import { findKey } from '../../utils/object'

const BBOX_SIZE_IN_METER_AROUND_GEOLOC_POINT = 1000

const getPositionHandler = (dispatch, getState) => geoPosition => {
  setGeolocationPosition({
    lat: geoPosition.coords.latitude,
    lng: geoPosition.coords.longitude
  })(dispatch, getState)
}

const getErrorHandler = (dispatch, getState) => error => {
  if (error.code === 1) {
    setGeolocationStatus(GEOLOC_STATUS_BLOCKED)(dispatch, getState)
    geoLocationService.clearWatch()
  }
  console.error('geoposition error', error)
}

export const geolocationWatcherMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)
    if (action.type === SET_GEOLOC_STATUS) {
      const status = action.payload.status
      switch (status) {
        case GEOLOC_STATUS_FETCH_ACTIVE:
        case GEOLOC_STATUS_FETCH_FOLLOW:
          geoLocationService.watchPosition({
            positionHandler: getPositionHandler(dispatch, getState),
            errorHandler: getErrorHandler(dispatch, getState)
          })

          break
        case GEOLOC_STATUS_DISABLED:
          geoLocationService.clearWatch()
          break
      }
    }
    return r
  }

export const geolocationStatusMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const oldStatus = selectGeolocationStatus(getState())
    const r = next(action)

    switch (action.type) {
      case SET_GEOLOC_POSITION:
        const newStatus = GEOLOC_STATUS_FETCH_WORKFLOW[oldStatus]
        if (newStatus) {
          const options = selectGeolocationFetchOptions(getState())
          setGeolocationStatus(newStatus)(dispatch, getState)
          cleanGeolocationAfterFetch()(dispatch, getState)
          if (options.mapMove) {
            const bbox = computeBBoxFromCoordinates(BBOX_SIZE_IN_METER_AROUND_GEOLOC_POINT)(action.payload)
            requestMove({ bbox, options: { from: GEOLOC_MOVE_FROM_INITIAL } })(dispatch, getState)
          }
        }
        break
      case MAP_REQUEST_MOVE:
        const from = action?.payload?.options?.from
        if (oldStatus === GEOLOC_STATUS_FOLLOW && ![GEOLOC_MOVE_FROM_FOLLOW, GEOLOC_MOVE_FROM_INITIAL].includes(from)) {
          setGeolocationStatus(GEOLOC_STATUS_ACTIVE)(dispatch, getState)
        }
        break
    }
    return r
  }

export const geolocationFollowMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const oldStatus = selectGeolocationStatus(getState())
    const r = next(action)

    switch (action.type) {
      case SET_GEOLOC_POSITION:
        if (selectGeolocationStatus(getState()) === GEOLOC_STATUS_FOLLOW) {
          requestMove({ center: action.payload, options: { from: GEOLOC_MOVE_FROM_FOLLOW } })(dispatch, getState)
        }
        break
      case SET_GEOLOC_STATUS:
        const status = action.payload.status
        const position = selectGeolocationPosition(getState())
        if (status === GEOLOC_STATUS_FOLLOW && oldStatus === GEOLOC_STATUS_ACTIVE && position) {
          const bbox = computeBBoxFromCoordinates(BBOX_SIZE_IN_METER_AROUND_GEOLOC_POINT)(position)
          requestMove({ bbox, options: { from: GEOLOC_MOVE_FROM_INITIAL } })(dispatch, getState)
        }
        break
    }
    return r
  }

export const processRequestStatusMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)

    if (__BROWSER__) {
      const requestStatus = selectGeolocationRequestStatus(getState())
      if (requestStatus) {
        const fetchStatus = findKey(GEOLOC_STATUS_FETCH_WORKFLOW, value => value === requestStatus)
        const fetchOptions = selectGeolocationFetchOptions(getState())
        if (fetchStatus) {
          cleanGeolocationAfterFetch()(dispatch, getState)
          if (!fetchOptions.doNotRequest) {
            setGeolocationStatus(fetchStatus, fetchOptions)(dispatch, getState)
          }
        }
      }
    }

    return r
  }

export default [
  geolocationWatcherMiddleware,
  geolocationStatusMiddleware,
  geolocationFollowMiddleware,
  processRequestStatusMiddleware
]
