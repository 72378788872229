import { selectItineraryState } from '../../../store/itinerary/itinerary.selectors'
import { head, last } from '../../../utils/array'
import { getSeoStepForLandingPage } from '../../seo/seo'
import { getBboxFromLngLatPoints } from '../../utils/map'
import { createCustomSelector } from '../../utils/react-reselect'
import { getMaxStops } from './steps.utils'

export const extractCoordinates = step => (step?.coordinates?.lat && step?.coordinates?.lng ? step.coordinates : null)

export const selectAllSteps = (store, props) => {
  if (props?.steps) return props.steps
  return store?.itinerary?.steps ?? []
}

export const getStepIdx = createCustomSelector(selectItineraryState, itinerary => itinerary?.idx)

export const selectDepartureStep = createCustomSelector(selectAllSteps, steps => head(steps))

export const selectArrivalStep = createCustomSelector(selectAllSteps, steps => last(steps))

export const selectStopSteps = createCustomSelector(selectAllSteps, steps => steps.slice(1, -1))

export const isStepFilled = step => Boolean(step?.label)

export const selectIsDepartureFilled = createCustomSelector(selectDepartureStep, step => isStepFilled(step))

export const selectFilledSteps = createCustomSelector(selectAllSteps, steps => steps.filter(isStepFilled))

export const areStepsFilled = createCustomSelector(
  selectDepartureStep,
  selectArrivalStep,
  (departureStep, arrivalStep) => Boolean(departureStep?.label && arrivalStep?.label)
)

export const selectSeoDeparture = createCustomSelector(selectDepartureStep, step => getSeoStepForLandingPage(step))

export const selectSeoArrival = createCustomSelector(selectArrivalStep, step => getSeoStepForLandingPage(step))

export const selectStepsLength = createCustomSelector(selectAllSteps, steps => steps.length)

export const selectStopStepsLength = createCustomSelector(selectStopSteps, steps => steps.length)

export const selectStepsCoordinates = createCustomSelector(
  selectDepartureStep,
  selectArrivalStep,
  selectStopSteps,
  (departureStep, arrivalStep, stopSteps) => {
    return {
      departure: extractCoordinates(departureStep),
      arrival: extractCoordinates(arrivalStep),
      stops: stopSteps.map(extractCoordinates)
    }
  }
)

export const selectStopStepIds = createCustomSelector(selectStopSteps, stopsSteps =>
  stopsSteps.map(stop => stop?.ui?.stepId ?? null).filter(stopId => stopId !== null)
)

export const selectBboxFromSteps = createCustomSelector(selectStepsCoordinates, steps => {
  const { departure, arrival } = steps
  return departure && arrival ? getBboxFromLngLatPoints([departure, arrival]) : null
})

export const selectIsMaxStepNumberReached = createCustomSelector(
  selectStopStepIds,
  steps => getMaxStops() <= steps.length
)
