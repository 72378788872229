import {
  CONFIG_PAGE_AD_STATUS_NONE,
  CONFIG_PAGE_AD_STATUS_LOADED,
  CONFIG_PAGE_AD_STATUS_LOADING,
  CONFIG_PAGE_AD_STATUS_REQUESTED
} from './ad.constants'
import {
  PAGE_AD_CONFIG_NONE,
  PAGE_AD_CONFIG_LOADED,
  PAGE_AD_CONFIG_REQUESTED,
  PAGE_AD_CONFIG_LOADING,
  MARK_LAST_TIME_AD_SEARCH_EXTENSION_DISPLAYED,
  SET_AD_DATA,
  REMOVE_AD_DATA
} from './ad.actionTypes'

const getInitialState = () => ({
  textImageAd: {
    pageId: null,
    pageAdConfigLoadStatus: CONFIG_PAGE_AD_STATUS_NONE
  },
  adSearchExtension: {
    lastTimeDisplayed: 0
  },
  adData: []
})

export default function adReducer(state = getInitialState(), action) {
  switch (action && action.type) {
    case PAGE_AD_CONFIG_REQUESTED: {
      return {
        ...state,
        textImageAd: {
          pageAdConfigLoadStatus: CONFIG_PAGE_AD_STATUS_REQUESTED,
          pageId: action.payload
        }
      }
    }

    case PAGE_AD_CONFIG_LOADING: {
      return {
        ...state,
        textImageAd: {
          ...state.textImageAd,
          pageAdConfigLoadStatus: CONFIG_PAGE_AD_STATUS_LOADING
        }
      }
    }

    case PAGE_AD_CONFIG_LOADED: {
      return {
        ...state,
        textImageAd: {
          ...state.textImageAd,
          pageAdConfigLoadStatus: CONFIG_PAGE_AD_STATUS_LOADED
        }
      }
    }

    case PAGE_AD_CONFIG_NONE: {
      return {
        ...state,
        textImageAd: getInitialState().textImageAd
      }
    }

    case MARK_LAST_TIME_AD_SEARCH_EXTENSION_DISPLAYED: {
      return {
        ...state,
        adSearchExtension: {
          lastTimeDisplayed: Date.now()
        }
      }
    }

    case SET_AD_DATA: {
      const { adId, data } = action.payload
      return {
        ...state,
        adData: [
          ...state.adData,
          {
            adId,
            data
          }
        ]
      }
    }

    case REMOVE_AD_DATA: {
      const { adId } = action.payload
      return {
        ...state,
        adData: state.adData.filter(item => item.adId !== adId)
      }
    }

    default:
      return state
  }
}
