import {
  LOAD_ADDRESS_REQUEST,
  LOAD_SINGLE_ADDRESS_SUCCESS,
  LOAD_MULTI_ADDRESS_SUCCESS,
  LOAD_ADDRESS_ERROR,
  ADD_CONTOUR_TO_FIRST_ADDRESS
} from './address.actionTypes'
import { setCurrentSearch, hideCompleteSuggest, showUIElement, hideUIElement } from '../ui/ui.actions'
import { requestMove } from '../map/map.actions'

import { navigateTo } from '../history/history.actions'
import {
  ROUTE_ADDRESS,
  ROUTE_QUIZ_CITIES_DEPARTMENT,
  ROUTE_QUIZ_CITIES_REGION,
  ROUTE_SEO_ADDRESS,
  ROUTE_SEO_COUNTRY,
  ROUTE_SEO_DEPARTMENT,
  ROUTE_SEO_REGION
} from '../../routes'
import { selectAddressContour, selectFirstAddress } from './address.selectors'
import { resetDescription, removeError, resetAddress, searchAddressContour } from './address.actions'
import { selectCurrentSearch } from '../ui/ui.selectors'
import { UI_ELEMENTS } from '../ui/ui.constants'
import { HISTORY_NAVIGATION_DONE } from '../history/history.actionTypes'
import { selectCurrentHistoryRoute } from '../history/history.selectors'
import { projectDistrictZipcodeToWholeCityZipcode } from '../../domain/utils/location'
import { geocodeForwardCtx, geocodeLevelsWithContour, searchServiceCtx } from '../search/search.service.constants'
import { getViewportFromGeometry } from '../../domain/map/shape/map.polygon.utils'
import { bboxArrayToBboxArrays } from '../../domain/map/conversion'

export const handleSearchDisplayingForAddressMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    switch (action.type) {
      case LOAD_MULTI_ADDRESS_SUCCESS:
      case LOAD_ADDRESS_ERROR:
      case LOAD_ADDRESS_REQUEST:
        showUIElement(UI_ELEMENTS.SEARCH_DIALOG)(dispatch, getState)
        break
      case LOAD_SINGLE_ADDRESS_SUCCESS:
        hideUIElement(UI_ELEMENTS.SEARCH_DIALOG)(dispatch, getState)
        break
    }

    return next(action)
  }

export const mapMoveOnAddressLoadingMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    switch (action.type) {
      case LOAD_MULTI_ADDRESS_SUCCESS:
      case LOAD_SINGLE_ADDRESS_SUCCESS:
        const payloadOptions = action?.payload?.options
        if (payloadOptions?.targetCenter) {
          const zoom = payloadOptions?.targetZoom ? { zoom: payloadOptions.targetZoom } : {}
          requestMove({ center: payloadOptions.targetCenter, options: { ...zoom, avoidAnimation: true } })(
            dispatch,
            getState
          )
        } else {
          const payloadData = action?.payload?.data
          if (__BROWSER__ && geocodeLevelsWithContour.includes(payloadData?.addresses?.[0]?.geocode_level)) {
            break
          }
          const viewport = payloadData?.viewport || payloadData?.addresses?.[0]?.bbox
          if (viewport) {
            requestMove({ bbox: bboxArrayToBboxArrays(viewport), options: { avoidAnimation: true } })(
              dispatch,
              getState
            )
          }
        }
        break
      case ADD_CONTOUR_TO_FIRST_ADDRESS:
        requestMove({
          bbox: bboxArrayToBboxArrays(getViewportFromGeometry(action?.payload)),
          options: { avoidAnimation: true }
        })(dispatch, getState)
        break
    }

    return next(action)
  }

export const getRouteForAddress = address => {
  switch (address.geocode_level) {
    case searchServiceCtx.geocodeLevel.admin1:
      return ROUTE_SEO_REGION
    case searchServiceCtx.geocodeLevel.admin7:
      return ROUTE_SEO_DEPARTMENT
    case searchServiceCtx.geocodeLevel.country:
      return ROUTE_SEO_COUNTRY
    case searchServiceCtx.geocodeLevel.town:
    case searchServiceCtx.geocodeLevel.district:
      if (address.postcode && address.town) {
        return ROUTE_SEO_ADDRESS
      }
      return ROUTE_ADDRESS
    default:
      return ROUTE_ADDRESS
  }
}

export const navigateOnAddressMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)
    switch (action.type) {
      case LOAD_SINGLE_ADDRESS_SUCCESS:
        const address = selectFirstAddress(getState())
        if (__BROWSER__ && !selectCurrentSearch(getState())) {
          const label = address?.label
          setCurrentSearch(label)(dispatch, getState)
        }
        const options = action?.payload?.options ?? {}
        if (!options.avoidRedirect) {
          navigateTo({
            route: getRouteForAddress(address),
            selectedStoreState: { address },
            routeOptions: { avoidRefetchingPageData: true }
          })(dispatch, getState)
        }
        break
    }
    return r
  }

export const resetDescriptionAfterSearch =
  ({ dispatch, getState }) =>
  next =>
  action => {
    switch (action.type) {
      case LOAD_MULTI_ADDRESS_SUCCESS:
      case LOAD_SINGLE_ADDRESS_SUCCESS:
        resetDescription()(dispatch, getState)
        break
    }

    return next(action)
  }

export const handleRemoveError =
  ({ dispatch, getState }) =>
  next =>
  action => {
    switch (action.type) {
      case LOAD_ADDRESS_REQUEST:
        removeError()(dispatch, getState)
        break
    }

    return next(action)
  }

export const hideSearchDialogOnSearchToShowPlaceholdersAsap =
  ({ dispatch, getState }) =>
  next =>
  action => {
    switch (action.type) {
      case LOAD_ADDRESS_REQUEST:
        hideCompleteSuggest()(dispatch, getState)
        break
    }

    return next(action)
  }

export const resetSearchDataOnLoadAddressRequest =
  ({ dispatch, getState }) =>
  next =>
  action => {
    switch (action.type) {
      case LOAD_ADDRESS_REQUEST:
        resetAddress()(dispatch, getState)
        break
    }
    return next(action)
  }

const PAGES_WITH_CONTOUR = [
  ROUTE_ADDRESS,
  ROUTE_SEO_ADDRESS,
  ROUTE_SEO_DEPARTMENT,
  ROUTE_SEO_REGION,
  ROUTE_QUIZ_CITIES_REGION,
  ROUTE_QUIZ_CITIES_DEPARTMENT
]

export const searchContourOnNavigationDone =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const r = next(action)
    if (action.type === HISTORY_NAVIGATION_DONE && PAGES_WITH_CONTOUR.includes(selectCurrentHistoryRoute(getState()))) {
      if (selectAddressContour(getState())) return

      const { postcode, town, label, geocode_level } = selectFirstAddress(getState())
      if (geocode_level === searchServiceCtx.geocodeLevel.town) {
        searchAddressContour(`${projectDistrictZipcodeToWholeCityZipcode(postcode)}-${town}`)(dispatch, getState)
      } else if (geocode_level === searchServiceCtx.geocodeLevel.district) {
        searchAddressContour(`${label}`)(dispatch, getState)
      } else if (geocode_level === searchServiceCtx.geocodeLevel.admin7) {
        searchAddressContour(`${label}`, geocodeForwardCtx.filter.county)(dispatch, getState)
      } else if (geocode_level === searchServiceCtx.geocodeLevel.admin1) {
        searchAddressContour(`${label}`, geocodeForwardCtx.filter.state)(dispatch, getState)
      }
    }
    return r
  }

export default [
  handleSearchDisplayingForAddressMiddleware,
  mapMoveOnAddressLoadingMiddleware,
  navigateOnAddressMiddleware,
  resetDescriptionAfterSearch,
  handleRemoveError,
  hideSearchDialogOnSearchToShowPlaceholdersAsap,
  resetSearchDataOnLoadAddressRequest,
  searchContourOnNavigationDone
]
