import { string } from 'prop-types'
import { getCountryByLocale, localeCountryIsFrance, localeLanguageIsFrench } from '../../domain/i18n'
import { createCustomSelector } from '../../domain/utils/react-reselect'

const selectLocaleState = store => store?.locale

export const selectLocale = createCustomSelector(selectLocaleState, locale => locale?.value)
selectLocale.propTypes = string

export const selectIsFrenchSite = createCustomSelector(selectLocale, locale => localeCountryIsFrance(locale))

export const selectIsFrenchLanguage = createCustomSelector(selectLocale, locale => localeLanguageIsFrench(locale))

export const selectCountryForLocale = createCustomSelector(selectLocale, locale => getCountryByLocale(locale))
