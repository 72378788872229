import { search } from '../search/search.actions'
import {
  ADD_CONTOUR_TO_FIRST_ADDRESS,
  LOAD_ADDRESS_ERROR,
  LOAD_ADDRESS_REQUEST,
  LOAD_AREA_FROM_GEOCODE,
  LOAD_DESCRIPTION,
  LOAD_SINGLE_ADDRESS_SUCCESS,
  REMOVE_ADDRESS_ERROR,
  RESET_ADDRESS,
  RESET_DESCRIPTION
} from './address.actionTypes'

import {
  findAddressforGeolocation,
  findLocationForGeolocation,
  geocodeForward
} from '../../dataSource/address/address.request'
import { fetchCityDescription } from '../../dataSource/seo/seo.request'
import { LOCALES } from '../../domain/i18n'
import { getSeoCityPostcode } from '../../domain/seo/seo'
import { hasFictiveZipOfCityWithDistricts, isZipDashCity } from '../../domain/utils/location'
import { selectLocale } from '../locale/locale.selectors'
import { geocodeForwardCtx, searchServiceCtx } from '../search/search.service.constants'
import { setCurrentSearch } from '../ui/ui.actions'
import { selectFirstAddress, selectIsAddressAMunicipality } from './address.selectors'

export const resetAddress = () => dispatch => {
  dispatch({
    type: RESET_ADDRESS
  })
}
export const removeError = () => dispatch => {
  dispatch({
    type: REMOVE_ADDRESS_ERROR
  })
}

const handleError = dispatch => error => {
  dispatch({
    type: LOAD_ADDRESS_ERROR,
    payload: { error }
  })

  throw error
}

export const searchSingleAddress =
  ({ terms, options = {} }) =>
  (dispatch, getState) => {
    dispatch({
      type: LOAD_ADDRESS_REQUEST
    })

    const locale = selectLocale(getState())

    const geographicType = hasFictiveZipOfCityWithDistricts(terms)
      ? geocodeForwardCtx.filter.town
      : isZipDashCity(terms)
      ? geocodeForwardCtx.filter.municipality
      : undefined

    return geocodeForward({ location: terms, locale, geographicType })
      .then(({ addresses, viewport }) =>
        dispatch({
          type: LOAD_SINGLE_ADDRESS_SUCCESS,
          payload: {
            data: {
              addresses,
              viewport
            },
            options
          }
        })
      )
      .catch(handleError(dispatch))
  }

export const searchArea = (location, geographicType) => (dispatch, getState) => {
  const locale = selectLocale(getState())
  return geocodeForward({ location, locale, geographicType })
    .then(({ addresses, viewport }) => {
      dispatch({
        type: LOAD_AREA_FROM_GEOCODE,
        payload: {
          data: {
            addresses,
            viewport
          }
        }
      })
    })
    .catch(handleError(dispatch))
}

export const searchMultipleAddresses = args => (dispatch, getState) => {
  const locale = selectLocale(getState())
  return search({
    ...args,
    searchFilterType: locale === LOCALES.fr_FR ? searchServiceCtx.filter.default : searchServiceCtx.filter.address
  })(dispatch, getState)
}

export const searchLocationFromPoint =
  (lngLat, filter = searchServiceCtx.filter.default) =>
  (dispatch, getState) => {
    dispatch({
      type: LOAD_ADDRESS_REQUEST
    })
    const locale = selectLocale(getState())
    return findLocationForGeolocation({ ...lngLat, filter, contour: 1, locale })
      .then(({ addresses, viewport }) => {
        dispatch({
          type: LOAD_SINGLE_ADDRESS_SUCCESS,
          payload: {
            data: {
              addresses,
              viewport
            }
          }
        })
        if (addresses?.[0]?.contour) {
          dispatch({
            type: ADD_CONTOUR_TO_FIRST_ADDRESS,
            payload: addresses[0].contour
          })
        }
      })
      .then(() => {
        const label = selectFirstAddress(getState())?.label
        if (label) setCurrentSearch(label)(dispatch, getState)
      })
      .catch(handleError(dispatch))
  }

export const searchAddressFromPoint = lngLat => (dispatch, getState) => {
  dispatch({
    type: LOAD_ADDRESS_REQUEST
  })

  const locale = selectLocale(getState())
  return findAddressforGeolocation({ ...lngLat, locale })
    .then(({ addresses, viewport }) => {
      const { lat, lng } = addresses?.[0]?.coordinates || {}
      const options =
        lat && lng
          ? {
              options: {
                targetCenter: [lng, lat],
                targetZoom: 17
              }
            }
          : {}

      dispatch({
        type: LOAD_SINGLE_ADDRESS_SUCCESS,
        payload: {
          data: {
            addresses,
            viewport
          },
          ...options
        }
      })
    })
    .then(() => {
      const label = selectFirstAddress(getState())?.label
      if (label) setCurrentSearch(label)(dispatch, getState)
    })
    .catch(handleError(dispatch))
}

export const searchCityFromPoint = lngLat => (dispatch, getState) =>
  searchLocationFromPoint(lngLat, searchServiceCtx.filter.town)(dispatch, getState)

export const searchDepartmentFromPoint = lngLat => (dispatch, getState) =>
  searchLocationFromPoint(lngLat, searchServiceCtx.filter.county)(dispatch, getState)

export const searchRegionFromPoint = lngLat => (dispatch, getState) =>
  searchLocationFromPoint(lngLat, searchServiceCtx.filter.state)(dispatch, getState)

export const getSearchAddress = () => (__BROWSER__ ? searchMultipleAddresses : searchSingleAddress)

export const searchAddress = getSearchAddress()

export const resetDescription = () => dispatch => {
  dispatch({ type: RESET_DESCRIPTION })
}

export const searchCityDescription = () => (dispatch, getState) => {
  if (!selectIsAddressAMunicipality(getState())) return Promise.resolve()

  const address = { ...selectFirstAddress(getState()) }
  if (address.geocode_level === searchServiceCtx.geocodeLevel.town) {
    address.postcode = getSeoCityPostcode(address.postcode)
  }
  if (!address.postcode) return Promise.resolve()
  return fetchCityDescription(address).then(({ description }) => {
    if (description) {
      dispatch({ type: LOAD_DESCRIPTION, payload: { description } })
    }
  })
}

export const searchAddressContour =
  (location, geographicType = geocodeForwardCtx.filter.municipality) =>
  (dispatch, getState) => {
    const locale = selectLocale(getState())

    return geocodeForward({
      location,
      contour: 1,
      geographicType,
      locale
    })
      .then(({ addresses }) => {
        if (!addresses?.[0]?.contour) return
        dispatch({
          type: ADD_CONTOUR_TO_FIRST_ADDRESS,
          payload: addresses[0].contour
        })
      })
      .catch(error => {
        console.error('search location contour:', error)
      })
  }

export const setSingleAddress = address => dispatch => {
  dispatch({
    type: LOAD_SINGLE_ADDRESS_SUCCESS,
    payload: {
      data: {
        addresses: [address],
        viewport: address?.viewport
      }
    }
  })
}
