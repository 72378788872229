import { createSelector } from 'reselect'

import { doesAdBelongToPage } from '../../domain/advertising/AdConfigService'
import { selectArrivalStep, selectDepartureStep } from '../../domain/itinerary/steps/steps.selectors'
import { bboxArrayToBboxArrays } from '../../domain/map/conversion'
import { ONE_DAY_MS } from '../../domain/utils/date'
import { locationHasCoords } from '../../domain/utils/location'
import {
  ROUTE_ADDRESS,
  ROUTE_HOME,
  ROUTE_ITINERARY_HOME,
  ROUTE_ITINERARY_HP_BY_MODE,
  ROUTE_ITINERARY_TO,
  ROUTE_SEO_ADDRESS
} from '../../routes'
import { isEmpty } from '../../utils/lang'
import { selectFirstAddress, selectViewport } from '../address/address.selectors'
import { selectHasUserConsentedForSendKeywords } from '../cmp/cmp.selectors'
import {
  selectCurrentGeoentityTabBlocksCount,
  selectFirstGeoentity,
  selectGeoentityRubricId,
  selectGeoentityRubricSegment,
  selectSelectedGeoentity
} from '../geoentity/geoentity.selectors'
import { isAddressRoute, selectCurrentHistoryRoute } from '../history/history.selectors'
import {
  MULTIPATH_MODE_CAR,
  MULTIPATH_MODE_MOTORBIKE,
  PROVIDER_DOTT_VAE,
  PROVIDER_MARCEL,
  PROVIDER_SHARED_BIKE
} from '../itinerary/itinerary.constants'
import { selectQid } from '../itinerary/itinerary.selectors'
import {
  selectCurrentItineraryRoute,
  selectCurrentItineraryRouteDistance,
  selectCurrentItineraryRouteMode,
  selectHasToll
} from '../itinerary/routes.selectors'
import { selectMapActiveBbox } from '../map/map.selectors'
import { selectIsGE } from '../tools/tools.selectors'
import { selectPreferredFuelId, selectPreferredVehicleId } from '../travelOptions/travelOptions.selectors'
import {
  CONFIG_PAGE_AD_STATUS_LOADED,
  CONFIG_PAGE_AD_STATUS_REQUESTED,
  MAXIMAL_NB_OF_GEOENTITY_BLOCK_FOR_ADS
} from './ad.constants'

const roadType = {
  highway: 'highway',
  highwaydist: 'highwaydist',
  national_road: 'national_road',
  departmental_road: 'departmental_road'
}

const selectAdState = store => store?.ad

const selectAdTextImage = createSelector(selectAdState, ad => ad?.textImageAd)

const selectAdSearchExtension = createSelector(selectAdState, ad => ad?.adSearchExtension)

export const selectPageIdForAdConfig = createSelector(selectAdTextImage, textImageAd => textImageAd?.pageId)

export const selectPageAdConfigLoadStatus = createSelector(
  selectAdTextImage,
  textImageAd => textImageAd?.pageAdConfigLoadStatus
)

export const selectIsPageAdConfigRequested = createSelector(
  selectPageAdConfigLoadStatus,
  status => status === CONFIG_PAGE_AD_STATUS_REQUESTED
)

export const selectIsCorrespondingPageAdConfigLoaded = createSelector(
  selectPageIdForAdConfig,
  selectPageAdConfigLoadStatus,
  (_, adName) => adName,
  (pageId, pageAdConfigLoadStatus, adName) =>
    doesAdBelongToPage({ pageId, adName }) && pageAdConfigLoadStatus === CONFIG_PAGE_AD_STATUS_LOADED
)

const selectLastTimeAdSearchExtensionDisplayed = createSelector(
  selectAdSearchExtension,
  adSearchExtension => adSearchExtension?.lastTimeDisplayed
)

export const selectIsLastTimeAdSearchExtensionDisplayedMoreThan1Day = createSelector(
  selectLastTimeAdSearchExtensionDisplayed,
  lastTimeDisplayed => Date.now() - lastTimeDisplayed > ONE_DAY_MS
)

const formatLatLng = value => Math.round(value * 1000000) + 90000000

export const buildLocationKeywords = ({ location, suffix = '', suffixLatLng = '' } = {}) => {
  const keywords = {}
  if (location) {
    keywords['pays' + suffix] = location.countryCode
    keywords['dept' + suffix] = location.departmentCode
    keywords['ville' + suffix] = location.town
    keywords['region' + suffix] = location.regionCode
    keywords['x' + suffixLatLng] = formatLatLng(location?.coordinates?.lng)
    keywords['y' + suffixLatLng] = formatLatLng(location?.coordinates?.lat)
  }
  return keywords
}

export const selectAddressKeywords = createSelector(
  selectHasUserConsentedForSendKeywords,
  selectFirstAddress,
  selectFirstGeoentity,
  (consent, address, geoentity) => {
    if (isEmpty(address)) return null
    if (!consent) return {}
    const isVde = geoentity?.vde ?? false
    const rubricId = isVde ? '' : selectGeoentityRubricId({}, { geoentity }) || ''
    return {
      ...buildLocationKeywords({ location: address }),
      cat: rubricId
    }
  }
)

const selectGeoentityKeywords = createSelector(
  selectHasUserConsentedForSendKeywords,
  selectSelectedGeoentity,
  selectGeoentityRubricId,
  selectGeoentityRubricSegment,
  (consent, geoentity, rubricId, segment) => {
    if (isEmpty(geoentity) || !rubricId) return null
    if (!consent) return {}
    return {
      ...buildLocationKeywords({ location: geoentity }),
      cat: rubricId || '',
      ...(Boolean(segment) && { segment })
    }
  }
)

export const selectGeoentityAdsKeywords = createSelector(
  selectGeoentityKeywords,
  selectCurrentGeoentityTabBlocksCount,
  (keywords, blocksCount) => (blocksCount > MAXIMAL_NB_OF_GEOENTITY_BLOCK_FOR_ADS ? null : keywords)
)

export const selectFirstGeoentityKeywords = createSelector(
  selectHasUserConsentedForSendKeywords,
  selectFirstGeoentity,
  (consent, geoentity) => {
    const rubricId = selectGeoentityRubricId({}, { geoentity })
    const segment = selectGeoentityRubricSegment({}, { geoentity })
    if (isEmpty(geoentity) || !rubricId) return null
    if (!consent) return {}
    return {
      ...buildLocationKeywords({ location: geoentity }),
      cat: rubricId || '',
      ...(Boolean(segment) && { segment })
    }
  }
)

const selectItineraryVehicleOptions = createSelector(
  selectCurrentItineraryRouteMode,
  selectPreferredVehicleId('car'),
  selectPreferredFuelId('car'),
  selectPreferredVehicleId('motorbike'),
  selectPreferredFuelId('motorbike'),
  (mode, carVehicleId, carFueldId, mortorbikeVehicleId, motorbikeFuelId) => {
    if (mode === MULTIPATH_MODE_CAR) {
      return {
        vehiculetype: carVehicleId,
        fueltype: carFueldId
      }
    }
    if (mode === MULTIPATH_MODE_MOTORBIKE) {
      return {
        vehiculetype: mortorbikeVehicleId,
        fueltype: motorbikeFuelId
      }
    }
    return {}
  }
)

export const selectItineraryType = createSelector(selectCurrentItineraryRoute, route => {
  const type = route?.route_type
  const providerId = route?.provider?.id
  if (type) {
    if (type === PROVIDER_SHARED_BIKE) {
      return 'freeridebike'
    }
    if (providerId === PROVIDER_DOTT_VAE) {
      return 'dottfreeridebike'
    }
    if (providerId === PROVIDER_MARCEL) {
      return providerId
    }
    return type.replace('motorbike_', '')
  }
  return providerId
})

export const convertDistanceForAd = distanceInMeters => {
  const distanceSteps = [
    2_000_000, 1_000_000, 500_000, 300_000, 250_000, 200_000, 150_000, 100_000, 80_000, 50_000, 40_000, 30_000, 20_000,
    10_000, 0
  ]
  const maxChar = 'a'.charCodeAt(0) + distanceSteps.length - 1
  const index = distanceSteps.findIndex(v => distanceInMeters >= v)
  return String.fromCharCode(maxChar - index)
}

export const selectCurrentItineraryStatistics = createSelector(selectCurrentItineraryRoute, route => route?.statistics)

export const selectCurrentItineraryRoadTypes = createSelector(
  selectCurrentItineraryStatistics,
  statistics => statistics?.road_type ?? {}
)

export const selectCurrentItineraryMainRoadType = createSelector(selectCurrentItineraryRoadTypes, roadTypes => {
  const sortedRoadTypes = Object.fromEntries(Object.entries(roadTypes).sort(([, a], [, b]) => b - a))
  const mainRoadType = Object.keys(sortedRoadTypes)[0]

  return mainRoadType === roadType.highway ? roadType.highwaydist : mainRoadType
})

export const selectCurrentItineraryUrbanArea = createSelector(
  selectCurrentItineraryStatistics,
  statistics => statistics?.urban_area ?? {}
)

export const selectCurrentItineraryParsedStatistics = createSelector(
  selectCurrentItineraryRoadTypes,
  selectCurrentItineraryMainRoadType,
  selectCurrentItineraryUrbanArea,
  selectCurrentItineraryRouteMode,
  (roadTypes, mainRoadType, urbanArea, mode) => {
    if (![MULTIPATH_MODE_CAR, MULTIPATH_MODE_MOTORBIKE].includes(mode)) return {}

    return {
      highwaydist: roadTypes[roadType.highway] ?? 0,
      national_road: roadTypes[roadType.national_road] ?? 0,
      departmental_road: roadTypes[roadType.departmental_road] ?? 0,
      urban_area: urbanArea['urban'] ?? 0,
      is_urban: urbanArea['is_urban'] ?? false,
      mainroadtype: mainRoadType
    }
  }
)

export const selectItineraryKeywords = createSelector(
  selectHasUserConsentedForSendKeywords,
  selectDepartureStep,
  selectArrivalStep,
  selectCurrentItineraryRouteDistance,
  selectCurrentItineraryRouteMode,
  selectHasToll,
  selectQid,
  selectItineraryVehicleOptions,
  selectItineraryType,
  selectCurrentItineraryParsedStatistics,
  (consent, departure, arrival, distanceObj, mode, istoll, qid, vehicleOptions, itineraryType, statistics) => {
    const distance = distanceObj?.value
    // The mode used here is the current route mode, not the current mode, because they do not change at the same time, and we want the keywords to be refreshed only when the route changes
    if (!locationHasCoords(departure) || !locationHasCoords(arrival) || !distance) return null
    if (!consent) return {}

    return {
      ...buildLocationKeywords({ location: departure, suffix: 'D', suffixLatLng: '1' }),
      ...buildLocationKeywords({ location: arrival, suffix: 'A', suffixLatLng: '2' }),
      dist: Math.ceil(parseInt(distance, 10) / 1000),
      itinerarydistance: convertDistanceForAd(distance),
      mode,
      istoll,
      searchid: qid,
      ...vehicleOptions,
      itinerarytype: itineraryType,
      ...statistics
    }
  }
)

export const selectAdData = createSelector(selectAdState, ad => ad?.adData)

export const selectAdDataByAdId = createSelector(
  selectAdData,
  (_, { adId } = {}) => adId,
  (adData, adId) => adData?.find(item => item.adId === adId)?.data
)

export const selectExternalTrackingUrlByAdId = createSelector(
  selectAdDataByAdId,
  data => data?.externalTrackingUrl || data?.vde?.externalTrackingUrl
)

export const selectAdDataForFirstVde = createSelector(selectAdData, (adData = []) => {
  const v = adData.filter(o => Boolean(o?.data?.vde)).map(({ data }) => data.vde)
  if (v && v.length > 0) return v[0]
})

export const selectBboxForVde = createSelector(
  selectMapActiveBbox,
  selectViewport,
  selectCurrentHistoryRoute,
  (bbox, viewport, route) => (isAddressRoute(route) ? bboxArrayToBboxArrays(viewport) : bbox)
)

export const selectVdeName = createSelector(selectIsGE, selectCurrentHistoryRoute, (ge, route) => {
  switch (route) {
    case ROUTE_HOME:
      return ge ? 'hp_vde_desktop' : undefined
    case ROUTE_ITINERARY_HOME:
    case ROUTE_ITINERARY_HP_BY_MODE:
    case ROUTE_ITINERARY_TO:
      return ge ? 'hp_itinerary_vde_desktop' : undefined
    case ROUTE_SEO_ADDRESS:
    case ROUTE_ADDRESS:
      return ge ? 'address_vde_desktop' : 'address_vde_mobile'
    default:
      return undefined
  }
})
