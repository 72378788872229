import { NO_POLYLINE_DATA, requestPoiOnRoute } from '../../dataSource/itinerary/itinerary.poisOnRoute.requests'
import { POIS_ON_ROUTE_TYPES } from '../../domain/itinerary/poisonroute/poisOnRoute.constants'
import {
  GEOENTITY_NO_RESULT,
  RESET_GEOENTITY_LIST,
  SET_GEOENTITIES_REQUESTED,
  SET_GEOENTITY_LIST
} from '../geoentity/geoentity.actionTypes'
import { resetGeoentities } from '../geoentity/geoentity.actions'
import { parseGeoentityListResponse } from '../geoentity/geoentity.dataParser'
import { selectLocale } from '../locale/locale.selectors'
import { selectMapBbox, selectMapZoom } from '../map/map.selectors'
import { getGeoentityTypeDataForSearch } from '../poiassets/poiassets.selectors'
import { setCurrentSearch } from '../ui/ui.actions'
import { RESET_POIS_ON_ROUTE, SET_POIS_ON_ROUTE } from './itinerary.actionTypes'
import { selectItineraryState } from './itinerary.selectors'
import { selectActivePoisOnRoute } from './poisOnRoute.selectors'

const setPoisOnRoute =
  ({ type, label, id, rubric }) =>
  dispatch => {
    dispatch({
      type: SET_POIS_ON_ROUTE,
      payload: { type, id, label, ...(rubric && { rubric }) }
    })
  }

const getType = (app, subcategory) => {
  if (app) return POIS_ON_ROUTE_TYPES.application
  if (subcategory) return POIS_ON_ROUTE_TYPES.subcategory
  return POIS_ON_ROUTE_TYPES.category
}

export const loadPoisOnRoute = appCategOrSubCateg => (dispatch, getState) => {
  const { app, category, subcategory } = appCategOrSubCateg
  const { appOrRubricIds, label } = getGeoentityTypeDataForSearch(getState(), appCategOrSubCateg)
  const type = getType(app, subcategory)

  setPoisOnRoute({
    id: app || category || subcategory,
    rubric: appOrRubricIds,
    type,
    label
  })(dispatch, getState)

  setCurrentSearch(label)(dispatch, getState)

  return execRequestPoisOnRoute(type, appOrRubricIds, dispatch, getState)
}

export const refreshPoisOnRoute = () => (dispatch, getState) => {
  const { type, rubric } = selectActivePoisOnRoute(getState())
  return execRequestPoisOnRoute(type, rubric, dispatch, getState)
}

const execRequestPoisOnRoute = (type, appOrRubricIds, dispatch, getState) => {
  dispatch({
    type: SET_GEOENTITIES_REQUESTED
  })

  const state = getState()

  const itinerary = selectItineraryState(state)
  const bbox = selectMapBbox(state)
  const zoom = selectMapZoom(state)
  const locale = selectLocale(state)

  return requestPoiOnRoute(type, appOrRubricIds, itinerary, bbox, zoom, locale)
    .then(response => {
      dispatch({
        type: RESET_GEOENTITY_LIST
      })
      return response
    })
    .then(response => {
      const { data } = response

      if (data && data.length > 0) {
        dispatch({
          type: SET_GEOENTITY_LIST,
          payload: {
            pois: parseGeoentityListResponse({ pois: data })
          }
        })
      } else {
        dispatch({
          type: GEOENTITY_NO_RESULT
        })
      }
    })
    .catch(e => {
      if (e.message !== NO_POLYLINE_DATA) {
        // Only show message if request has failed or showing no result
        dispatch({
          type: GEOENTITY_NO_RESULT
        })
      }
    })
}

export const resetPoisOnRoute = () => (dispatch, getState) => {
  dispatch({
    type: RESET_POIS_ON_ROUTE
  })
  resetGeoentities()(dispatch, getState)
}
